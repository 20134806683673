import React, { useContext } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { maxMedia } from '../../style';
import { CircleIcon, FlexMiddle, FlexSpace, Header2SemiBold } from '..';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import ConfigContext from '../../contexts/ConfigContext';

const slideInFromRight = keyframes`
  from {
    right: -407px;
  }

  to {
    right: 0;
  }
`;

const Wrapper = styled.div`
  ${({ entireScreenView }) =>
    entireScreenView &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(19, 21, 41, 0.3);
      z-index: 10;
    `}

  ${maxMedia.mobile`max-width: 100vw;`};
`;

const slideInFromLeft = keyframes`
  from {
    left: -407px;
  }

  to {
    right: 0;
  }
`;

const Container = styled.div.attrs(() => ({ className: 'side-details-modal-container' }))`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;

  display: block;
  width: 450px;
  ${({ position }) => position === 'right' && 'right: 0;'}
  ${({ position }) => position === 'left' && 'left: 0;'}
  z-index: 3;

  border-radius: 0 12px 12px 0;

  animation: ${({ position }) => (position === 'left' ? slideInFromLeft : slideInFromRight)};
  animation-duration: 250ms;

  ${maxMedia.mobile`max-width: 100vw; border-radius: 0;`};
`;

const TitleRow = styled(FlexSpace)`
  padding: 12px 28px;
  ${({ $showBorder, theme }) =>
    $showBorder &&
    css`
      border-bottom: 1px solid ${theme.colors.grey2};
    `}
`;

const TitleContent = styled(Header2SemiBold)`
  flex: 1;
`;

const Buttons = styled(FlexMiddle)`
  justify-content: flex-end;
  padding: 7px 28px;

  position: absolute;
  bottom: 0;
  width: 100%;

  border-top: ${({ theme }) => `1px solid ${theme.colors.grey2}`};

  > button:not(:first-child) {
    margin-left: 16px;
  }
`;

const SideDetailsModal = ({
  children,
  title = '',
  position = 'right',
  showCloseButton = false,
  onClose,
  entireScreenView = false,
  renderButtons = false
}) => {
  const { t } = useContext(ConfigContext);
  return (
    <Wrapper entireScreenView={entireScreenView}>
      <Container position={position}>
        {title && (
          <TitleRow className="side-details-modal-title" $showBorder={!!title}>
            <TitleContent>{typeof title === 'string' ? t(title) : title}</TitleContent>

            {showCloseButton && <CircleIcon icon={CloseIcon} onClick={onClose} />}
          </TitleRow>
        )}

        {children}

        {renderButtons && <Buttons className="side-details-modal-buttons">{renderButtons?.()}</Buttons>}
      </Container>
    </Wrapper>
  );
};

export default SideDetailsModal;
