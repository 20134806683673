import React from 'react';
import styled from 'styled-components';
import { FlexMiddle } from '../Flex';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { ellipsis } from '../Typography';

const Wrapper = styled(FlexMiddle)`
  cursor: pointer;
`;

const StyledArrow = styled(Arrow)`
  transform: rotate(${({ isExpended }) => (isExpended ? 90 : -90)}deg);
  height: 30px;
  min-width: 30px;
  padding: 10px;
  box-sizing: border-box;
`;

const Content = styled.div`
  ${({ multiline }) => (multiline ? 'white-space: break-spaces;' : ellipsis)};
  width: 100%;
`;

const CellWithArrow = ({ isExpended, toggleRow, children, multiline, className }) => (
  <Wrapper onClick={toggleRow} className={className}>
    <Content multiline={multiline}>{children}</Content>
    <StyledArrow isExpended={isExpended} />
  </Wrapper>
);

export default CellWithArrow;
