import styled, { css } from 'styled-components';
import React, { useContext, useMemo } from 'react';
import { Field } from 'react-final-form';
import { Trans } from 'react-i18next';
import useBranches from '@ubeya/shared/hooks/account/useBranches';
import { required } from '@ubeya/shared/utils/form';
import ConfigContext from '../../contexts/ConfigContext';
import { ReactComponent as ImportIcon } from '../../assets/import-file.svg';
import { ReactComponent as UploadExcelIcon } from '../../assets/upload-excel.svg';
import EmployeesImportExcelExample from '../../assets/employees-import-excel-example.png';
import SchedulingImportExcelExample from '../../assets/scheudling-import-excel-example.png';
import { flexCenter, FlexColumn } from '../Flex';
import BaseSelect from '../Select/Select';
import { FileUpload as BaseFileUpload } from '../FileUpload';
import { RadioField } from '../FormFields';
import { Loader } from '../Loader';

const Wrapper = styled(FlexColumn)`
  flex: 1;
  padding: 30px 50px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  ${({ $marginBottom }) => $marginBottom && 'margin-bottom: 15px'};
`;

const Select = styled(BaseSelect)`
  width: 200px;
  margin: 20px 0;

  .select {
    max-width: 300px;
  }
`;

const FileUploadWrapper = styled.div`
  margin: 20px 0;
`;

const FileUpload = styled(BaseFileUpload)`
  ${flexCenter};
  flex-direction: column;

  cursor: pointer;
  width: 100%;
  height: 180px;
  min-height: 150px;

  color: ${({ theme }) => theme.colors.grey3};
  ${({ $empty }) =>
    $empty
      ? css`
          background-color: ${({ theme }) => theme.colors.grey1};
          border: 2px dashed ${({ theme }) => theme.colors.grey2};
        `
      : css`
          border: 2px solid ${({ theme }) => theme.colors.grey2};
        `}
  text-align: center;

  &.active {
    border-style: dashed;
    border-width: 2px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: ${({ $imageHeight = 170 }) => $imageHeight}px;
  margin-top: 20px;
`;

const Options = styled.div`
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  font-size: 16px;
  margin-top: 30px;
  display: flex;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  margin: 0 5px;
`;

const UploadDescription = styled.div`
  margin: 10px 0;
`;

const Link = styled.a`
  margin-top: 10px;
`;

const ExcelDescription = ({ importContext }) => {
  const { t } = useContext(ConfigContext);

  const { subTitleText, excelImage, exampleLink, imageHeight, uploadDescriptionText } = useMemo(() => {
    const result = {
      subTitleText: undefined,
      exampleLink: undefined,
      uploadDescriptionText: undefined,
      excelImage: undefined,
      imageHeight: undefined
    };

    switch (importContext) {
      case 'employees':
        result.subTitleText = t('excelExampleDescription');
        result.exampleLink =
          'https://docs.google.com/spreadsheets/d/1Ob6p4gJ7mEdhYbGSfsCkuZu25ttqQjZw/edit#gid=1627973441';
        result.uploadDescriptionText = 'importEmployeesExcelFileExplanation';
        result.excelImage = EmployeesImportExcelExample;
        result.imageHeight = 170;
        break;

      case 'scheduling':
        result.subTitleText = t('excelExampleDescription');
        result.exampleLink =
          'https://docs.google.com/spreadsheets/d/1jdF_Q_ha5n37NVPSHITDFIbZrckNvBwoT_f4B9Gmiig/edit#gid=0';
        result.uploadDescriptionText = 'importEventsExcelFileExplanation';
        result.excelImage = SchedulingImportExcelExample;
        result.imageHeight = 160;
        break;

      default: {
        result.subTitleText = '';
        result.exampleLink = '';
        result.uploadDescriptionText = '';
        result.excelImage = EmployeesImportExcelExample;
        result.imageHeight = 170;
      }
    }

    return result;
  }, [importContext, t]);

  return (
    <>
      <SubTitle>{subTitleText}</SubTitle>

      <ImageWrapper>
        <Image src={excelImage} $imageHeight={imageHeight} />
      </ImageWrapper>

      <Link href={exampleLink} target="_blank">
        ({t('clickHereForExcelExample')})
      </Link>

      <UploadDescription>
        <Trans>{uploadDescriptionText}</Trans>
      </UploadDescription>
    </>
  );
};

const ImportFromFile = ({
  title,
  handleDrop,
  value,
  isLoading,
  showEmployeeOptions = false,
  showBranchesSelector = false,
  showExcelExample = false,
  requiredBranchSelector = true,
  importContext
}) => {
  const { t } = useContext(ConfigContext);

  const { branchesOptions } = useBranches();

  return (
    <Wrapper>
      {title && <SubTitle $marginBottom>{title}</SubTitle>}
      {showEmployeeOptions && (
        <Options>
          <RadioField
            name="status"
            options={[
              { value: 'isActive', label: t('isActive') },
              { value: 'isDeleted', label: t('isDeleted') },
              { value: 'inActive', label: t('inactive') }
            ]}
          />
        </Options>
      )}
      {showBranchesSelector && branchesOptions.length > 1 && (
        <Field
          name="branchId"
          validate={requiredBranchSelector ? required : undefined}
          render={({ input }) => (
            <Select
              title={t('uploadTo')}
              options={branchesOptions}
              {...input}
              usePortal
              required={requiredBranchSelector}
            />
          )}
        />
      )}
      <Field name="file" validate={required} render={() => <div />} />
      <SubTitle>{t('uploadExcel')}</SubTitle>
      <FileUploadWrapper>
        <FileUpload multiple={false} onDrop={handleDrop} accept=".xlsx" $empty={isLoading || !value}>
          {isLoading ? (
            <Loader />
          ) : value ? (
            <>
              <UploadExcelIcon />
              <TextWrapper>
                <Trans i18nKey="uploadedSuccessfully" />
              </TextWrapper>
            </>
          ) : (
            <>
              <ImportIcon />
              <TextWrapper>
                <Trans i18nKey="uploadFileDescription">
                  <Description />
                </Trans>
              </TextWrapper>
            </>
          )}
        </FileUpload>
      </FileUploadWrapper>

      {showExcelExample && <ExcelDescription importContext={importContext} />}
    </Wrapper>
  );
};

export default ImportFromFile;
