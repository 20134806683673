import React, { useMemo, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { ATTACHMENTS_TYPES } from '@ubeya/shared/constants';
import { getExtension, getSource } from '@ubeya/shared-web/utils/files';
import { DocumentPreview } from '../..';
import GeneralAttachments from './GeneralAttachments';

const attachment = css`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-top: 10px;
  &:not(:nth-child(4n)) {
    margin-right: 10px;
  }
`;

const Image = styled.div.attrs(({ src }) => ({ style: { backgroundImage: `url(${src})` } }))`
  ${attachment};
  background-position: center;
  background-size: cover;
  &:hover {
    cursor: pointer;
  }
`;

const Video = styled.video.attrs(() => ({ controls: true }))`
  ${attachment};
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
`;

const getAttachmentComponent = (type) => {
  switch (type) {
    case 'document':
      return DocumentPreview;
    case 'image':
      return Image;
    case 'video':
      return Video;
    default:
      return DocumentPreview;
  }
};

const UploadAttachments = ({ onDelete, onUpload, enableUpload, attachments = [] }) => {
  const [newAttachments, setNewAttachments] = useState([...attachments]);

  const data = useMemo(
    () =>
      newAttachments.map((item) => {
        const extension = getExtension(item)?.toLowerCase();
        const type = ATTACHMENTS_TYPES[extension];
        return {
          ...item,
          src: getSource(item),
          Component: getAttachmentComponent(type),
          videoUrl: type === 'video',
          extension,
          type
        };
      }),
    [newAttachments]
  );

  const handleDeleteItems = useCallback(
    (fileId) => {
      setNewAttachments((prev) => prev.filter(({ id }) => id !== fileId));
      onDelete([fileId]);
    },
    [onDelete]
  );

  const handleAddItems = useCallback(
    (addedData) => {
      setNewAttachments((prev) => [...prev, addedData[0]]);
      onUpload(addedData);
    },
    [onUpload]
  );

  return (
    <GeneralAttachments
      data={data}
      enableUpload={enableUpload}
      handleDeleteItems={handleDeleteItems}
      handleAddItems={handleAddItems}
    />
  );
};

export default UploadAttachments;
