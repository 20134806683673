import React from 'react';
import useBranchCostCenters from '@ubeya/shared/hooks/account/useBranchCostCenters';
import { FlexMiddle } from '../../Flex';

const CostCenterChip = ({ externalOptions = {}, value }) => {
  const { branchCostCentersOptions } = useBranchCostCenters();

  const formattedValueData = value ? (Array.isArray(value) ? value : [value]) : [];
  const costCenterOptions = externalOptions.costCenter || branchCostCentersOptions;

  return (
    <FlexMiddle>
      {costCenterOptions
        .filter(({ value: optionValue }) => formattedValueData.includes(optionValue))
        .map(({ label: optionLabel }) => optionLabel)
        .join(', ')}
    </FlexMiddle>
  );
};

export default CostCenterChip;
