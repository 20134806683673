import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckmarkOutlineGreenIcon } from '@ubeya/shared-web/assets/checkmark-outline-green.svg';
import ConfigContext from '../../../contexts/ConfigContext';
import BaseCheckBox from '../../CheckBox';
import DropDown from '../../DropDown';
import { FlexCenter } from '../../Flex';
import FilterLayout from '../FilterLayout';

const CheckBox = styled(BaseCheckBox)`
  flex: 1;
`;

const Label = styled(FlexCenter)`
  gap: 6px;
`;

const ColoredCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 3px 0 2px;
  background-color: ${({ color }) => color};
`;

const BookingStatusFilter = ({ defaultValue, handleClose, onApply, hideLayout = false, search, onChange }) => {
  const { t, theme } = useContext(ConfigContext);
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const options = useMemo(
    () => [
      {
        label: t('statusNoEmployeesBooked'),
        value: 'no_employees_booked',
        visual: <ColoredCircle color={theme.colors.redNegative} />
      },
      {
        label: t('statusPartiallyBooked'),
        value: 'partially_booked',
        visual: <ColoredCircle color={theme.colors.yellowPartially} />
      },
      {
        label: t('statusFullyBooked'),
        value: 'fully_booked',
        visual: <ColoredCircle color={theme.colors.greenPositive} />
      },
      {
        label: t('statusFullyConfirmed'),
        value: 'fully_confirmed',
        visual: <CheckmarkOutlineGreenIcon width={14} height={15} />
      }
    ],
    [t, theme.colors.greenPositive, theme.colors.redNegative, theme.colors.yellowPartially]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? options.filter(
            ({ label }) =>
              t('positions').toLowerCase().includes(search.toLowerCase()) ||
              label.toLowerCase().includes(search.toLowerCase())
          )
        : options,
    [options, search, t]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout
      name={t('bookingStatus')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={hideLayout}>
      {filteredOptions.map(({ value, label, visual }) => (
        <DropDown.Item key={value}>
          <CheckBox
            name={`status-${value}`}
            value={values.includes(value)}
            onChange={() => handleChange(value)}
            label={
              <Label>
                {visual}
                {label}
              </Label>
            }
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default BookingStatusFilter;
