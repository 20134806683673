import styled from 'styled-components';
import { FlexMiddle } from '../Flex';

const ButtonHeader = styled(FlexMiddle)`
  justify-content: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  cursor: pointer;
  padding: 0 10px !important;
  min-width: 34px;
  min-height: 34px;

  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
  ${({ disabled, theme }) => disabled && `color: ${theme.colors.grey2};`}

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.grey3} !important;
  }
`;

export default ButtonHeader;
