/* eslint-disable camelcase */
import en from './en.json';
import he from './he.json';
import nl from './nl.json';
import es from './es.json';
import de from './de.json';
import ru from './ru.json';
import fr from './fr.json';
import industry_1 from './industry_1.json';
import industry_2 from './industry_2.json';
import industry_3 from './industry_3.json';
import industry_4 from './industry_4.json';
import industry_5 from './industry_5.json';
import industry_6 from './industry_6.json';
import industry_7 from './industry_7.json';
import industry_8 from './industry_8.json';
import industry_9 from './industry_9.json';
import industry_10 from './industry_10.json';
import industry_11 from './industry_11.json';
import industry_12 from './industry_12.json';
import industry_13 from './industry_13.json';
import industry_14 from './industry_14.json';
import industry_15 from './industry_15.json';
import industry_16 from './industry_16.json';

const industriesFiles = {
  industry_1,
  industry_2,
  industry_3,
  industry_4,
  industry_5,
  industry_6,
  industry_7,
  industry_8,
  industry_9,
  industry_10,
  industry_11,
  industry_12,
  industry_13,
  industry_14,
  industry_15,
  industry_16
};

const baseLanguagesFiles = {
  en,
  he,
  nl,
  es,
  de,
  ru,
  fr
};

const baseLanguagesSlug = ['en', 'he', 'nl', 'es', 'de', 'ru', 'fr'];

const DEFAULT_FALLBACK_LNG = 'en';

const industriesLangauges = Object.entries(industriesFiles).reduce((all, [key, industryFile]) => {
  baseLanguagesSlug.forEach((language) => {
    all[`${key}_${language}`] = industryFile[language];
  });
  return all;
}, {});

const languages = { ...baseLanguagesFiles, ...industriesLangauges };

const resources = Object.keys(languages).map((key) => {
  return {
    key,
    lng: languages[key],
    fallback: [...key.split('_').slice(-1), DEFAULT_FALLBACK_LNG]
  };
});

export default resources;
