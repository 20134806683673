import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ALLOWED_PHOTO_TYPES, ATTACHMENTS_TYPES } from '@ubeya/shared/constants';
import { ReactComponent as PDFIcon } from '@ubeya/shared-web/assets/pdf-file.svg';
import { ImagesCarousel, TextWithTooltip } from '..';
import { getExtension, getSource } from '../../utils/files';

const attachment = css`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-top: 10px;
  &:not(:nth-child(4n)) {
    margin-right: 10px;
  }
`;
const Image = styled.div.attrs(({ src }) => ({ style: { backgroundImage: `url(${src})` } }))`
  ${attachment};
  background-position: center;
  background-size: cover;
  &:hover {
    cursor: pointer;
  }
`;

const Video = styled.video.attrs(() => ({ controls: true }))`
  ${attachment};
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div``;
const Doc = styled.div`
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DocumentWrapper = styled.a`
  ${attachment};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.disabled};
  display: flex;
  width: max-content;
  height: 40px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.grey2};
`;

const Title = styled.div`
  font-size: 10px;
  padding: 2px;
  max-width: 80px;
`;

const SubTitle = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.disabled};
`;

const Document = ({ children, title, src }) => {
  return (
    <DocumentWrapper href={src} target="_blank">
      <PDFIcon />
      <Doc>
        <Title>
          <TextWithTooltip>{title}</TextWithTooltip>
        </Title>
        <SubTitle>{children.toUpperCase()}</SubTitle>
      </Doc>
    </DocumentWrapper>
  );
};

const COMPONENTS_MAP = {
  document: Document,
  image: Image,
  video: Video
};

const DocumentsRenderer = ({ rowData, dataKey }) => {
  const [isOpenIndex, setIsOpenIndex] = useState();

  const allPhotoTypeDocuments = useMemo(
    () =>
      Object.entries(rowData).reduce((all, [key, value]) => {
        const isPhoto =
          key.includes('field-') &&
          !!value?.link &&
          typeof value?.link === 'string' &&
          ALLOWED_PHOTO_TYPES.includes(getExtension(value)?.toLowerCase());

        if (isPhoto) {
          all.push({ src: getSource(value), valueId: value?.id });
        }

        return all;
      }, []),
    [rowData]
  );

  const mappedIndices = useMemo(
    () =>
      (allPhotoTypeDocuments || []).reduce((all, curr, index) => {
        all[curr.valueId] = index;
        return all;
      }, {}),
    [allPhotoTypeDocuments]
  );

  const views = useMemo(() => (allPhotoTypeDocuments || []).map(({ src }) => ({ source: src })), [
    allPhotoTypeDocuments
  ]);

  // specific pressed item data
  const item = rowData[dataKey];

  if (!item) {
    return null;
  }

  const extension = getExtension(item)?.toLowerCase();

  if (!extension) {
    return null;
  }

  const type = ATTACHMENTS_TYPES[extension];
  const Component = COMPONENTS_MAP[type];
  const name = type === 'document' && extension;
  const title = item?.title;
  const src = getSource(item);
  const valueId = item?.id;

  return (
    <Container>
      <Component
        onClick={() => setIsOpenIndex(mappedIndices[valueId])}
        title={title}
        extension={extension}
        src={src}
        preload="false">
        {name || ''}
      </Component>
      <ImagesCarousel views={views} currentIndex={isOpenIndex} onClose={() => setIsOpenIndex()} />;
    </Container>
  );
};

export default DocumentsRenderer;
