import { isUndefined, omitBy } from 'lodash';
import moment from 'moment';
import { get, post, put, httpDelete, patch, delay } from '../utils/http';
import dashboardFinancial from './mock/dashboardFinancial.json';

export const fetchUser = () => get('/auth/me');

export const refreshAdminToken = () => post(`${process.env.API_AUTH_URL}/admin/refresh`);

export const refreshUserToken = () => post(`${process.env.API_AUTH_URL}/user/refresh`);

export const fetchFields = ({ accountId }) => get(`/accounts/${accountId}/fields`);

export const fetchPositions = ({ accountId }) => get(`/accounts/${accountId}/positions`);

export const fetchPositionsGroups = ({ accountId }) => get(`/accounts/${accountId}/positions-groups`);

export const fetchBranches = ({ accountId }) => get(`/accounts/${accountId}/branches`);

export const fetchClientBranchesAccessConfiguration = ({ accountId, clientId }) =>
  get(`/accounts/${accountId}/clients/${clientId}/branch-access`);

export const updateClientBranchesAccessConfiguration = ({ accountId, clientId, configuration }) =>
  put(`/accounts/${accountId}/clients/${clientId}/branch-access`, configuration);

export const fetchFileTypes = () => get('/documents/types');

export const uploadImage = (files) => {
  const formData = new FormData();
  formData.append('file', files[0]);
  return post(`/upload`, formData, { 'Content-Type': 'multipart/form-data' });
};

export const fetchAccount = ({ accountId }) =>
  get(`/accounts/${accountId}`, {
    includes: [
      'withCurrency',
      'withLanguage',
      'withCountry',
      'withPayrollConfig',
      'withCustomSettings',
      'withOrganization'
    ]
  });

export const addAccount = (params) => post('/accounts', params);

export const fetchAccountAdminconfig = ({ accountId, adminId }) => get(`/accounts/${accountId}/admins/${adminId}`);

export const updateAccountAdminConfig = ({ accountId, adminId, ...params }) =>
  patch(`/accounts/${accountId}/admins/${adminId}`, params);

export const fetchEmployees = ({ accountId, ...params }) => get(`/accounts/${accountId}/employees`, params);

export const exportEmployees = ({ toSendMail, accountId, ...params }) =>
  get(`/accounts/${accountId}/employees/dump`, { toSendMail, ...params });

export const fetchCandidates = ({ accountId, ...params }) => get(`/accounts/${accountId}/candidates`, params);

export const patchCandidate = ({ accountId, requestId, isApproved }) =>
  patch(`/accounts/${accountId}/candidates/${requestId}`, { isApproved });

export const fetchEmployeesStats = ({ accountId, filters, searchTerm, branches }) =>
  get(`/accounts/${accountId}/employees/stats`, { filters, searchTerm, branches });

export const fetchEmployee = ({ accountId, employeeId }) => get(`/accounts/${accountId}/employees/${employeeId}`);
export const fetchOSEmployee = ({ accountId, employeeId, contextProjectId, contextShiftId }) =>
  get(`/accounts/${accountId}/employees/os/${employeeId}`, { contextProjectId, contextShiftId });

export const updateOSEmployee = ({ accountId, employeeId, ...params }) =>
  patch(`/accounts/${accountId}/employees/os/${employeeId}`, params);

export const addEmployee = ({ accountId, ...params }) => post(`/accounts/${accountId}/employees`, params);

export const updateEmployee = ({ accountId, employeeId, ...params }) =>
  patch(`/accounts/${accountId}/employees/${employeeId}`, params);

export const updateEmployeesBulkParams = ({ accountId, employeeIds, updateParams }) =>
  post(`/accounts/${accountId}/employees/bulk-edit`, { employeeIds, updateParams });

export const deleteEmployee = ({ accountId, employeeId }) =>
  httpDelete(`/accounts/${accountId}/employees/${employeeId}`);

export const permanentlyDeleteEmployee = ({ accountId, employeeId }) =>
  httpDelete(`/accounts/${accountId}/employees/${employeeId}/permanent`);

export const inviteEmployee = ({ accountId, employeeId }) =>
  post(`/accounts/${accountId}/employees/${employeeId}/invite`);

export const updateBulkEmployees = ({ accountId, employees }) =>
  post(`/accounts/${accountId}/employees/batch`, { employees });

export const updateEmployeePhoto = ({ accountId, employeeId, employeePhotoId, isSharedWithClient }) =>
  patch(`/accounts/${accountId}/employees/${employeeId}/photos/${employeePhotoId}`, { isSharedWithClient });

export const fetchTimeSheet = ({
  accountId,
  branchIds,
  from,
  to,
  employeeIds,
  projectIds,
  filters,
  withFields,
  warnings = true,
  withJobs = false,
  withExternalEmployees = false,
  invoiceStatus
}) => {
  return get(`/accounts/${accountId}/jobs`, {
    branches: branchIds,
    from,
    to,
    employeeIds,
    projectIds,
    filters,
    warnings,
    withJobs,
    withFields,
    withExternalEmployees,
    invoiceStatus
  });
};

export const fetchSpecificTimeSheet = ({ accountId, timesheetId }) =>
  get(`/accounts/${accountId}/timesheets/${timesheetId}`);

export const fetchSpecificOsTimeSheet = ({ accountId, timesheetId }) =>
  get(`/accounts/${accountId}/timesheets/os/${timesheetId}`);

export const updateTimeSheet = ({ accountId, timesheetId, shouldWaitForBreaktimes, ...params }) =>
  patch(`/accounts/${accountId}/timesheets/${timesheetId}`, { ...params, options: { shouldWaitForBreaktimes } });

export const createJob = ({ accountId, ...params }) => post(`/accounts/${accountId}/jobs`, params);

export const updateJob = ({ accountId, jobId, ...params }) => patch(`/accounts/${accountId}/jobs/${jobId}`, params);

export const deleteJob = ({ accountId, jobId }) => httpDelete(`/accounts/${accountId}/jobs/${jobId}`);

export const approveAll = ({ accountId, ids }) => put(`/accounts/${accountId}/timesheets/approve`, { ids });

export const approveOSAll = ({ accountId, ids, signature }) =>
  put(`/accounts/${accountId}/timesheets/os/approve`, { ids, signature });

export const uploadExpense = () => delay({ data: { path: 'test.doc' } });

export const addBranch = ({ accountId, branch }) => post(`/accounts/${accountId}/branches`, branch);

export const updateBranch = ({ accountId, branchId, branch }) =>
  patch(`/accounts/${accountId}/branches/${branchId}`, branch);

export const addField = ({ accountId, ...params }) => post(`/accounts/${accountId}/fields`, params);

export const updateField = ({ accountId, fieldId, ...params }) =>
  patch(`/accounts/${accountId}/fields/${fieldId}`, params);

export const deleteField = ({ accountId, fieldId }) => httpDelete(`/accounts/${accountId}/fields/${fieldId}`);

export const syncFields = ({ accountId, fields }) => put(`/accounts/${accountId}/fields`, fields);

export const sortFields = ({ accountId, fields }) => put(`/accounts/${accountId}/fields/sort`, { fields });

export const fetchAdmins = ({ accountId }) => get(`/accounts/${accountId}/admins`);

export const addAdmin = ({ accountId, admin }) => post(`/accounts/${accountId}/admins`, admin);

export const updateAdmin = ({ accountId, adminId, admin }) => patch(`/accounts/${accountId}/admins/${adminId}`, admin);

export const deleteAdmin = ({ accountId, adminId }) => httpDelete(`/accounts/${accountId}/admins/${adminId}`);

export const fetchBranchAdmins = ({ accountId, branchId }) => get(`/accounts/${accountId}/branches/${branchId}/admins`);

export const fetchAccountProfile = ({ accountId }) => get(`/accounts/${accountId}/profile`);

export const addBranchAdmin = ({ accountId, branchId, admin }) =>
  post(`/accounts/${accountId}/branches/${branchId}/admins`, admin);

export const updateBranchAdmin = ({ accountId, branchId, adminId, admin }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/admins/${adminId}`, admin);

export const deleteBranchAdmin = ({ accountId, branchId, adminId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/admins/${adminId}`);

export const fetchAccountConfig = ({ accountId, getNextPayment }) =>
  get(`/accounts/${accountId}`, {
    includes: ['withConfig', 'withPayrollConfig', 'withHolidays', 'withCRMConfig'],
    getNextPayment
  });

export const getBilling = ({ type, date }) => get(`/billing`, { period: { type, date } });

export const getBillingHistory = ({ type, date }) => get(`/billing/history`, { period: { type, date } });

export const createBilling = ({ body }) => post(`/billing`, body);

export const addBillingMethod = ({ accountId, body }) => post(`accounts/${accountId}/billing/methods`, body);

export const updateAccountConfig = ({ accountId, account }) => patch(`/accounts/${accountId}`, account);

export const updateAccountProfile = ({ accountId, profile }) => put(`/accounts/${accountId}/profile`, { ...profile });

export const fetchRegions = () => get('/regions');

export const fetchModules = () => get('/modules');

export const fetchIndustries = () => get('/industries');

export const fetchJobTypes = () => get('/jobs/types');

export const fetchEmploymentTypes = () => get('/employment-types');

export const addPosition = ({ accountId, position }) => post(`/accounts/${accountId}/positions`, position);
export const addPositionsGroup = ({ accountId, positionsGroup }) =>
  post(`/accounts/${accountId}/positions-groups`, positionsGroup);

export const updatePosition = ({ accountId, positionId, position }) =>
  patch(`/accounts/${accountId}/positions/${positionId}`, position);

export const updatePositionsGroup = ({ accountId, positionsGroupId, positionsGroup }) =>
  patch(`/accounts/${accountId}/positions-groups/${positionsGroupId}`, positionsGroup);

export const updatePositions = ({ accountId, positions }) => put(`/accounts/${accountId}/positions`, positions);
export const deletePositionsGroup = ({ accountId, positionGroupsId }) =>
  httpDelete(`/accounts/${accountId}/positions-groups/${positionGroupsId}`);

export const fetchLocations = ({ accountId }) => get(`/accounts/${accountId}/locations`, { withIsDeleted: true });

export const fetchBranchLocation = ({ accountId, branchId, locationId, withAttachments }) =>
  get(`/accounts/${accountId}/branches/${branchId}/locations/${locationId}`, { withAttachments });

export const addSubLocation = ({ accountId, branchId, subLocationId, locationId, clientBranchId }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/locations/${locationId}/sub-locations`, {
    subLocationId,
    clientBranchId
  });

export const addLocation = ({ accountId, branchId, location: { location, ...rest } }) =>
  post(`/accounts/${accountId}/branches/${branchId}/locations`, { ...location, ...rest });

export const updateLocation = ({
  accountId,
  branchId,
  locationId,
  //  { instructions, attachments  } are partially supported in backend, but not in UI
  location: { name, photo, lat, lng, address, subLocations }
}) =>
  patch(
    `/accounts/${accountId}/branches/${branchId}/locations/${locationId}`,
    omitBy({ name, lat, lng, address, photo, subLocations }, isUndefined)
  );

export const deleteLocation = ({ accountId, branchId, locationId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/locations/${locationId}`);

export const fetchTags = ({ accountId }) => get(`/accounts/${accountId}/tags`);

export const addTag = ({ accountId, tag }) => post(`/accounts/${accountId}/tags`, tag);

export const updateTag = ({ accountId, tagId, tag }) => patch(`/accounts/${accountId}/tags/${tagId}`, tag);

export const deleteTag = ({ accountId, tagId }) => httpDelete(`/accounts/${accountId}/tags/${tagId}`);

export const fetchUniforms = ({ accountId }) => get(`/accounts/${accountId}/uniforms`);

export const addUniform = ({ accountId, branchId, uniform }) =>
  post(`/accounts/${accountId}/branches/${branchId}/uniforms`, uniform);

export const updateUniform = ({ accountId, branchId, uniformId, uniform }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/uniforms/${uniformId}`, uniform);

export const deleteUniform = ({ accountId, branchId, uniformId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/uniforms/${uniformId}`);

export const fetchDepartments = ({ accountId }) => get(`/accounts/${accountId}/departments`);

export const addDepartment = ({ accountId, department }) => post(`/accounts/${accountId}/departments`, department);

export const updateDepartment = ({ accountId, departmentId, department }) =>
  patch(`/accounts/${accountId}/departments/${departmentId}`, department);

export const deleteDepartment = ({ accountId, departmentId }) =>
  httpDelete(`/accounts/${accountId}/departments/${departmentId}`);

export const fetchRoles = () => get('/roles');

export const fetchPrivileges = () => get('/privileges');

export const fetchLabels = ({ accountId }) => get(`/accounts/${accountId}/labels`);

export const addLabel = ({ accountId, branchId, title, startTime, endTime }) =>
  post(`/accounts/${accountId}/branches/${branchId}/labels`, { title, startTime, endTime });

export const updateLabel = ({ accountId, branchId, labelId, title, startTime, endTime, isDeleted }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/labels/${labelId}`, { title, startTime, endTime, isDeleted });

export const deleteLabel = ({ accountId, branchId, labelId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/labels/${labelId}`);

export const updateLabels = ({ accountId, branchId, labels }) =>
  put(`/accounts/${accountId}/branches/${branchId}/labels`, { labels });

export const fetchProjects = ({
  accountId,
  branchIds,
  from,
  to,
  allEmployees,
  filters,
  isArchived,
  full,
  showAllBranches
}) =>
  get(`/accounts/${accountId}/projects`, {
    branches: branchIds,
    from,
    to,
    employees: allEmployees ? 'all' : 'booked',
    filters,
    isArchived,
    listType: true,
    warnings: full,
    full,
    showAllBranches
  });

export const fetchPresentationInfo = ({ accountId, branchId, projectId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/presentationInfo`);

export const fetchProject = ({ accountId, branchId, projectId, includes = [], isLite, withPayroll = true, context }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}`, { includes, isLite, withPayroll, context });

export const fetchProjectHourly = ({ accountId, branchId, from, to }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/hourly`, { from, to });

export const exportProjectInvoice = ({ accountId, branchId, projectId }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/invoice`);

export const exportProjectForecastingReport = ({ accountId, branchId, projectId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/forecasting-report`);

export const addProjects = ({ accountId, branchId, projects }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects`, projects);

export const updateProject = ({ accountId, branchId, projectId, project, deleteEmptyProject = true }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}`, {
    ...project,
    deleteEmptyProject
  });

export const initiateOrderChangeUponShiftsChange = ({ accountId, branchId, projectId, shifts }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/orders-bulk-change`, {
    shifts
  });

export const updateProjectShiftsHours = ({
  accountId,
  branchId,
  projectId,
  minutes,
  startTime,
  endTime,
  shiftIds,
  createOrderChangeBulk = false,
  notifyEmployeesUponChange = false
}) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/shiftHours`, {
    minutes,
    shiftIds,
    startTime,
    endTime,
    createOrderChangeBulk,
    notifyEmployeesUponChange
  });

export const deleteProject = ({ accountId, branchId, projectId, message }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}`, { message });

export const updateProjectPayroll = ({ accountId, branchId, projectId, wages }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/payroll`, { ...wages });

export const orderProject = ({
  accountId,
  projectId,
  supplierBranchId,
  supplierBookerId,
  comments,
  uniformId,
  instructions,
  poNumber,
  meetingSpot,
  onsiteContactName,
  onsiteContactPhone,
  isDraft,
  shifts,
  name,
  selfServeMethod
}) =>
  post(`/accounts/${accountId}/projects/${projectId}/order`, {
    supplierBranchId,
    supplierBookerId,
    comments,
    uniformId,
    instructions,
    poNumber,
    meetingSpot,
    onsiteContactName,
    onsiteContactPhone,
    isDraft,
    shifts,
    name,
    selfServeMethod
  });

export const updateOrder = ({ accountId, orderId, status, documents, cancellationReason }) =>
  patch(`/accounts/${accountId}/orders/${orderId}`, { status, documents, cancellationReason });

export const addOrder = ({
  accountId,
  branchId,
  supplierBranchId,
  supplierBookerId,
  comments,
  uniformId,
  instructions,
  meetingSpot,
  onsiteContactName,
  onsiteContactPhone,
  isDraft,
  shifts,
  date,
  endDate,
  locationId,
  name,
  clientBranchId,
  clientAdminid,
  applicationType,
  poNumber,
  selfServeMethod
}) =>
  post(`/accounts/${accountId}/branches/${branchId}/orders`, {
    supplierBranchId,
    supplierBookerId,
    comments,
    uniformId,
    instructions,
    meetingSpot,
    onsiteContactName,
    onsiteContactPhone,
    isDraft,
    shifts,
    date,
    endDate,
    clientBranchId,
    clientAdminid,
    locationId,
    name,
    applicationType,
    poNumber,
    selfServeMethod
  });

export const fetchBranchOrders = ({ accountId, branchId }) => get(`/accounts/${accountId}/branches/${branchId}/orders`);
export const fetchAccountOrders = ({ accountId }) => get(`/accounts/${accountId}/orders`);

export const fetchOrderChatMessages = ({ accountId, orderId }) =>
  get(`/accounts/${accountId}/orders/${orderId}/messages`);

export const addOrderChatMessage = ({ accountId, orderId, message, isClient }) =>
  post(`/accounts/${accountId}/orders/${orderId}/messages`, { message, isClient });

export const editOrderChatMessage = ({ accountId, orderId, messageId, status }) =>
  patch(`/accounts/${accountId}/orders/${orderId}/messages/${messageId}`, { status });

export const editProjectOSSlot = ({ accountId, branchId, projectId, slotId, slot }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/os/slots/${slotId}`, slot);

export const editOSTimesheet = ({ accountId, timesheetId, timesheet, shouldWaitForBreaktimes }) =>
  patch(`/accounts/${accountId}/timesheets/os/${timesheetId}`, {
    ...timesheet,
    options: { shouldWaitForBreaktimes }
  });

export const fetchProjectsPayroll = ({ accountId, branchIds, projectIds }) =>
  get(`/accounts/${accountId}/projects/payroll`, { branches: branchIds, projectIds });

export const fetchProjectPayroll = ({ accountId, branchId, projectId, employeeIds, client, asClient, mode }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/payroll`, {
    employeeIds,
    options: { client, asClient, mode }
  });

export const addSchedule = ({
  accountId,
  branchId,
  projectId,
  shiftId,
  slotId,
  employeeId,
  notes,
  isConfirmed,
  isShiftConfirmed
}) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/booking`, {
    slotId,
    employeeId,
    notes,
    isConfirmed,
    isShiftConfirmed
  });

export const bookEmployeeToProject = ({
  accountId,
  branchId,
  projectId,
  shiftId,
  employeeId,
  enableWtdCheck = true,
  enableComplianceCheck = true,
  specificShiftIds
}) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/book`, {
    employeeId,
    preferredShiftId: shiftId,
    enableWtdCheck,
    enableComplianceCheck,
    specificShiftIds
  });

export const moveSlot = ({ accountId, branchId, projectId, shiftId, slotId, dst }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/slots/${slotId}/move`, {
    dst
  });

export const updateShift = ({ accountId, branchId, projectId, shiftId, isOverbooking }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}`, {
    isOverbooking
  });

export const changeShiftInternalEmployee = ({ accountId, branchId, projectId, shiftId, slotId, destShiftId }) =>
  patch(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/slots/${slotId}/change-shift`,
    {
      destShiftId
    }
  );

export const removeSchedule = ({
  accountId,
  branchId,
  projectId,
  shiftId,
  slotId,
  isSoftDelete,
  push,
  message,
  addUnbooking,
  markNoShow
}) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/booking`, {
    slotId,
    isSoftDelete,
    push,
    message,
    addUnbooking,
    markNoShow
  });

export const changeShiftExternalEmployee = ({
  accountId,
  branchId,
  projectId,
  srcClientShiftId,
  dstClientShiftId,
  originSupplierEmployeeSlotId,
  directSupplierProjectId
}) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/os-change-shift`, {
    srcClientShiftId,
    dstClientShiftId,
    originSupplierEmployeeSlotId,
    directSupplierProjectId
  });

export const updateSlot = ({ accountId, branchId, projectId, shiftId, slotId, slot, src }) =>
  patch(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/slots/${slotId}?src=${src}`,
    slot
  );

export const updateShowStatusUnbooking = ({ accountId, branchId, projectId, shiftId, unbookingId, noShow, notes }) =>
  patch(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/unbookings/${unbookingId}`,
    { noShow, notes }
  );

export const createConfirmation = ({ accountId, branchId, projectId, shiftIds, message }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/confirmations`, {
    shifts: shiftIds,
    message
  });

export const resendConfirmations = ({ accountId, branchId, projectId }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/confirmations/resend`);

export const updateConfirmationSlot = ({
  accountId,
  branchId,
  projectId,
  shiftId,
  slotId,
  confirmationId,
  isConfirmed
}) =>
  patch(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/slots/${slotId}/confirmations/${confirmationId}`,
    { isConfirmed }
  );

export const autoFillTimeSheet = ({ accountId, ids, override, shouldWaitForBreaktimes }) =>
  put(`/accounts/${accountId}/timesheets/autofill`, { ids, override, options: { shouldWaitForBreaktimes } });

export const autoFillOSTimeSheet = ({ accountId, ids, override, shouldWaitForBreaktimes }) =>
  put(`/accounts/${accountId}/timesheets/os/autofill`, { ids, override, options: { shouldWaitForBreaktimes } });

export const fetchSearchAvailableEmployees = ({
  accountId,
  branchId,
  projectId,
  shiftId,
  name,
  info,
  pageSize,
  token,
  filterByPositions = false
}) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/availability`, {
    name,
    info,
    pageSize,
    page: token || 0,
    kinds: ['available', 'applied', 'other', 'applied-rejection', 'available-rejection'],
    warnings: true,
    filterByPositions
  });

export const shareShiftToSocial = ({
  accountId,
  branchId,
  projectId,
  shiftId,
  sharedDataKeys,
  additionalInformation,
  specialRequirements
}) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/social-share`, {
    sharedDataKeys,
    additionalInformation,
    specialRequirements
  });

export const fetchAvailableEmployeesByTimeAndPosition = ({
  accountId,
  branchId,
  startTime,
  endTime,
  positionId,
  name,
  pageSize,
  token
}) =>
  get(`/accounts/${accountId}/branches/${branchId}/availability`, {
    startTime,
    endTime,
    positionId,
    name,
    pageSize,
    page: token || 0,
    kinds: ['available', 'other', 'applied-rejection', 'available-rejection'],
    warnings: true,
    info: false
  });

export const fetchAvailableEmployeesByDate = ({ accountId, branchId, date }) =>
  get(`/accounts/${accountId}/branches/${branchId}/availability/date`, {
    date
  });

export const fetchShiftApplicationsHistory = ({ accountId, branchId, projectId, shiftId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/applications-history`);

export const fetchAvailableEmployees = ({ accountId, branchId, projectId, name, showRejected }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/availability`, {
    name,
    projectIds: [projectId],
    pageSize: 500,
    page: 0,
    kinds: showRejected ? ['applied', 'applied-rejection', 'available-rejection'] : ['applied'],
    warnings: true,
    info: true
  });

export const fetchProjectInfo = ({ accountId, branchId, projectId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/info`);

export const fetchProjectFinancials = ({ accountId, branchId, projectId, mode, withRevenue, from, to }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/payroll/financials`, {
    mode,
    withRevenue,
    from,
    to
  });

export const fetchOSProjectInfo = ({ accountId, branchId, projectId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/os/info`);

export const publishProjects = ({ accountId, branchId, projectIds, consolidate }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/broadcast`, { ids: projectIds, consolidate });

export const publishProjectEmployeeSlot = ({ accountId, branchId, projectId, employeeSlotsIds }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/broadcast/slots`, {
    ids: employeeSlotsIds
  });

export const copyProjects = ({ accountId, branchId, data }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/copy`, data);

export const copyMultipleBranchesProjects = ({
  accountId,
  branches,
  fromDate,
  toDate,
  weeksToCopy,
  copyEmployees,
  copyApplications,
  copyOrders,
  wait
}) =>
  post(`/accounts/${accountId}/projects/copy`, {
    branches,
    fromDate,
    toDate,
    weeksToCopy,
    copyEmployees,
    copyApplications,
    copyOrders,
    wait
  });

export const deleteProjectsShiftsInRange = ({ accountId, branchId, from, to }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/shifts`, { from, to });

export const unbookProjectsShiftsInRange = ({ accountId, branchId, from, to }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/shifts/unbook`, { from, to });

export const clientTimesheetSendProject = ({ accountId, branchId, projectId, adminIds, sendCopy }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/clients/timesheet/send`, {
    adminIds,
    sendCopy
  });

export const clientSendBookedEmployees = ({ accountId, branchId, projectId, adminIds, sendCopy }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/clients/bookedEmployees/send`, {
    adminIds,
    sendCopy
  });

export const fetchPosts = ({ accountId, projectId, branches, token, search }) =>
  get(`/accounts/${accountId}/posts`, { search, projectId, branches, pageSize: 20, token: token || 0 });

export const fetchPost = ({ accountId, postId }) => get(`/accounts/${accountId}/posts/${postId}`);

export const addPost = ({ accountId, branches, ...params }) =>
  post(`/accounts/${accountId}/posts`, { branches, ...params });

export const editPost = ({ accountId, id: postId, ...params }) =>
  patch(`/accounts/${accountId}/posts/${postId}`, params);

export const deletePost = ({ accountId, id: postId }) => httpDelete(`/accounts/${accountId}/posts/${postId}`);

export const getFilters = ({ accountId }) => get(`/accounts/${accountId}/filters`);

export const fetchPublication = ({ accountId, publicationId }) =>
  get(`/accounts/${accountId}/publications/${publicationId}`);

export const fetchPublicationsBatch = ({ accountId, publicationIds }) =>
  get(`/accounts/${accountId}/publications/batch`, { publicationIds });

export const addPublication = ({ accountId, ...params }) => post(`/accounts/${accountId}/publications`, params);

export const updatePublication = ({ accountId, publicationId, ...params }) =>
  put(`/accounts/${accountId}/publications/${publicationId}`, params);

export const notifyPublication = ({ accountId, publicationId, ...params }) =>
  post(`/accounts/${accountId}/publications/${publicationId}/notification`, params);

export const notifyBulkPublication = ({ accountId, publicationIds, ...params }) =>
  post(`/accounts/${accountId}/publications/notification`, { ids: publicationIds, ...params });

export const updatePublicationInfo = ({ accountId, publicationId, ...params }) =>
  patch(`/accounts/${accountId}/publications/${publicationId}`, params);

export const fetchReports = () => get('/reports');

export const fetchReportCategories = () => get('/categories');

export const fetchReport = ({ accountId, reportId, fromDate, toDate, invoiceNumber, payrollModes, branches }) =>
  get(`accounts/${accountId}/reports/${reportId}`, { fromDate, toDate, invoiceNumber, payrollModes, branches });

export const fetchActiveAccountModules = ({ accountId }) => get(`/accounts/${accountId}/modules`);

export const updateModules = ({ accountId, modules }) => put(`/accounts/${accountId}/modules`, { moduleIds: modules });

export const postAction = () => delay({});

export const fetchClients = ({ accountId, withLastOrderDate, searchTerm }) =>
  get(`/accounts/${accountId}/clients`, { withLastOrderDate, searchTerm });

export const fetchClient = ({ accountId, clientId }) => get(`/accounts/${accountId}/clients/${clientId}`);

export const fetchClientLocations = ({ accountId, clientId }) =>
  get(`/accounts/${accountId}/clients/${clientId}/locations`);

export const fetchClientSpecificLocation = ({ accountId, clientId, clientBranchId, locationId, withAttachments }) =>
  get(`/accounts/${accountId}/clients/${clientId}/branches/${clientBranchId}/locations/${locationId}`, {
    withAttachments
  });

export const addClientLocation = ({ accountId, clientId, clientBranchId, location }) =>
  post(`/accounts/${accountId}/clients/${clientId}/branches/${clientBranchId}/locations`, { ...location });

export const updateClientLocation = ({
  accountId,
  clientId,
  clientBranchId,
  locationId,
  location: { name, photo, lat, lng, address, subLocations }
}) =>
  patch(
    `/accounts/${accountId}/clients/${clientId}/branches/${clientBranchId}/locations/${locationId}`,
    omitBy({ name, lat, lng, address, photo, subLocations }, isUndefined)
  );

export const fetchBranchCostCenters = ({ accountId, branchId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/cost-centers`);

export const addBranchCostCenter = ({ accountId, branchId, name, identifier }) =>
  post(`/accounts/${accountId}/branches/${branchId}/cost-centers`, { name, identifier });

export const updateBranchCostCenter = ({ costCenterId, accountId, branchId, name, identifier }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/cost-centers/${costCenterId}`, { name, identifier });

export const deleteBranchCostCenter = ({ costCenterId, accountId, branchId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/cost-centers/${costCenterId}`);

export const addClient = ({ accountId, ...params }) => post(`/accounts/${accountId}/clients`, params);

export const addClients = ({ accountId, clients }) => post(`/accounts/${accountId}/clients/bulk`, { clients });

export const updateClient = ({ accountId, clientId, ...params }) =>
  patch(`/accounts/${accountId}/clients/${clientId}`, params);

export const deleteClient = ({ accountId, clientId }) => httpDelete(`/accounts/${accountId}/clients/${clientId}`);

export const inviteClientAdmin = ({ accountId, clientId, adminId }) =>
  post(`/accounts/${accountId}/clients/${clientId}/admins/${adminId}`);

export const fetchEmployeeSlotBreakPlots = ({ accountId, employeeSlotId }) =>
  get(`/accounts/${accountId}/employees-slots/${employeeSlotId}/breakplots`);

export const updateEmployeeSlotBreakPlots = ({ accountId, employeeSlotId, ...values }) =>
  put(`/accounts/${accountId}/employees-slots/${employeeSlotId}/breakplots`, { ...values });

export const fetchClientComments = ({ accountId, clientId }) =>
  get(`/accounts/${accountId}/clients/${clientId}/comments`);

export const deleteClientComment = ({ accountId, clientId, id }) =>
  httpDelete(`/accounts/${accountId}/clients/${clientId}/comments/${id}`);

export const addClientComment = ({ accountId, clientId, comment }) =>
  post(`/accounts/${accountId}/clients/${clientId}/comments`, { comment });

export const fetchSuppliers = ({ accountId, clientBranchId }) =>
  get(`/accounts/${accountId}/suppliers`, { clientBranchId });

export const fetchSupplier = ({ accountId, supplierId }) => get(`/accounts/${accountId}/suppliers/${supplierId}`);

export const addSupplier = ({ accountId, ...params }) => post(`/accounts/${accountId}/suppliers`, params);

export const updateSupplier = ({ accountId, supplierId, ...params }) =>
  patch(`/accounts/${accountId}/suppliers/${supplierId}`, params);

export const fetchSmartAnalytics = ({ from, to }) => get(`/analytics/highlights`, { from, to });

export const fetchNewAccounts = ({ from, to }) => get(`/analytics/newAccounts`, { from, to });

export const fetchNewAdmins = ({ from, to }) => get(`/analytics/newAdmins`, { from, to });

export const fetchNewAppUsers = ({ from, to }) => get(`/analytics/newAppUsers`, { from, to });

export const fetchSchedulingOverview = ({ from, to }) => get(`/analytics/schedulingOverview`, { from, to });

export const fetchTimeclockOverview = ({ from, to }) => get(`/analytics/timeclockOverview`, { from, to });

export const fetchAccountsStatuses = ({ from, to }) => get(`/analytics/accountsStatuses`, { from, to });

export const fetchAccountsCountries = ({ from, to }) => get(`/analytics/accountsCountries`, { from, to });

export const fetchAccountsIndustries = ({ from, to }) => get(`/analytics/accountsIndustries`, { from, to });

export const fetchAccountsCharts = ({ from, to }) => get(`/analytics/accountChart`, { from, to });

export const fetchSmartReportsAnalytics = () => get(`/analytics/reports/highlights`);

export const fetchTotalTimesheetsHours = () => get(`/analytics/totalTimesheetsHours`);

export const fetchAnalyticsBookedUnique = () => get(`/analytics/bookedUnique`);

export const fetchAnalyticsBookedUniqueByCountry = () => get(`/analytics/bookedUniqueByCountry`);

export const fetchAnalyticsBookedUniqueByCountryByCompany = (countryId) =>
  get(`/analytics/bookedUniqueByCountryByCompany`, { countryId: countryId || undefined });

export const fetchAccountAnalyticsBookedVsNeeded = ({ accountId }) =>
  get(`/accounts/${accountId}/dashboard/analytics/bookedVsNeeded`);

export const fetchAccountAnalyticsFulfillment = ({ accountId }) =>
  get(`/accounts/${accountId}/dashboard/analytics/fulfillment`);

export const fetchAccountTotalTimesheetsHours = ({ accountId }) =>
  get(`/accounts/${accountId}/dashboard/analytics/totalTimesheetsHours`);

export const fetchAccountAnalyticsBookedUnique = ({ accountId }) =>
  get(`/accounts/${accountId}/dashboard/analytics/bookedUnique`);

export const fetchAnalyticsBookedVsNeeded = () => get(`/analytics/bookedVsNeeded`);

export const fetchAllPayrolls = () => get(`/analytics/allPayrolls`);

export const fetchAnalyticsActualVsScheduled = () => get(`/analytics/actualVsScheduledTimesheets`);

export const fetchAnalyticsFulfillment = () => get(`/analytics/fulfillment`);

export const fetchFinancial = () => delay(dashboardFinancial);

export const fetchPlans = () => get(`/plans`);

export const fetchPayroll = ({
  accountId,
  branches,
  from,
  to,
  client,
  asClient,
  withoutOnCost,
  clientIds,
  supplierId,
  employeeIds,
  mode
}) =>
  get(`/accounts/${accountId}/payroll`, {
    from,
    to,
    employeeIds,
    branches,
    clientIds,
    supplierId,
    options: { client, asClient, withoutOnCost },
    mode
  });

export const processPayroll = ({ accountId, employeesSlotsIds, from, to, branchIds, dummy }) =>
  post(`accounts/${accountId}/payroll/processPayrolls`, { from, to, employeesSlotsIds, branchIds, dummy });

export const payrollPayQuery = ({ accountId, employeeSlotId }) =>
  patch(`accounts/${accountId}/payroll/payQuery`, { employeeSlotId });

export const fetchClientInvoices = ({ accountId, clientId, from, to }) =>
  get(`/accounts/${accountId}/clients/${clientId}/invoices`, {
    accountId,
    clientId,
    from,
    to
  });

export const fetchSupplierInvoices = ({ accountId, supplierId, from, to }) =>
  get(`/accounts/${accountId}/suppliers/${supplierId}/invoices`, {
    accountId,
    supplierId,
    from,
    to
  });

export const sendSupplierInvoiceEmail = ({ accountId, supplierId, from, to, supplierAdminIds, columns, sendCopy }) =>
  post(`/accounts/${accountId}/suppliers/${supplierId}/invoices/email`, {
    from,
    to,
    supplierAdminIds,
    columns,
    sendCopy
  });

export const updateWage = ({ accountId, wageId, wage }) => patch(`/accounts/${accountId}/wages/${wageId}`, wage);

export const updateJobPayrollField = ({ accountId, jobId, payrollFieldId, value }) =>
  patch(`/accounts/${accountId}/jobs/${jobId}/payroll/fields/${payrollFieldId}`, { value });

export const updateSlotPayrollField = ({ accountId, employeeSlotId, payrollFieldId, valueKey = 'value', value }) =>
  patch(`/accounts/${accountId}/employees-slots/${employeeSlotId}/payroll/fields/${payrollFieldId}`, {
    [valueKey]: value
  });

export const updateSlotPayrollFields = ({ accountId, employeeSlotId, values }) =>
  patch(`/accounts/${accountId}/employees-slots/${employeeSlotId}/payroll/fields`, { values });

export const fetchPayrollFields = ({ accountId }) => get(`/accounts/${accountId}/payroll/fields`);

export const fetchProjectPayrollFields = ({ accountId, branchId, projectId, asClient = true }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/payroll/fields`, { asClient });

export const addPayrollField = ({ accountId, field }) => post(`/accounts/${accountId}/payroll/fields`, field);

export const updatePayrollField = ({ accountId, fieldId, field }) =>
  patch(`/accounts/${accountId}/payroll/fields/${fieldId}`, field);

export const deletePayrollField = ({ accountId, fieldId }) =>
  patch(`/accounts/${accountId}/payroll/fields/${fieldId}`, { isDeleted: true });

export const fetchPayrollPeriods = () => get('/payroll-period-types');

export const fetchRequests = ({ accountId }) => get(`/accounts/${accountId}/requests`);

export const fetchPaginatedRequests = ({ accountId, status, branchIds, page, pageSize, searchTerm }) =>
  get(`/accounts/${accountId}/requests/paginated`, {
    status,
    branches: branchIds,
    page,
    pageSize,
    searchTerm
  });

export const addRequest = ({ accountId, employeeId, typeId, branchId, jobTypeId, startTime, endTime }) =>
  post(`/accounts/${accountId}/requests`, {
    typeId,
    employeeId,
    branchId,
    data: { startTime, endTime, jobTypeId }
  });

export const deleteRequest = ({ accountId, requestId }) => httpDelete(`/accounts/${accountId}/requests/${requestId}`);

export const deleteTimeOff = ({ accountId, timeOffId }) => httpDelete(`/accounts/${accountId}/timeoffs/${timeOffId}`);

export const fetchRequestsStats = ({ accountId }) => get(`/accounts/${accountId}/requests/stats`);

export const fetchSupplierBranchOrderStats = ({ accountId, branchId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/orders/stats`);

export const fetchSupplierAccountOrderStats = ({ accountId }) => get(`/accounts/${accountId}/orders/stats`);

export const fetchRequestsTypes = () => get('/requests/types');

export const updateRequest = ({ accountId, requestId, ...body }) =>
  patch(`/accounts/${accountId}/requests/${requestId}`, body);

export const fetchPeriod = ({ accountId, branchId, date }) =>
  get(`/accounts/${accountId}/branches/${branchId}/periods`, { date });

export const updatePeriod = ({ accountId, branchId, id, ...params }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/periods/${id}`, params);

export const createPeriod = ({ accountId, branchId, date }) =>
  post(`/accounts/${accountId}/branches/${branchId}/periods`, { date });

export const fetchManagers = ({ accountId, adminId }) => get(`/accounts/${accountId}/managers/${adminId}/employees`);

export const updateManagers = ({ accountId, adminId, ids }) =>
  put(`/accounts/${accountId}/managers/${adminId}/employees`, { ids });

export const resendAdminEmail = ({ accountId, employeeId }) =>
  post(`/accounts/${accountId}/employees/${employeeId}/admins/invite`);

export const autoFillOpenShiftsProjects = ({ accountId, branchId, from, to }) =>
  post(`/accounts/${accountId}/branches/${branchId}/schedule/autoOpenShifts`, { from, to });

export const autoFillTimeAvailabilitiesProjects = ({ accountId, branchId, from, to, resetShifts }) =>
  post(`/accounts/${accountId}/branches/${branchId}/schedule/autoTimeAvailabilities`, { from, to, resetShifts });

export const autoFillProjects = ({ accountId, branchId, date }) =>
  post(`/accounts/${accountId}/branches/${branchId}/schedule/auto`, { date });

export const autoFillProjectTimesheet = ({ accountId, branchId, projectId, shouldWaitForBreaktimes, ...params }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/timesheets`, {
    ...params,
    options: { shouldWaitForBreaktimes }
  });

export const autoFillOSProjectTimesheet = ({ accountId, branchId, projectId, shouldWaitForBreaktimes, ...params }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/timesheets/os`, {
    ...params,
    options: { shouldWaitForBreaktimes }
  });

export const addSchedulesShift = ({ accountId, branchId, projectId, shiftId, employeeIds }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/booking/bulk`, {
    employeeIds
  });

export const fetchAvailabilities = ({ accountId, start, end, branches, employeeIds }) =>
  get(`/accounts/${accountId}/availabilities`, { start, end, branches, employeeIds });

export const updateAvailabilities = ({ accountId, body }) => put(`/accounts/${accountId}/availabilities`, body);

export const fetchSequences = ({ accountId, projects, kind, withSubmissions, types }) =>
  get(`/accounts/${accountId}/sequences`, { projects, kind, withSubmissions, types });

export const updateSequencesPriority = ({ accountId, sequences }) =>
  post(`/accounts/${accountId}/sequences/priority`, { sequences });

export const fetchSequence = ({ accountId, sequenceId }) => get(`/accounts/${accountId}/sequences/${sequenceId}`);

export const fetchSequenceResult = ({ accountId, sequenceId, instanceId }) =>
  get(`/accounts/${accountId}/sequences/${sequenceId}/instances/${instanceId}/export`);

export const addSequence = ({ accountId, ...body }) => post(`/accounts/${accountId}/sequences`, body);

export const putSequence = ({ accountId, payloads }) => put(`/accounts/${accountId}/sequences`, { ...payloads });

export const updateSequence = ({ accountId, sequenceId, ...body }) =>
  patch(`/accounts/${accountId}/sequences/${sequenceId}`, body);

export const publishSequenceToNotAnswered = ({ accountId, sequenceId }) =>
  post(`/accounts/${accountId}/sequences/${sequenceId}/publishToNotAnswered`);

export const deleteSequence = ({ accountId, sequenceId }) =>
  httpDelete(`/accounts/${accountId}/sequences/${sequenceId}`);

export const publishProjectsApplications = ({ accountId, branchId, data, emptyShiftsOnly, withPush }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/publish`, { data, emptyShiftsOnly, withPush });

export const fetchProjectTodo = ({ accountId, branchId, projectId }) =>
  get(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/todos`);

export const addProjectTodo = ({ accountId, branchId, projectId, ...params }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/todos`, params);

export const updateProjectTodo = ({ accountId, branchId, projectId, todoId, ...params }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/todos/${todoId}`, params);

export const deleteProjectTodo = ({ accountId, branchId, projectId, todoId }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/todos/${todoId}`);

export const fetchOrganization = ({ accountId }) => get(`/accounts/${accountId}/managers`);

//DASHBOARD

export const fetchAccountOverview = ({ accountId }) => get(`/accounts/${accountId}/dashboard/steps`);

export const fetchBranchesOverview = ({ accountId, from, to }) =>
  get(`/accounts/${accountId}/dashboard/branches/payroll`, { from, to });

export const fetchBranchesLocations = ({ accountId }) => get(`/accounts/${accountId}/dashboard/locations`);

export const fetchEmployeesOverview = ({ accountId, from, to }) =>
  get(`/accounts/${accountId}/dashboard/employees/milestones`, { from, to });

export const fetchPayrollOverview = ({ accountId, from, to }) =>
  get(`/accounts/${accountId}/dashboard/account/payroll`, { from, to });

export const enableRevenue = ({ accountId }) => post(`/accounts/${accountId}/dashboard/revenue`);

export const fetchGenericIntegrations = () => get('/integrations');

export const fetchIntegrations = ({ accountId }) => get(`/accounts/${accountId}/integrations`);

export const updateIntegrations = ({ accountId, integrationId, ...params }) =>
  put(`/accounts/${accountId}/integrations/${integrationId}`, params);

export const integrationsOAuth2ExchangeCode = (integrationId, code) =>
  post(`/integrations/${integrationId}/code-exchange`, { code });

export const deleteEmployeeApplication = ({
  accountId,
  branchId,
  employeeIds,
  projectId,
  shiftId,
  withNotification,
  message = undefined
}) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/employees`, {
    employeeIds,
    withNotification,
    message
  });

export const updateEmployeeApplication = ({ accountId, branchId, projectId, shiftId, employeeId, data }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/employees/${employeeId}`, {
    ...data
  });

export const fetchEmployeeShiftUnbookings = ({ accountId, employeeId }) =>
  get(`/accounts/${accountId}/employees/${employeeId}/unbookings`);

export const fetchEmployeeApplications = ({ accountId, employeeId, includeRejectedApplications }) =>
  get(`/accounts/${accountId}/employees/${employeeId}/applications`, { includeRejectedApplications });

export const updateProjectRegistration = ({ accountId, branchId, projectId, isPaused, message }) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/registration`, { isPaused, message });

export const fetchAuditLogs = ({ accountId, timesheetIds, jobIds, projectIds, employeeIds, token, pageSize }) =>
  get(`/accounts/${accountId}/audit-logs`, {
    timesheetIds,
    jobIds,
    pageSize,
    projectIds,
    employeeIds,
    token: token || 0
  });

export const exportAuditLogs = ({ accountId, timesheetIds, jobIds, projectIds, employeeIds, pageSize }) =>
  get(`/accounts/${accountId}/audit-logs/export`, {
    accountId,
    timesheetIds,
    jobIds,
    projectIds,
    employeeIds,
    pageSize
  });

export const fetchPresentations = ({ accountId, projectId, presentationHash }) =>
  get(`/accounts/${accountId}/projects/${projectId}/presentation`, { presentationHash });

export const shiftAvailabilityRelease = ({ accountId, branchId, projectId, shiftId, message }) =>
  httpDelete(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/releaseEmployees`, {
    message
  });

export const updateShiftRegistration = ({
  accountId,
  branchId,
  projectId,
  isPaused,
  publicationId,
  shiftId,
  message
}) =>
  patch(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/registration`, {
    isPaused,
    publicationId,
    message
  });

export const addShift = ({ accountId, branchId, projectId, values }) =>
  post(`/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts`, values);

export const fetchTimeAvailabilities = ({ accountId, branchIds, from, to }) =>
  get(`/accounts/${accountId}/timeAvailabilities`, { branchIds, from, to });

export const fetchEmployeesTimeAvailabilities = ({ accountId, branchIds, from, to, employeeIds }) =>
  get(`/accounts/${accountId}/timeAvailabilities`, { branchIds, from, to, employeeIds });

export const addTimeAvailabilities = ({ accountId, timeAvailabilities, employeeId }) =>
  post(`/accounts/${accountId}/timeAvailabilities`, { timeAvailabilities, employeeId });

export const deleteTimeAvailability = ({ accountId, timeAvailabilityId }) =>
  httpDelete(`/accounts/${accountId}/timeAvailabilities/${timeAvailabilityId}`);

export const tempFix = () => post(`/accounts/tempFix`);

export const CopyPositionMappingSupplierToClients = (params) =>
  post(`/tools/copyPositionMappingSupplierToClients`, params);

export const CopyPositionMappingClientToSuppliers = (params) =>
  post(`/tools/copyPositionMappingClientToSuppliers`, params);

export const AddClientToThirdPartyPositionMapping = (params) =>
  post(`/tools/addClientToThirdPartyPositionMapping`, params);

export const AddPositionToOrganization = (params) => post(`/tools/addPositionToOrganization`, params);
export const AddPositionsToAccountIds = (params) => post(`/tools/addPositionsToAccountIds`, params);

export const fetchEmployeeJobStats = ({
  accountId,
  employeeId,
  futureFromDate,
  futureToDate,
  pastFromDate,
  pastToDate,
  includeRejectedApplications = true
}) =>
  get(`/accounts/${accountId}/employees/${employeeId}/stats/jobs`, {
    futureFromDate,
    futureToDate,
    pastFromDate,
    pastToDate,
    includeRejectedApplications
  });

export const fetchBranchOrdersForecast = ({ accountId, branches, from, to }) =>
  get(`/accounts/${accountId}/branch-orders-forecast`, {
    branches,
    from: moment(from).format('YYYY-MM-DD'),
    to: moment(to).format('YYYY-MM-DD')
  });

export const fetchWeatherData = ({ accountId, from, to, locationIds }) =>
  get(`/accounts/${accountId}/weather/forecast`, {
    locationIds,
    from: moment(from).format('YYYY-MM-DD'),
    to: moment(to).format('YYYY-MM-DD')
  });

export const updateBranchOrdersForecast = ({ accountId, data }) =>
  put(`/accounts/${accountId}/branch-orders-forecast`, { data });

export const fetchGeneralAvailabilities = ({ accountId, employeeIds }) =>
  get(`/accounts/${accountId}/general-availabilities`, { employeeIds });

export const updateGeneralAvailability = ({ accountId, boards }) =>
  put(`/accounts/${accountId}/general-availabilities`, { boards });

export const upsertAccountChatRoom = ({ accountId }) => put(`/accounts/${accountId}/chatrooms`);

export const fetchEmployeeComments = ({ accountId, employeeId }) =>
  get(`/accounts/${accountId}/employees/${employeeId}/comments`);

export const deleteEmployeeComment = ({ accountId, employeeId, id }) =>
  httpDelete(`/accounts/${accountId}/employees/${employeeId}/comments/${id}`);

export const addEmployeeComment = ({ accountId, employeeId, comment, typeId }) =>
  post(`/accounts/${accountId}/employees/${employeeId}/comments`, { comment, typeId });

export const fetchEmployeeCompliance = ({ accountId, employeeId }) =>
  get(`/accounts/${accountId}/employees/${employeeId}/compliance`);

export const fetchCompliances = ({ accountId, positionId, branchIds, isActive }) =>
  get(`/accounts/${accountId}/compliances`, { positionId, branchIds, isActive });

export const fetchAccountPackages = ({ accountId }) => get(`/accounts/${accountId}/packages`);
export const addAccountPackage = ({ accountId, name, rules, filters, type }) =>
  post(`/accounts/${accountId}/packages`, {
    name,
    rules,
    filters,
    type
  });

export const editAccountPackage = ({ accountId, packageId, name, rules, filters, type }) =>
  patch(`/accounts/${accountId}/packages/${packageId}`, {
    name,
    rules,
    filters,
    type
  });
export const deleteAccountPackages = ({ accountId, packageId }) =>
  httpDelete(`/accounts/${accountId}/packages/${packageId}`);

export const fetchEmployeePackages = ({ accountId, employeeId }) =>
  get(`/accounts/${accountId}/employees/${employeeId}/packages`);

export const fetchSupplierOrders = ({ accountId, supplierId }) =>
  get(`/accounts/${accountId}/suppliers/${supplierId}/orders`);

export const fetchClientOrders = ({ accountId, clientId }) => get(`/accounts/${accountId}/clients/${clientId}/orders`);

export const fetchOrderDetails = ({ accountId, orderId }) => get(`/accounts/${accountId}/orders/${orderId}`);

export const createOrderChange = ({ accountId, orderId, data }) =>
  post(`/accounts/${accountId}/orders/${orderId}/changes`, data);

export const publishOrder = ({ accountId, orderId }) =>
  patch(`/accounts/${accountId}/orders/${orderId}/changes/publish`);

export const approveOrderChange = ({ accountId, orderId, applyChangesOnShifts, applyChangesOnOrders }) =>
  patch(`/accounts/${accountId}/orders/${orderId}/changes`, { applyChangesOnShifts, applyChangesOnOrders });

export const rejectOrderChange = ({ accountId, orderId, data }) =>
  httpDelete(`/accounts/${accountId}/orders/${orderId}/changes`, data);

export const fetchProfitAndLossAnalytics = ({ accountId, from, to }) =>
  get(`/accounts/${accountId}/dashboard/analytics/profitAndLoss`, { from, to });

export const fetchFeatureGate = ({ accountId }) => get(`/accounts/${accountId}/feature-gate`);

export const fetchPayRates = (accountId) => get(`/accounts/${accountId}/payrates`);

export const syncPayRates = (accountId, payrates) => put(`/accounts/${accountId}/payrates`, payrates);

export const addPayRate = (accountId, payrate) => post(`/accounts/${accountId}/payrates/rate`, payrate);

export const updatePayRate = (accountId, payRateId, payrate) =>
  patch(`/accounts/${accountId}/payrates/rate/${payRateId}`, payrate);

export const removePayRate = (accountId, payRateId) => httpDelete(`/accounts/${accountId}/payrates/rate/${payRateId}`);

export const fetchChatTags = ({ accountId }) => get(`/accounts/${accountId}/chat-tags`);

export const addChatTag = ({ accountId, tag }) => post(`/accounts/${accountId}/chat-tags`, tag);

export const updateChatTag = ({ accountId, tagId, tag }) => patch(`/accounts/${accountId}/chat-tags/${tagId}`, tag);

export const deleteChatTag = ({ accountId, tagId }) => httpDelete(`/accounts/${accountId}/chat-tags/${tagId}`);

export const createInvoice = ({ accountId, clientId, employeeSlotIds, branchIds, groupBy, issueDate }) =>
  post(`/accounts/${accountId}/clients/${clientId}/invoices`, { employeeSlotIds, branchIds, groupBy, issueDate });

export const createInvoiceForAllClients = ({ accountId, fromDate, toDate }) =>
  post(`/accounts/${accountId}/invoices`, { fromDate, toDate });

export const cancelInvoice = ({ accountId, invoiceId }) => patch(`/accounts/${accountId}/invoices/${invoiceId}/cancel`);

/*
type filters = {
	clientsIds: number[];
	status: string;
	fromDate: Date;
	toDate: Date;
};
 */
export const getIssuedInvoices = ({ accountId, filters }) =>
  post(`/accounts/${accountId}/invoices/issued`, { filters });

/*
type filters = {
	status: string;
	fromDate: Date;
	toDate: Date;
};
 */
export const getReceivedInvoices = ({ accountId, filters }) =>
  post(`/accounts/${accountId}/invoices/received`, { filters });

export const getInvoiceSnapshot = ({ accountId, invoiceId, tab }) =>
  get(`/accounts/${accountId}/invoices/${tab}/${invoiceId}`);

export const getInvoiceAttachment = ({
  accountId,
  integrationInvoiceId,
  integrationAttachmentId,
  attachmentMimeType
}) =>
  post(
    `/accounts/${accountId}/invoices/${integrationInvoiceId}/attachments/${integrationAttachmentId}`,
    {
      attachmentMimeType
    },
    { responseType: 'blob' }
  );

export const getInvoiceAsPdf = ({ accountId, invoiceId }) =>
  get(`/accounts/${accountId}/invoices/${invoiceId}/pdf`, {}, { responseType: 'blob' });

export const fetchClientBranchCostCenters = ({ accountId, clientId, clientBranchId }) =>
  get(`/accounts/${accountId}/clients/${clientId}/branches/${clientBranchId}/cost-centers`);

export const addClientBranchCostCenters = ({ accountId, clientId, clientBranchId, name, identifier }) =>
  post(`/accounts/${accountId}/clients/${clientId}/branches/${clientBranchId}/cost-centers`, { name, identifier });

export const updateClientBranchCostCenters = ({
  accountId,
  clientId,
  clientBranchId,
  costCenterId,
  name,
  identifier
}) =>
  patch(`/accounts/${accountId}/clients/${clientId}/branches/${clientBranchId}/cost-centers/${costCenterId}`, {
    name,
    identifier
  });

export const fetchPayrollProcessHistory = ({ accountId }) => get(`/accounts/${accountId}/payroll/process-history`);

export const fetchReconciliationPayrollProcessHistory = ({ accountId, payrollProcessHistoryIds }) =>
  post(`/accounts/${accountId}/payroll/process-history/reconciliation`, { payrollProcessHistoryIds });

export const fetchUserAction = ({ accountId }) => get(`/accounts/${accountId}/ubeya-tools/user-action`);

export const toolsReset = ({ accountId, email }) => post(`/accounts/${accountId}/ubeya-tools/admin-action`, { email });
