import React, { useMemo } from 'react';
import moment from 'moment';
import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import ReactMarkdown from 'react-markdown';
import { CircleIcon, FlexCenter, Map, Modal as BaseModal } from '@ubeya/shared-web/components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { ReactComponent as ClockIcon } from '@ubeya/shared-web/assets/share-clock.svg';
import { ReactComponent as DateIcon } from '@ubeya/shared-web/assets/share-calendar.svg';
import { ReactComponent as ClientIcon } from '@ubeya/shared-web/assets/share-client.svg';
import { ReactComponent as LocationIcon } from '@ubeya/shared-web/assets/share-location.svg';
import { ReactComponent as BaseEventEndedIcon } from '@ubeya/shared-web/assets/event-ended-icon.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ $isPreview }) =>
    $isPreview
      ? css`
          padding: 0 10px;
        `
      : css`
          padding: 0 15% 10% 15%;
        `};

  ${({ $isMobile }) =>
    $isMobile
      ? css`
          padding: 0 10px;
        `
      : css``};

  .circle-icon {
    padding: 5px;

    margin-right: 2px;
    width: 36px;

    svg {
      width: 36px;
    }
  }
`;

const AccountContent = styled(FlexCenter)`
  flex-direction: column;
  z-index: 1;
  width: 100%;
`;

const MainDataContainer = styled.div`
  margin-top: 5px;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  height: 180px;
  width: 180px;
  border-radius: 100%;
`;

const DetailWrapper = styled.div`
  margin: 2px 0;
  display: flex;
  align-items: center;
  ${({ $marginLeft }) => $marginLeft && 'margin-left:33px;'};
  ${({ $marginBottom }) => $marginBottom && 'margin-bottom:15px;'};

  word-break: break-all;
`;

const Detail = styled.div`
  ${({ $underline }) => $underline && 'color:#1B74F9; max-width: 70%;text-decoration:underline;cursor:pointer;'};
`;

const SubDataContainer = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: 30px;
    `}

  ${({ $showBorder }) =>
    $showBorder &&
    css`
      border-top: 1px solid ${({ theme }) => theme.colors.grey2};
      margin-top: 10px;
      padding: 10px;
    `}
`;

const Circle = styled(FlexCenter).attrs(() => ({ className: 'social-circle' }))`
  border-radius: 100%;
  border: 8px solid ${({ theme }) => theme.colors.white};
  z-index: 3;
  margin-top: -10%;
`;

const PositionWrapper = styled.div.attrs(() => ({ className: 'social-position' }))`
  font-weight: 500;
  font-size: 35px;
`;

const PayRateWrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;

  height: 40px;
  width: max-content;
  padding: 2px 10px;
  background: ${({ theme }) => theme.colors.greenPositive};
  border-radius: 30px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const LocaitonWrapper = styled.div`
  font-size: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const EmptyLogo = styled.div.attrs(() => ({ className: 'social-empty-logo' }))`
  display: flex;
  border-radius: 100%;
  height: 180px;
  width: 180px;
  align-items: center;
  justify-content: center;
  font-size: 50px;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};

  z-index: 1;
`;

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  margin-bottom: 2px;
`;

const ModalContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 10px;
`;

const Container = styled.div`
  position: relative;
  font-family: ${({ $isRtl }) => ($isRtl ? 'Heebo' : 'Poppins')};
  width: 100%;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      display: flex;
      flex-direction: column;
    `};

  ${({ $isPreview }) =>
    $isPreview
      ? css`
          overflow-y: scroll;
          max-height: 490px;
          flex: 1;
          width: 500px;
        `
      : css`
          height: 100%;
        `};
`;

const LocationImage = styled.img`
  width: 100%;
  height: ${({ $height }) => $height}px;
  object-fit: cover;
`;

const LocationImagePlaceHolder = styled.div`
  width: 100%;
  height: 100px;
`;

const ModalSubTitle = styled.div`
  font-weight: 400;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 20px;
`;

const EventEndedIcon = styled(BaseEventEndedIcon)`
  width: 180px;
  height: 180px;
`;

const LocationSection = ({ locationImageHeight, location, locationImage, lat, lng }) => {
  const { t } = useContext(ConfigContext);

  if (!location) {
    return <LocationImagePlaceHolder />;
  }

  return locationImage ? (
    <LocationImage src={locationImage} $height={locationImageHeight} />
  ) : (
    <Map
      markers={[
        {
          title: `${t('eventLocation')}- `,
          lat,
          lng
        }
      ]}
      height={locationImageHeight}
    />
  );
};

const ShareShiftSocialInfo = ({ account, shared = {}, isPreview = false, locationImageHeight = 300 }) => {
  const { t, currency, isRtl, timeFormat } = useContext(ConfigContext);

  const {
    logo,
    client,
    accountName,
    position,
    payRate,
    date,
    startTime,
    endTime,
    location,
    address,
    name,
    generalComments,
    shiftNotes,
    additionalInformation,
    locationImage,
    uniform,
    specialRequirements,
    lat,
    lng
  } = useMemo(
    () => ({
      ...(shared || {}),
      logo: account.logo,
      accountName: account.name,
      position: shared.position ? t(shared.position) : t('generalPosition'),
      payRate: shared.payRate ? `${shared.payRate}${currency} ${t('perhour')}` : '',
      date: moment(shared.date).format('MM/DD/YY, ddd'),
      startTime: moment(shared.startTime).format(timeFormat),
      endTime: shared.endTime && moment(shared.endTime).format(timeFormat),
      generalComments: shared.publicComments,
      shiftNotes: shared.comments,
      lat: Number(shared.locationLat),
      lng: Number(shared.locationLng)
    }),
    [account.logo, account.name, currency, shared, t, timeFormat]
  );

  const mainDetails = useMemo(
    () => [
      { icon: DateIcon, value: date },
      ...(location
        ? [
            {
              icon: LocationIcon,
              value: `${location}, ${address}`,
              underline: true,
              onClick: () => {
                window.open(`http://www.google.com/maps/place/${lat},${lng}`, '_blank');
              }
            }
          ]
        : []),
      ...(client ? [{ icon: ClientIcon, value: client }] : []),
      { icon: ClockIcon, value: `${startTime}${endTime ? `- ${endTime} ` : ''}` }
    ],
    [address, client, date, endTime, lat, lng, location, startTime]
  );

  const details = useMemo(
    () =>
      [{ uniform }, { generalComments }, { shiftNotes }, { specialRequirements }, { additionalInformation }]
        .filter((curr) => !!Object.values(curr)[0])
        .map((label) => {
          const key = Object.keys(label)[0];
          const value = Object.values(label)[0];

          return {
            title: key,
            value: ['specialRequirements', 'additionalInformation'].includes(key) ? (
              <ReactMarkdown>{value}</ReactMarkdown>
            ) : (
              value
            )
          };
        }),
    [additionalInformation, generalComments, shiftNotes, specialRequirements, uniform]
  );

  const showEventEndedModal = false;
  // moment().isAfter(moment(shared.startTime));

  return (
    <Container $isRtl={isRtl} $isPreview={isPreview} $isMobile={isMobile}>
      <AccountContent>
        <LocationSection
          locationImageHeight={locationImageHeight}
          location={location}
          locationImage={locationImage}
          lat={lat}
          lng={lng}
        />
        <Circle>
          {logo ? <Logo src={logo} /> : <EmptyLogo>{(accountName || '').charAt(0).toUpperCase()}</EmptyLogo>}
        </Circle>
      </AccountContent>
      <Wrapper $isPreview={isPreview} $isMobile={isMobile}>
        <MainDataContainer>
          <Row>
            <PositionWrapper>{position}</PositionWrapper>
            {payRate && <PayRateWrapper>{payRate}</PayRateWrapper>}
          </Row>
          <Row>
            <LocaitonWrapper>{name || location}</LocaitonWrapper>
          </Row>
        </MainDataContainer>

        <SubDataContainer>
          {(mainDetails || []).map((data, index) => (
            <DetailWrapper key={index}>
              {data.icon && <CircleIcon icon={data.icon} />}
              <Detail $underline={data.underline} onClick={data.onClick}>
                {data.value}
              </Detail>
            </DetailWrapper>
          ))}
        </SubDataContainer>

        <SubDataContainer $showBorder $marginBottom={!isPreview && isMobile}>
          {(details || []).map((data, index) => (
            <DetailWrapper key={`detail-${index}`} $marginBottom>
              <NotesWrapper>
                <Title>{t(data.title)}</Title>
                <Detail>{data.value}</Detail>
              </NotesWrapper>
            </DetailWrapper>
          ))}
        </SubDataContainer>
      </Wrapper>

      {showEventEndedModal && (
        <BaseModal size="small" showConfirmButton={false}>
          <ModalContainer>
            <ModalTitle>{t('eventEndedModalTitle')}</ModalTitle>
            <ModalSubTitle>{t('eventEndedModalSubTitle')}</ModalSubTitle>

            <EventEndedIcon />
          </ModalContainer>
        </BaseModal>
      )}
    </Container>
  );
};

export default ShareShiftSocialInfo;
