import React from 'react';
import styled from 'styled-components';
import { SmallSemiBold } from '../Typography';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

const COLORS = ['#1abc9c', '#273c75', '#3498db', '#9b59b6'];

const Container = styled.div`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;
const Wrapper = styled(SmallSemiBold)`
  display: inline-block;
  background: ${({ theme, color, background }) => background || `${color}2d` || `${theme.colors.primaryLight}12b`};
  color: ${({ theme, color }) => color || theme.colors.black};
  padding: 8px 12px;
  border-radius: 3px;
`;

const RemoveButton = styled(CloseIcon)`
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

const Chip = ({ label, colorIndex, background, color: baseColor, onRemove, className, onClick }) => {
  const color = colorIndex === undefined ? baseColor : COLORS[colorIndex % COLORS.length];

  return (
    <Container onClick={onClick}>
      <Wrapper color={color} background={background} className={className}>
        {label}
        {onRemove && <RemoveButton onClick={onRemove} />}
      </Wrapper>
    </Container>
  );
};

export default Chip;
