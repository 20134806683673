/* eslint-disable no-console */
import React, { useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
// import { isIOS, isDesktop } from 'react-device-detect';
import useUser from '@ubeya/shared/hooks/auth/useUser';
import useMenu from '../../hooks/useMenu';
import { DropDownContextProvider } from '../../contexts/DropDownContext';
import { FlexMiddle, FlexColumn, FlexCenter } from '../Flex';
import { card } from '../Card';

// import { Mixpanel } from '../../analytics/Mixpanel';

import { ReactComponent as BaseArrow } from '../../assets/arrow.svg';
import ConfigContext from '../../contexts/ConfigContext';
import { Search } from '..';
import DropDownItem from './DropDownItem';
import DropDownSeparator from './DropDownSeparator';

const Wrapper = styled.div.attrs(() => ({ className: 'drop-down-wrapper' }))`
  position: relative;

  &&& {
    ${({ $isOpen }) => $isOpen && 'display:block;'}
  }
`;

const Clickable = styled(FlexMiddle)`
  cursor: pointer;
`;

const SearchContainer = styled(FlexCenter)`
  position: sticky;
  top: 0;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.colors.white};

  .drop-down-search {
    max-width: 100%;
    width: 100%;

    > div:last-child {
      max-width: 100%;
      width: 100%;
    }
  }
`;

const Arrow = styled(BaseArrow)`
  transform: rotate(${({ $isOpen }) => ($isOpen ? -90 : 90)}deg);
  width: 12px;
  margin-left: 7px;
  > path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const NoOption = styled.div``;

const Options = styled(FlexColumn)`
  ${card};
  position: relative;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 5;
  gap: 3px;
  ${({ innerStyle }) =>
    css`
      ${innerStyle}
    `}

  ${({ menuOffset: { top, right } = {} }) =>
    css`
      ${top !== undefined && `top: ${top}px;`}
      ${right !== undefined && `right: ${right}px;`}
    `}

    ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow: auto;
    `}


    padding: 10px;
  ${({ $hidePaddingTop }) =>
    $hidePaddingTop &&
    css`
      padding-top: 0px;
    `}

  min-width: ${({ menuMinWidth }) => menuMinWidth}px;
  max-width: ${({ menuMaxWidth }) => menuMaxWidth}px;

  .drop-down-no-options {
    padding: 8px;
  }
`;

const DropDown = ({
  className,
  portalStyle,
  children,
  renderValue,
  menuOffset,
  menuMinWidth = 90,
  showArrow = true,
  clickableContainer: ClickableContainer,
  scrollingContainer,
  usePortal,
  controlledIsOpen = false,
  openFromRight = true,
  maxHeight,
  menuMaxWidth,
  print,
  showSearch = false
}) => {
  const { t } = useContext(ConfigContext);
  const [search, setSearch] = useState('');

  const {
    data: { id: adminId }
  } = useUser();

  const printLogs = print && [5443, 5122, 10340].includes(adminId);

  const { Portal, wrapperRef, isOpen: isMenuOpen, setToggle, setClose, style, menuRef } = useMenu({
    usePortal,
    openFromRight,
    menuOffset,
    scrollingContainer,
    printLogs
  });

  const isOpen = useMemo(() => controlledIsOpen || isMenuOpen, [controlledIsOpen, isMenuOpen]);

  const Container = ClickableContainer ?? Clickable;

  const filteredChildren = React.Children.toArray(children)
    .filter((child) => child.props.isVisible === undefined || child.props.isVisible)
    .filter((child) => {
      if (!showSearch) {
        // no search enabled, return all
        return true;
      }
      if (child.props.rawSearchLabel) {
        // specific search label was provided, search according to it
        return child.props.rawSearchLabel.toLowerCase().includes(search.toLowerCase());
      }
      if (typeof child.props.children !== 'string') {
        // search is enabled, but children prop is not a string, return all
        return true;
      }
      // search by children string value
      return child.props.children.toLowerCase().includes(search.toLowerCase());
    });

  const clickHandlers = useMemo(() => {
    const result = {};

    // const condition =
    //   !isDesktop &&
    //   isIOS &&
    //   ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);

    // if (printLogs && condition) {
    //   Mixpanel.track('CHANGE SHIFT CLICK ISSUE - 1', {
    //     adminId,
    //     isIOS,
    //     isIOSNew: /iPhone|iPad|iPod/i.test(navigator.userAgent),
    //     isMobile: /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    //     isDesktop,
    //     isTouchDevice: 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
    //     maxTouchPoints: navigator.maxTouchPoints,
    //     msMaxTouchPoints: navigator.msMaxTouchPoints,
    //     userAgent: navigator.userAgent,
    //     condition: isIOS && ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
    //   });
    // }

    //TEMP - DO NOT ENTER HERE
    // if (condition) {
    //   if (printLogs) {
    //     Mixpanel.track('CHANGE SHIFT CLICK ISSUE - 2', { adminId });
    //     console.log('CHANGE SHIFT CLICK ISSUE - 2', { adminId });
    //   }

    //   result.onTouchStart = () => {
    //     // below object passed to setToggle is for keep devtools console clear from error
    //     // as touchstart is a passive event and don't have such functions callable
    //     setToggle({
    //       preventDefault: () => {},
    //       stopPropagation: () => {}
    //     });
    //   };
    // } else {
    result.onClick = (e) => {
      // if (printLogs) {
      //   Mixpanel.track('CHANGE SHIFT CLICK ISSUE - 4 - Using onClick for non-iOS device', { adminId, e });
      //   console.log('CHANGE SHIFT CLICK ISSUE - 4 - Using onClick for non-iOS device', { adminId, e });
      // }
      return setToggle(e);
    };
    // }
    return result;
  }, [setToggle]);

  // if (printLogs && !clickHandlers?.onClick) {
  //   Mixpanel.track('CHANGE SHIFT CLICK ISSUE - 5', {
  //     onclick: !!clickHandlers?.onClick,
  //     adminId,
  //     setToggle: !!clickHandlers?.setToggle
  //   });
  // }

  return (
    <DropDownContextProvider value={{ isOpen, closeMenu: setClose }}>
      <Wrapper className={className} ref={wrapperRef} $isOpen={isOpen}>
        <Container onTouchStart={clickHandlers?.onTouchStart} onClick={clickHandlers?.onClick} isActive={isOpen}>
          {React.isValidElement(renderValue)
            ? React.cloneElement(renderValue, { isactive: { isOpen: isOpen.toString() } })
            : typeof renderValue === 'function'
            ? renderValue({ isOpen })
            : renderValue}
          {showArrow && <Arrow $isOpen={isOpen} />}
        </Container>

        {isOpen && (
          <Portal>
            <Options
              $hidePaddingTop={showSearch}
              ref={menuRef}
              innerStyle={{ ...style, ...portalStyle }}
              menuMinWidth={menuMinWidth}
              maxHeight={maxHeight}
              menuMaxWidth={menuMaxWidth}>
              {showSearch && (
                <SearchContainer menuMinWidth={menuMinWidth} maxHeight={maxHeight}>
                  <Search
                    className="drop-down-search"
                    placeholder={t('search')}
                    value={search}
                    onChange={setSearch}
                    keepOpen
                    autoFocus
                  />
                </SearchContainer>
              )}

              {showSearch && filteredChildren.length === 0 && (
                <NoOption className="drop-down-no-options">{t('noOptions')}</NoOption>
              )}

              {filteredChildren}
            </Options>
          </Portal>
        )}
      </Wrapper>
    </DropDownContextProvider>
  );
};

DropDown.Item = DropDownItem;
DropDown.Separator = DropDownSeparator;

export default DropDown;
