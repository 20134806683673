import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { ReactComponent as BaseScoreEmptyIcon } from '@ubeya/shared-web/assets/score-low.svg';
import { ReactComponent as BaseScoreMediumIcon } from '@ubeya/shared-web/assets/score-medium.svg';
import { ReactComponent as BaseScoreHighIcon } from '@ubeya/shared-web/assets/score-high.svg';
import { Tooltip } from '../Tooltip';

const ScoreEmptyIcon = styled(BaseScoreEmptyIcon)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.colors.redNegative};
`;
const ScoreMediumIcon = styled(BaseScoreMediumIcon)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.colors.yellowPartially};
`;
const ScoreHighIcon = styled(BaseScoreHighIcon)`
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.colors.greenPositive};
`;

const AccountScoreRenderer = ({ rowData, dataKey }) => {
  const value = get(rowData, dataKey);

  return (
    <>
      {!value || value < 50 ? (
        <Tooltip body="Low score">
          <ScoreEmptyIcon />
        </Tooltip>
      ) : value < 100 ? (
        <Tooltip body="Medium score">
          <ScoreMediumIcon />
        </Tooltip>
      ) : (
        <Tooltip body="High score">
          <ScoreHighIcon />
        </Tooltip>
      )}
      {/* <Score score={value} /> */}
    </>
  );
};

export default AccountScoreRenderer;
