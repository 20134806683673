import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Form } from 'react-final-form';
import { NavLink } from 'react-router-dom';
import { noop } from 'lodash';
import { FlexColumnCenter, card, flexColumn, BodySemiBold, SmallBody } from '@ubeya/shared-web/components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

const Wrapper = styled(FlexColumnCenter)`
  height: 100vh;
`;

const Container = styled.form`
  ${({ $inside }) =>
    $inside
      ? css`
          flex: 1;
        `
      : css`
          ${card}
          padding: 50px 28px 20px 28px;
        `};

  ${flexColumn};
  max-width: 400px;

  > div {
    margin-bottom: 30px;
    /* width: 240px; */
  }
`;

const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.redNegative};
  margin-top: 13px;
`;

const Link = styled(NavLink).attrs(() => ({ className: 'box-line-text' }))`
  padding-top: 20px;
  text-align: center;
  text-decoration: none;
`;

const Title = styled(BodySemiBold).attrs(() => ({ className: 'box-title' }))`
  font-size: 36px;
  font-weight: normal;
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.black};
`;

const SubTitle = styled(SmallBody).attrs(() => ({ className: 'box-sub-title' }))`
  margin: 0 0 20px !important;
  color: ${({ theme }) => theme.colors.disabled};
  text-align: center;
`;

const Box = ({
  onSubmit = noop,
  validate,
  decorators,
  children,
  linkTo,
  linkText,
  inside = false,
  submitSucceededTitle = '',
  showSubTitleUponSubmitted = true,
  title,
  subTitle,
  className,
  displayErrors = true
}) => {
  const { t } = useContext(ConfigContext);

  const content = (
    <Form
      onSubmit={async (values) => {
        try {
          await onSubmit(values);
        } catch (err) {
          return t(err.response?.data?.message || err.response?.data?.err);
        }
      }}
      validate={validate}
      decorators={decorators}
      render={({ handleSubmit, submitErrors, ...props }) => {
        const mainTitleText = props.submitSucceeded && submitSucceededTitle ? submitSucceededTitle : title;
        const displaySubTitle = subTitle && (props.submitSucceeded ? showSubTitleUponSubmitted : true);

        return (
          <Container $inside={inside} onSubmit={handleSubmit} className={className}>
            {title && <Title>{mainTitleText}</Title>}
            {displaySubTitle && <SubTitle>{subTitle}</SubTitle>}

            {children({ ...props, submitErrors })}

            {displayErrors && submitErrors && <ErrorText>{submitErrors}</ErrorText>}

            {linkTo && <Link to={linkTo}>{linkText}</Link>}
          </Container>
        );
      }}
    />
  );

  if (inside) {
    return content;
  }

  return <Wrapper>{content}</Wrapper>;
};

export default Box;
