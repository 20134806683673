import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { counterFormatter } from '../../utils/filters';
import { ReactComponent as FilterIcon } from '../../assets/filter.svg';
import { FlexCenter } from '../Flex';
import { extraSmall } from '../Typography';
import { Tooltip } from '../Tooltip';
import DropDown from '../DropDown';
import CircleIcon from '../CircleIcon';
import BaseSearch from '../Search';
import ConfigContext from '../../contexts/ConfigContext';
import TempFilterOptions from './TempFilterOptions';

const ValueWrapper = styled.div`
  position: relative;
`;

const Counter = styled(FlexCenter)`
  ${extraSmall};
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 -2px 10px 0 ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 15px;
  height: 15px;
`;

const Search = styled(BaseSearch)`
  .circle-icon {
    margin-top: 20px;
  }
  > div:last-child {
    margin-top: 20px;
    > input {
      border-radius: 5px;
      width: 100%;
      border: 1px solid ${({ theme }) => theme.colors.grey2};
      outline: 0;
      padding: 14px;
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
      &::placeholder {
        color: ${({ theme }) => theme.colors.disabled};
      }
      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }
`;

const Value = ({ isActive, showIcon, title, filters, search, setSearch }) => {
  const { t } = useContext(ConfigContext);
  const counter = useMemo(
    () =>
      Object.values(filters).reduce(
        (sum, array) => sum + array.reduce((prevSum, { value }) => prevSum + (counterFormatter(value) ? 1 : 0), 0),
        0
      ),
    [filters]
  );

  return (
    <Tooltip body={t('filter')}>
      <ValueWrapper>
        {showIcon ? (
          <CircleIcon isActive={isActive} icon={FilterIcon} />
        ) : (
          <Search placeholder={title} value={search} onChange={setSearch} keepOpen />
        )}
        {counter > 0 && <Counter>{counter}</Counter>}
      </ValueWrapper>
    </Tooltip>
  );
};

const TempFilter = ({
  showIcon = true,
  isDisabled = false,
  filters = {},
  title = '',
  setFilters,
  disabledFilters = [],
  usePortal
}) => {
  const [search, setSearch] = useState('');

  return (
    <DropDown
      showArrow={false}
      renderValue={
        <Value
          search={search}
          title={title}
          setSearch={setSearch}
          showIcon={showIcon}
          filters={filters}
          isDisabled={isDisabled}
        />
      }
      menuMinWidth={210}
      maxHeight={400}
      menuOffset={{ top: 10 }}
      usePortal={usePortal}>
      <TempFilterOptions
        showSearchBox={showIcon}
        search={search}
        setSearch={setSearch}
        selectedKey="employees"
        filters={filters}
        setFilters={setFilters}
        disabledFilters={disabledFilters}
      />
    </DropDown>
  );
};

export default TempFilter;
