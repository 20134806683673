import React, { useContext, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import BaseLabel from '../Label';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as BasePreviewIcon } from '../../assets/eye.svg';
import { CircleIcon, TextareaAutosize, flexCenter } from '..';
import ConfigContext from '../../contexts/ConfigContext';
import { TextPreviewModal } from '../Modal/TextPreviewModal';
import ErrorText from './ErrorText';

const PreviewIcon = styled(BasePreviewIcon)`
  width: 18px;
  height: 18px;

  &:hover {
    cursor: pointer;
  }
`;

const Label = styled(BaseLabel)`
  ${({ $enablePreviewModal }) =>
    $enablePreviewModal &&
    css`
      ${flexCenter};
      flex-direction: row;
    `}

  .label {
    display: flex;
  }
`;

const TitleCountWrapperTitle = styled.div`
  margin-right: 5px;
`;

const TitleCountWrapper = styled.div.attrs(() => ({ className: 'title-count-wrapper' }))`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  color: ${({ theme }) => theme.colors.grey4};
`;

export const TitleWithCharsCount = ({ title, value = '', maxLength = 250 }) => {
  const length = value.length;

  return (
    <TitleCountWrapper>
      <TitleCountWrapperTitle>{title}</TitleCountWrapperTitle>

      <div>
        {length}/{maxLength}
      </div>
    </TitleCountWrapper>
  );
};

const TextArea = ({
  title: baseTitle,
  name,
  value,
  maxLength,
  onChange,
  error,
  className,
  required,
  innerRef,
  showResizeIcon = true,
  showCharsCount = false,
  enablePreviewModal = false,
  ...inputProps
}) => {
  const { t } = useContext(ConfigContext);
  const [showPreview, setShowPreview] = useState(false);

  const inputRef = useRef();
  const textAreaRef = innerRef || inputRef;

  const title = showCharsCount ? (
    <TitleWithCharsCount title={baseTitle} value={value} maxLength={maxLength} />
  ) : (
    baseTitle
  );

  const inputParams = {
    id: name,
    value,
    maxLength,
    onChange: (e) => onChange(e.target.value),
    error,
    showResizeIcon,
    textAreaRef,
    ...inputProps
  };

  const isDisabled = inputParams.disabled;

  return (
    <Label
      title={title}
      required={required}
      className={className}
      showCharsCount={showCharsCount}
      $enablePreviewModal={enablePreviewModal}>
      <TextareaAutosize {...inputParams} />
      {enablePreviewModal && (
        <CircleIcon
          tooltip={t(isDisabled ? 'view' : 'edit')}
          icon={isDisabled ? PreviewIcon : EditIcon}
          onClick={() => setShowPreview(true)}
        />
      )}

      {!!error && <ErrorText error={error} />}

      {showPreview && (
        <TextPreviewModal onClose={() => setShowPreview(false)} textAreaRef={textAreaRef} inputParams={inputParams} />
      )}
    </Label>
  );
};

export default TextArea;
