import React, { useContext, useState } from 'react';
import { useField } from 'react-final-form';
import { required } from '@ubeya/shared/utils/form';
import useEmployees from '@ubeya/shared/hooks/employees/useEmployees';
import styled from 'styled-components';
import privileges from '../../../privileges';
import Label from '../../Label';
import { SelectField } from '../../FormFields';
import ConfigContext from '../../../contexts/ConfigContext';
import PrivilegesContext from '../../../contexts/PrivilegesContext';
import { Flex, Loader, Toggle } from '../..';

const LoaderWrapper = styled(Flex)`
  align-items: flex-start;
`;

export const EditableByAdmins = () => {
  const { t } = useContext(ConfigContext);
  const myPrivileges = useContext(PrivilegesContext);

  const {
    input: { value: adminsIds, onChange: onAdminsChange }
  } = useField('metadata.adminsIds');

  const [editableByAdminOnly, setEditableByAdminOnly] = useState((adminsIds || []).length > 0);

  const hasEditPrivilege = myPrivileges[privileges.ACCOUNT_ADMIN] && myPrivileges[privileges.EDIT_PERMISSIONS];

  const { data, isLoading } = useEmployees({
    onlyAdmins: true,
    includeAdmin: true,
    enabled: hasEditPrivilege,
    pageSize: 500
  });

  if (!hasEditPrivilege || (!isLoading && !data?.length)) {
    return null;
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size="medium" />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Toggle
        text={t('limitEditsToSpecificAdmins')}
        className="full"
        value={editableByAdminOnly}
        onChange={(newValue) => {
          setEditableByAdminOnly(newValue);
          !newValue && onAdminsChange([]);
        }}
      />
      {editableByAdminOnly && (
        <Label title={t('admins')} className="full">
          <SelectField
            name="metadata.adminsIds"
            usePortal
            validate={required}
            options={(data || [])
              .filter(({ admin }) => !!admin?.adminId)
              .map(({ firstName, lastName, admin }) => ({
                label: `${firstName} ${lastName}`,
                value: admin?.adminId
              }))}
            isMulti
          />
        </Label>
      )}
    </>
  );
};
