import React, { useState, useMemo, useRef, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { ButtonHeader } from '../Header';
import { body } from '../Typography';
import { FlexMiddle } from '../Flex';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { ReactComponent as BaseArrow } from '../../assets/arrow.svg';
import ConfigContext from '../../contexts/ConfigContext';
import { responsiveShow, media } from '../../style';
import BaseDatePickerWithOptions from './DatePickerWithOptions';

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'date-range' }))`
  white-space: nowrap;
  ${body};
`;

const Button = styled(ButtonHeader)`
  margin-right: 5px;
  ${media.mobile`margin-right: 10px;`}
`;

const DatesButton = styled(Button).attrs(() => ({ className: 'date-range-dates-button' }))`
  min-width: 155px;
  text-align: center;
  ${({ $isString }) => $isString && 'justify-content: space-between'};
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.grey3} !important;
  }
`;

const TodayButton = styled(Button)`
  ${responsiveShow.tablet};
  text-align: center;
  min-width: 85px;
  ${({ theme, isActive }) => isActive && `border-color: ${theme.colors.primary}`};
  ${({ theme, isActive }) => isActive && `color: ${theme.colors.primary}`};
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.grey3} !important;
  }
  margin-right: 30px;
`;

const DatePickerWithOptions = styled(BaseDatePickerWithOptions)`
  position: absolute;
  top: 42px;
  ${({ openFromRight }) => (openFromRight ? 'right' : 'left')}: 0;
  z-index: 9;
`;

const Arrow = styled(BaseArrow).attrs(({ $left }) => ({ className: $left ? 'rotate-180' : 'rotate-0' }))``;

const MenuArrow = styled(BaseArrow)`
  transform: rotate(${({ $isOpen }) => ($isOpen ? -90 : 90)}deg);
  margin-left: 10px;
`;

const DateText = ({ date, dateFormat }) => {
  return <span>{moment(date).format(dateFormat)}</span>;
};

const DayText = ({ date }) => {
  return <span className="day">, {moment(date).format('ddd')}</span>;
};

const DateRange = ({
  value,
  onChange,
  hideToday,
  valueAsString,
  options,
  onCalculateClick,
  numberOfMonths,
  className,
  allOption = false,
  shortFormat = false,
  openFromRight = false,
  openWithOptions = true,
  allowInitialEmptyValue = false,
  isCustomOptionSingleDay = false,
  maximumSelectedDays
}) => {
  const { shortDateMonthFormat, shortDateFormat, t } = useContext(ConfigContext);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  const isSingleDay = value.key === 'today' || value.key === 'yesterday';
  const isToday = value.key === 'today' && moment(value?.start).startOf('day')?.isSame(moment().startOf('day'));

  const handleDayPrev = useCallback(
    () =>
      onChange({
        ...value,
        start: moment(value?.start).subtract(1, 'day'),
        end: moment(value?.end).subtract(1, 'day')
      }),
    [onChange, value]
  );

  const handleDayNext = useCallback(
    () =>
      onChange({
        ...value,
        start: moment(value?.start).add(1, 'day'),
        end: moment(value?.end).add(1, 'day')
      }),
    [onChange, value]
  );

  const handleToday = useCallback(
    () =>
      onChange({
        key: 'today',
        start: moment(),
        end: moment()
      }),
    [onChange]
  );

  const showValueAsString = useMemo(() => value && value.key && valueAsString && value.key !== 'custom', [
    value,
    valueAsString
  ]);

  const showInitialEmptyState = useMemo(() => (!value || !value.key) && allowInitialEmptyValue, [
    allowInitialEmptyValue,
    value
  ]);

  const dateFormat =
    shortFormat && (!value?.start || !value?.end || value.start.isSame(value.end, 'year'))
      ? shortDateMonthFormat
      : shortDateFormat;

  return (
    <Wrapper ref={ref} className={className}>
      {!hideToday && (
        <TodayButton isActive={isToday} onClick={handleToday}>
          <Trans>today</Trans>
        </TodayButton>
      )}

      {!hideToday && isSingleDay && (
        <Button onClick={handleDayPrev}>
          <Arrow $left />
        </Button>
      )}

      <DatesButton onClick={() => setIsOpen(true)} $isString={showValueAsString}>
        {showValueAsString ? (
          <Trans>{value.key}</Trans>
        ) : showInitialEmptyState ? (
          <span>{t('chooseTimeRange')} </span>
        ) : (
          <>
            <DateText date={value?.start} dateFormat={dateFormat} />
            {!shortFormat && <DayText date={value?.start} />}

            {!isSingleDay && (
              <>
                -<DateText date={value?.end} dateFormat={dateFormat} />
                {!shortFormat && <DayText date={value?.end} />}
              </>
            )}
          </>
        )}

        <MenuArrow $isOpen={isOpen} />

        {isOpen && (
          <DatePickerWithOptions
            value={value}
            numberOfMonths={numberOfMonths}
            onChange={onChange}
            maximumSelectedDays={maximumSelectedDays}
            onClose={() => setIsOpen(false)}
            openFromRight={openFromRight}
            options={options}
            allOption={allOption}
            isCustomOptionSingleDay={isCustomOptionSingleDay}
            onCalculateClick={onCalculateClick}
            openWithOptions={openWithOptions}
          />
        )}
      </DatesButton>

      {!hideToday && isSingleDay && (
        <Button onClick={handleDayNext}>
          <Arrow />
        </Button>
      )}
    </Wrapper>
  );
};

export default DateRange;
