import styled from 'styled-components';
import React from 'react';
import Collapsible from 'react-collapsible';
import { ReactComponent as BaseArrow } from '@ubeya/shared-web/assets/arrow.svg';
import { ReactComponent as SoonChip } from '@ubeya/shared-web/assets/soon-chip.svg';
import { ModuleIcons } from '@ubeya/shared-web/assets/SidebarIcons';
import { ReactComponent as BaseStarIcon } from '@ubeya/shared-web/assets/is-host-star.svg';
import { Flex } from '../Flex';

const NewModuleIcon = styled(ModuleIcons.CommonModuleIcons.NewModule)`
  position: absolute;
  top: -5px;
  right: 0px;
`;

const IconContainer = styled.div`
  position: relative;
`;

const MenuRowContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, isSelected, mainIcon }) =>
    mainIcon ? theme.colors.white : isSelected ? theme.colors.primaryLight : theme.colors.white};
  cursor: pointer;
  transition: all;
  padding: 8px 8px 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  margin: 0 24px;
`;

const Arrow = styled(BaseArrow)`
  cursor: pointer;
  transition: all;
  ${({ isOpen }) => (!isOpen ? `transform: rotate(90deg);` : `transform: rotate(-90deg);`)}
`;

const MenuRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  position: relative;
`;

const MenuText = styled.div`
  color: #252525;
  font-size: 16rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24rem; /* 150% */
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors.primary : theme.colors.black)};
`;

const Submenu = styled.div`
  padding-left: 16px;
  transition: all 0.3s ease-out;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};
  margin: 0 24px 4px;
  padding-bottom: 4px;
`;

const SubmenuRow = styled.div`
  text-decoration: none;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: flex-start;
  padding: 8px 8px 8px 16px;
  margin: 4px 0;
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all;
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors.primary : theme.colors.black)};
`;

const StarIcon = styled(BaseStarIcon)`
  position: absolute;
  top: -8px;
  right: 0;
`;

const Spacer = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey2};
  margin: 24px 0;
`;

const SubmenuText = styled.div`
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
`;

const strokeIcons = ['/employees', '/statements'];

export const NavigationMenuItem = ({
  menuItem,
  LinkRenderer,
  pathname,
  theme,
  t,
  onClick,
  isHost,
  onMenuItemPress,
  openMenuIndex,
  key,
  index
}) => {
  const path = menuItem.to.split('/:accountId')[1];
  const label = menuItem.mobileLabel || menuItem.label;
  const filteredSecondaryMenu = (menuItem?.secondaryMenu || []).filter(({ isHidden = false }) => !isHidden);

  const allSubpaths = filteredSecondaryMenu.map((submenuItem) => submenuItem.to.split('/:accountId')[1]);
  const isSelected = [path, ...allSubpaths].some((tempPath) =>
    (tempPath || '').includes((pathname || '').split('/')?.[2])
  );
  const hasSubmenu = filteredSecondaryMenu.length > 0;
  const isOpen = openMenuIndex === index;
  const MenuIcon = isSelected ? menuItem.active : menuItem.iconDark;

  return (
    <Collapsible
      accordionPosition={index}
      transitionTime={200}
      open={isOpen}
      trigger={
        <>
          <MenuRowContainer
            key={menuItem.label + menuItem.to + key}
            as={LinkRenderer}
            to={menuItem.to}
            isSelected={isSelected}
            mainIcon={menuItem.mainIcon}
            onClick={() => {
              onMenuItemPress(isOpen ? -1 : hasSubmenu ? index : -1);
              if (!hasSubmenu) {
                onClick();
              }
            }}>
            <MenuRow>
              <IconContainer>
                <MenuIcon width={40} mainIcon={menuItem.mainIcon} />
                {menuItem.isNew && <NewModuleIcon />}
                {isHost && menuItem?.disabled && <StarIcon />}
              </IconContainer>
              <MenuText $isSelected={isSelected}>{t(label || '')}</MenuText>
            </MenuRow>
            {hasSubmenu && <Arrow isOpen={isOpen} width={20} />}
          </MenuRowContainer>
          {menuItem?.hasBottomBorder && isHost && <Spacer />}
        </>
      }>
      <Submenu>
        {filteredSecondaryMenu.map((submenuItem) => {
          const SubmenuIcon = submenuItem.icon;
          const subpath = submenuItem.to.split('/:accountId')[1];
          return (
            <SubmenuRow
              as={submenuItem.disabled ? undefined : LinkRenderer}
              key={submenuItem.label + submenuItem.to}
              to={submenuItem.to}
              $isSelected={pathname.includes(subpath)}
              $disabled={submenuItem.disabled}
              onClick={
                submenuItem.disabled
                  ? (e) => {
                      e.preventDefault();
                    }
                  : () => {
                      if (onClick) {
                        onClick();
                      }
                      if (submenuItem?.onClick) {
                        submenuItem.onClick();
                      }
                    }
              }>
              <SubmenuIcon
                width={40}
                opacity={submenuItem.disabled ? 0.5 : 1}
                fill={
                  strokeIcons.includes(subpath)
                    ? 'none'
                    : pathname.includes(subpath)
                    ? theme.colors.primary
                    : theme.colors.grey4
                }
                stroke={
                  strokeIcons.includes(subpath)
                    ? pathname.includes(subpath)
                      ? theme.colors.primary
                      : theme.colors.grey4
                    : 'none'
                }
              />
              <SubmenuText $disabled={submenuItem.disabled}>{t(submenuItem.label || '')}</SubmenuText>
              {submenuItem.disabled && <SoonChip />}
            </SubmenuRow>
          );
        })}
      </Submenu>
    </Collapsible>
  );
};
