import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { body } from '../Typography';
import { FlexMiddle } from '../Flex';
import Label from '../Label';
import { baseInputStyle } from '../Input/InputStyle';
import ErrorText from '../Input/ErrorText';

const Container = styled(FlexMiddle)`
  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.redNegative};
    `};
`;

const common = css`
  ${body};
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  outline: 0;
  padding: 10px;
  background: ${({ theme }) => theme.colors.white};
  min-height: 48px;
`;

const Input = styled.input`
  ${baseInputStyle};

  ${common};
  width: 100%;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Button = styled.button.attrs(() => ({ className: 'input-button', type: 'button' }))`
  ${common};
  cursor: pointer;
  border-radius: ${({ isLeft }) => (isLeft ? `5px 0 0 5px` : `0 5px 5px 0`)};
  border-width: ${({ isLeft }) => (isLeft ? `1px 0 1px 1px` : `1px 1px 1px 0`)};
`;

const NumberInput = ({
  title,
  value,
  onChange,
  className,
  required,
  inputPrefix,
  error,
  min = -Infinity,
  max = Infinity,
  step = undefined,
  disableTextInput = false,
  disabled = false,
  showButtons = true,
  incrementBy = 1,
  decrementBy = 1
}) => {
  const handleChange = useCallback(
    (changedValue) => {
      const numberValue = Number(changedValue);
      if (numberValue >= min && numberValue <= max) {
        onChange(numberValue);
      }
    },
    [max, min, onChange]
  );

  return (
    <Label title={title} required={required} className={className}>
      <Container error={error}>
        {!disabled && showButtons && (
          <Button isLeft type="button" onClick={() => handleChange(parseInt(value, 10) - decrementBy)}>
            -
          </Button>
        )}
        {inputPrefix}
        <Input
          error={error ? 1 : 0}
          onWheel={(e) => e.target.blur()}
          type="number"
          value={value}
          onChange={disableTextInput ? undefined : (e) => handleChange(e.target.value)}
          min={min}
          step={step}
          disabled={disabled}
        />
        {!disabled && showButtons && (
          <Button type="button" onClick={() => handleChange(parseInt(value, 10) + incrementBy)}>
            +
          </Button>
        )}
      </Container>
      {!!error && <ErrorText error={error} />}
    </Label>
  );
};

export default NumberInput;
