import styled from 'styled-components';
import { ReactComponent as BaseDeclineIcon } from '../../assets/decline.svg';

export const DeclineIcon = styled(BaseDeclineIcon)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  g {
    stroke: ${({ $color, theme }) => $color || theme.colors.redNegative};
    stroke-width: 1;
  }
`;

export const ActiveDeclineIcon = styled(BaseDeclineIcon)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  g {
    stroke-width: 1;

    fill: ${({ $color, theme }) => $color || theme.colors.redNegative};
    stroke: white;
  }
`;
