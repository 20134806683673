import React, { useContext, useRef, useState } from 'react';
import styled, { keyframes, useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import useAccount from '@ubeya/shared/hooks/account/useAccount';
import { FlexColumn, FlexSpace } from '../Flex';
import { card } from '../Card';
import { ReactComponent as NavigationIcon } from '../../assets/navigation.svg';
import { media, responsiveHide } from '../../style';
import ConfigContext from '../../contexts/ConfigContext';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { NavigationMenuItem } from './NavigationMenuItem';

const TopBar = styled(FlexSpace)`
  ${media.tablet`display: none`}
  background: ${({ theme }) => theme.colors.sideBarBackground};
  padding: 0 18px;
  height: 60px;
`;

const MenuIcon = styled(NavigationIcon)`
  ${responsiveHide.tablet}
  cursor:pointer;
`;

const enter = keyframes`
	0%{transform: translateX(-100vw)}
	100%{transform: translateX(0px)}
`;

const Menu = styled(FlexColumn)`
  ${card};
  ${responsiveHide.tablet}
  animation: ${enter} ease-out 200ms;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
  overflow-y: scroll;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  row-gap: 8px;
  width: 95vw;
  max-width: 300px;
  z-index: 5;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 24px 0;

  > .Collapsible {
    width: 100%;
  }
`;

const NavigationMenu = ({ linkRenderer: LinkRenderer, links, children }) => {
  const { t } = useContext(ConfigContext);
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));
  const { pathname } = useLocation();
  const theme = useTheme();
  const {
    account: { isHost }
  } = useAccount();

  const formattedLinks = links
    .filter(({ isVisible = true }) => isVisible)
    .map((item, linkIndex) => {
      return (
        <NavigationMenuItem
          key={item.to + linkIndex}
          LinkRenderer={LinkRenderer}
          index={linkIndex}
          menuItem={item}
          isHost={isHost}
          pathname={pathname}
          openMenuIndex={openMenuIndex}
          t={t}
          theme={theme}
          onMenuItemPress={(value) => setOpenMenuIndex(value | linkIndex)}
          onClick={() => setIsOpen(false)}
        />
      );
    });

  return (
    <TopBar>
      <MenuIcon onClick={() => setIsOpen(true)} />
      {children}

      {isOpen && <Menu ref={ref}>{formattedLinks}</Menu>}
    </TopBar>
  );
};

export default NavigationMenu;
