import React, { useContext, useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { useField } from 'react-final-form';
import useAccount from '@ubeya/shared/hooks/account/useAccount';
import useSequencePayload from '@ubeya/shared/hooks/account/useSequencePayload';
import useFields from '@ubeya/shared/hooks/account/useFields';
import { ReactComponent as BaseInfoIcon } from '@ubeya/shared-web/assets/help.svg';
import { ReactComponent as BasePayrollIcon } from '@ubeya/shared-web/assets/payroll-dollar.svg';
import { SmallBody, smallBodySemiBold } from '../Typography';
import useFormFields from '../../hooks/useFormFields';
import { FlexColumn, flexMiddle } from '../Flex';
import { ReactComponent as ProfileFieldIcon } from '../../assets/profile-field.svg';
import { ExplanationTooltip, Tooltip as BaseTooltip } from '..';
import ConfigContext from '../../contexts/ConfigContext';
import ProfileInputModal from './ProfileInputModal';
import PayrollInputModal from './PayrollInputModal';

const Wrapper = styled(FlexColumn)`
  padding: 10px 0;
  overflow: auto;

  min-width: 165px;
`;

const Tooltip = styled(BaseTooltip)`
  .rc-tooltip-inner {
    max-width: 200px;
    text-align: center;
  }
`;

const InfoIcon = styled(BaseInfoIcon)`
  width: 15px;
  height: 15px;
  margin: 0 0 2px 5px;
`;

const PayrollIcon = styled(BasePayrollIcon)`
  width: 15px;
  height: 15px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  padding-bottom: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  margin: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${smallBodySemiBold}
  text-align: center;
`;

const FieldType = styled(SmallBody)`
  ${flexMiddle};
  color: ${({ theme }) => theme.colors.grey4};
  cursor: pointer;
  min-height: 40px;
  padding: 5px 20px;

  &:hover {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const StyledIcon = styled.div.attrs(() => ({ dir: 'rtl' }))`
  min-width: 20px;
  margin-right: 16px;
`;

const FieldsTypes = ({
  kind,
  includeUnsupportedInputs = false,
  includeContent = false,
  includeProfileFields = false,
  includePayrollFields = false,
  includeShowNotSupportedFields = false,
  includeOnlyAdminSupported = false,
  className,
  onClick,
  fieldsName = 'fields',
  allowedFieldTypes
}) => {
  const {
    slug: { myContent }
  } = useSequencePayload({ kind });
  const { t } = useContext(ConfigContext);
  const { accountId } = useAccount();
  const { mappedFields } = useFields();
  const { fieldTypesOptions } = useFormFields(
    includeUnsupportedInputs,
    includeContent,
    includeShowNotSupportedFields,
    includeOnlyAdminSupported
  );

  const contentFields = useMemo(
    () =>
      fieldTypesOptions.filter(({ content, value }) => {
        if (typeof allowedFieldTypes !== 'undefined') {
          if (!allowedFieldTypes.includes(value)) {
            return false;
          }
        }
        return content;
      }),
    [allowedFieldTypes, fieldTypesOptions]
  );
  const inputFields = useMemo(
    () =>
      fieldTypesOptions.filter(({ content, value }) => {
        if (typeof allowedFieldTypes !== 'undefined') {
          if (!allowedFieldTypes.includes(value)) {
            return false;
          }
        }
        return !content;
      }),
    [allowedFieldTypes, fieldTypesOptions]
  );

  const [showProfileInput, setShowProfileInput] = useState(false);
  const [showPayrollInput, setShowPayrollInput] = useState(false);

  const {
    input: { value: fields, onChange }
  } = useField(fieldsName);

  const handleProfileConfirm = useCallback(
    (newFields) => {
      // Get IDs of newly selected fields
      const existingIds = new Set(fields.map((field) => field.id));

      const newFieldIds = new Set(newFields.map((field) => field.id));

      // Keep only fields that are either not profile fields (id <= 0 - when editing, or no mappedFields record) or are in the new selection
      const filteredFields = fields.filter(
        (field) => field.id <= 0 || !mappedFields?.[field.id] || newFieldIds.has(field.id)
      );

      // Filter out any new fields that already exist
      const uniqueNewFields = newFields.filter((field) => !existingIds.has(field.id));

      onChange([...filteredFields, ...uniqueNewFields]);
    },
    [fields, mappedFields, onChange]
  );

  return (
    <Wrapper className={className}>
      {contentFields.length > 0 && (
        <Title>
          <ExplanationTooltip title={t(myContent)} body={t('tooltipAddworkflowcontent')} />
        </Title>
      )}

      {contentFields.map(({ value, label, icon: Icon }) => (
        <FieldType key={value} onClick={() => onClick(value)}>
          {Icon && <StyledIcon as={Icon} />}
          {label}
        </FieldType>
      ))}

      {inputFields.length > 0 && (
        <Title>
          <ExplanationTooltip title={t('inputField')} body={t('tooltipInputfield')} />
        </Title>
      )}

      {includeProfileFields && (
        <FieldType onClick={() => setShowProfileInput(true)}>
          <StyledIcon as={ProfileFieldIcon} />
          {t('profileField')}
          <Tooltip body={t('profileFieldTooltip')}>
            <InfoIcon />
          </Tooltip>
        </FieldType>
      )}

      {includePayrollFields && (
        <FieldType onClick={() => setShowPayrollInput(true)}>
          <StyledIcon as={PayrollIcon} />
          {t('payrollField')}
          <Tooltip body={t('payrollFieldTooltip')}>
            <InfoIcon />
          </Tooltip>
        </FieldType>
      )}

      {inputFields.map(({ value, label, icon: Icon }) => (
        <FieldType key={value} onClick={() => onClick(value)}>
          {Icon && <StyledIcon as={Icon} />}
          {label}
        </FieldType>
      ))}

      {showProfileInput && (
        <ProfileInputModal
          accountId={accountId}
          onClose={() => setShowProfileInput(false)}
          onConfirm={handleProfileConfirm}
          initialValues={(fields || [])
            .filter(({ id }) => id > 0 && !!mappedFields?.[id])
            .reduce((acc, { id }) => ({ ...acc, [`profile-field-${id}`]: true }), {})}
        />
      )}

      {showPayrollInput && (
        <PayrollInputModal
          accountId={accountId}
          onClose={() => setShowPayrollInput(false)}
          onConfirm={(newFields) => onChange([...fields, ...newFields])}
        />
      )}
    </Wrapper>
  );
};

export default FieldsTypes;
