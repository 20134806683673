import { createGlobalStyle, css } from 'styled-components';
import { body, smallBody } from '../components/Typography';
import media from './media';
import tooltipStyle from './tooltipStyle';
import scrollbar from './scrollbar';

const fontSize = css`
  font-family: Roboto, Rubik, sans-serif;
`;

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 1px;
    ${media.mobile`--vh: 1vh;`}
    direction: ltr;
    ${({ browserName }) =>
      browserName === 'Firefox' &&
      css`
        overflow: hidden;
      `}
  }

  body {
    margin: 0;
    ${fontSize};
    background:${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    font-size: 14rem;
  }

  input, textarea, button{
    color: ${({ theme }) => theme.colors.black};
    ${fontSize};
  }

  h1, h2, h3, h4, h5, h6, p{
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.link};
  }

  *,*:after,*:before{
    box-sizing: border-box;
  }

  svg {
    box-sizing: content-box;
  }

  .rc-tooltip{
    border-radius: 8px;
  }
  .rc-tooltip-inner{
    min-height: auto;
    border: none;
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px;
    border-radius: 8px;
  }

  ${tooltipStyle};

  .select__value-container{
    ${body};
  }

  .select__menu {
    margin-top: 0px;

    .select__option{
      ${smallBody};
      padding: 10px;
      border-radius: 5px;
      color: ${({ theme }) => theme.colors.black};
      background: ${({ theme }) => theme.colors.white};
      cursor: pointer;
    }

    .select__option:hover,.select__option--is-focused, .select__option--is-selected{
      background: ${({ theme }) => theme.colors.white}!important;
    }
  }

  .rotate-0{
    transform: rotate(${({ isRtl }) => (isRtl ? 180 : 0)}deg);
  }

  .rotate-180{
    transform: rotate(${({ isRtl }) => (isRtl ? 0 : 180)}deg);
  }

  .mirror{
    ${({ isRtl }) => (isRtl ? 'transform: scale(-1, 1)' : '')};
  }

  .soon {
    position: relative;
    &:after{
      content: 'SOON';
      position: absolute;
      top: 30px;
      left: 30px;
      right: 30px;
      bottom: 30px;
      display:flex;
      align-items:center;
      justify-content: center;
      color: red;
      transform: skew(10deg, -10deg);
    }
  }

  
  [dir="rtl"]{
    direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
  }

  .dragging-row {
    z-index: 999999;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black}!important;
    left: 0!important;
  }
  /* Scrollbar styling only for Windows (non-overlay scrollbars) */

  ${navigator.userAgent.includes('Windows') ? scrollbar : ''}

`;

export default GlobalStyle;
