import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import branchManagerBadge from '@ubeya/shared-web/assets/branch-manager-badge.svg';
import accountManagerBadge from '@ubeya/shared-web/assets/account-manager-badge.svg';
import { ReactComponent as ErrorIcon } from '@ubeya/shared-web/assets/error.svg';
import { flexCenter } from '../Flex';
import { Tooltip } from '../Tooltip';
import ConfigContext from '../../contexts/ConfigContext';

const SIZES = {
  xs: { image: 20, font: 10 },
  small: { image: 24, font: 12 },
  medium: { image: 28, font: 13 },
  medium2: { image: 34, font: 13 },
  big: { image: 50, font: 13 },
  large: { image: 150, font: 52 }
};

const badgeStyle = css`
  content: '';
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: -4px;
  bottom: -2px;
`;

const Image = styled.div.attrs(({ image }) => ({ className: 'avatar', style: { backgroundImage: `url(${image})` } }))`
  position: relative;
  ${flexCenter};
  border-radius: 100%;
  width: ${({ size }) => SIZES[size]?.image || size}px;
  height: ${({ size }) => SIZES[size]?.image || size}px;
  min-width: ${({ size }) => SIZES[size]?.image || size}px;
  min-height: ${({ size }) => SIZES[size]?.image || size}px;
  background: ${({ theme }) => theme.colors.generalBlue};
  color: ${({ theme }) => theme.colors.white};
  border: ${({ image }) => (image ? 0 : 2)} solid ${({ theme }) => theme.colors.white};
  text-align: center;
  font-weight: 300;
  font-size: ${({ size }) => SIZES[size]?.font}rem;
  ${({ onClick }) => onClick && 'cursor:pointer'};
  > svg {
    width: ${({ size }) => (SIZES[size]?.image || size) / 1.5}px;
    height: ${({ size }) => (SIZES[size]?.image || size) / 1.5}px;
  }
  background-size: cover;
  background-position: center;

  ${({ $showBranchAdmin, $showAccountAdmin }) =>
    ($showAccountAdmin || $showBranchAdmin) &&
    css`
      &:after {
        background: url('${$showBranchAdmin ? branchManagerBadge : accountManagerBadge}');
        ${badgeStyle}
      }
    `}
`;

const PendingWarning = styled.div`
  position: absolute;
  right: -2px;
  top: -2px;
  border-radius: 50%;
  padding: 0;
`;

const Wrapper = styled.div``;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const WarningItem = styled.div`
  display: flex;
  margin-left: 10px;
  max-width: 250px;
`;

const CircleChip = styled(ErrorIcon)`
  width: 12px;
  height: 12px;
  margin: 2px;
`;

const Avatar = ({
  firstName,
  lastName,
  isPending,
  warning,
  image,
  onClick,
  size = 'medium2',
  tooltip = false,
  tooltipBody,
  className,
  employeeCompliance,
  showStar = false,
  showBranchAdmin = false,
  showOnAvatarData
}) => {
  const [isInnerTooltipOpen, setInnerTooltipOpen] = useState(false);
  const name = tooltipBody || [firstName, lastName].join(' ');
  const { t } = useContext(ConfigContext);
  const innerData = image ? (
    <></>
  ) : firstName || lastName ? (
    [firstName?.charAt?.(0), lastName?.charAt?.(0)].join('')
  ) : (
    'N / A'
  );

  const { isEmployeeCompliant = true, compliantExpDate } = employeeCompliance || {};

  const warnings = [
    { visible: isPending, body: t('pendingApproval') },
    { visible: !!warning, body: t(warning) },
    {
      visible: !isEmployeeCompliant,
      body: `${t('notCompliant')} ${compliantExpDate ? `${t('compliantSince')} ${compliantExpDate}` : ''}`
    }
  ];

  const visibleWarnings = warnings.filter((warningProp) => !!warningProp.visible);

  return (
    <Tooltip
      body={name}
      disabled={!tooltip}
      overlayStyle={visibleWarnings.length > 0 && isInnerTooltipOpen && { display: 'none' }}>
      <Image
        image={image}
        className={className}
        size={size}
        $showAccountAdmin={showStar}
        $showBranchAdmin={showBranchAdmin}
        onClick={onClick}
        $showWarning={isPending}>
        {innerData}
        {visibleWarnings.length > 0 && (
          <PendingWarning
            onMouseEnter={() => setInnerTooltipOpen(true)}
            onMouseLeave={() => {
              setInnerTooltipOpen(false);
            }}>
            <Tooltip
              body={
                <Wrapper>
                  <Title>
                    {visibleWarnings.length === 1
                      ? t('thereIsAnIssue')
                      : t('thereAreIssues', { warningsNumber: visibleWarnings.length })}
                    :
                  </Title>
                  {visibleWarnings.map((warningProp, index) => (
                    <WarningItem key={index}>
                      {index + 1}. {warningProp.body}
                    </WarningItem>
                  ))}
                </Wrapper>
              }>
              <CircleChip />
            </Tooltip>
          </PendingWarning>
        )}
        {showOnAvatarData}
      </Image>
    </Tooltip>
  );
};

export default Avatar;
