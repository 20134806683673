import styled, { css } from 'styled-components';
import { responsiveShowFlex } from '@ubeya/shared-web/style';

export const ActionColumn = styled.div`
  position: sticky;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: inherit;
  padding: 17px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};
  grid-column: -1;
  ${responsiveShowFlex.tablet}
`;

export const RowWrapper = styled.div.attrs(({ row }) => ({
  id: `${row.isGroup ? 'group' : 'sub-row'}`,
  className: `row ${row.isGroup ? 'group' : 'sub-row'}`
}))`
  contain: strict;
  contain: size layout style paint;
  display: grid;
  grid-template-rows: var(--row-height);
  grid-template-columns: var(--template-columns);
  position: absolute;
  left: 0;
  width: var(--row-width);
  height: var(--row-height);
  background-color: var(--background-color);
  min-width: 100%;
  top: ${({ $top }) => $top}px!important;

  background-color: ${({ theme, row }) => {
    return row.isGroup && (row?.subRows || []).length > 0 ? theme.colors.grey1 : theme.colors.white;
  }} !important;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hoverBackground} !important;

    > ${ActionColumn} {
      opacity: 1;
    }
  }

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: var(--header-row-height);
      z-index: 1;
    `}
`;

export const PlaceholderRow = styled.div`
  position: absolute;
  contain: size layout style paint;
  display: grid;
  grid-template-rows: var(--row-height);
  grid-template-columns: var(--template-columns);
`;
