import React from 'react';
import styled from 'styled-components';
import { Flex } from '../Flex';
import { Small } from '../Typography';
import OutsourceLogo from './OutsourceLogo';

const Title = styled(Small)`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 10px;
`;

const Wrapper = styled(Flex)`
  height: 25px;
  border-radius: 13px;
  margin: 3px 18px 1px 0;
  align-items: center;
`;

const OutsourceBadge = ({ orderDetails, className, withName = false }) => {
  const { name, logo } = orderDetails || {};

  return (
    <Wrapper className={className}>
      {withName && <Title>{name}</Title>}
      <OutsourceLogo logo={logo} name={name} withName={withName} />
    </Wrapper>
  );
};

export default OutsourceBadge;
