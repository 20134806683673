import React, { useMemo, useCallback, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { ALLOWED_FILE_EXTENSIONS } from '@ubeya/shared/constants';
import * as api from '@ubeya/shared/services/api';
import {
  ImagesCarousel,
  Flex,
  FileUpload as BaseFileUpload,
  flexCenter,
  ContextMenu as BaseContextMenu,
  PdfModal
} from '@ubeya/shared-web/components';
import { ReactComponent as Plus } from '@ubeya/shared-web/assets/plus.svg';
import { ReactComponent as ArrowIcon } from '@ubeya/shared-web/assets/arrow.svg';
import ConfigContext from '../../../contexts/ConfigContext';

const Container = styled(Flex).attrs(() => ({ className: 'attachment-container' }))`
  flex-wrap: wrap;
`;

const attachment = css`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-top: 10px;
  &:not(:nth-child(4n)) {
    margin-right: 10px;
  }
`;

const FileUpload = styled(BaseFileUpload)`
  ${attachment};
  ${flexCenter};
  color: ${({ theme }) => theme.colors.disabled};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23DCDCDCFF' stroke-width='4' stroke-dasharray='9%2c 10' stroke-dashoffset='40' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
`;

const ContextMenu = styled(BaseContextMenu)`
  position: absolute;
  top: 0;
  right: 0;
`;

const ComponentWrapper = styled.div`
  position: relative;

  .document-wrapper {
    margin: 10px 5px 0 0;
  }

  &:hover {
    .arrow {
      visibility: visible;
      background-color: ${({ theme }) => theme.colors.disabled};
      > path {
        fill: ${({ theme }) => theme.colors.white};
        stroke: none;
      }
    }
  }
`;

const Arrow = styled(ArrowIcon).attrs(() => ({ className: 'arrow' }))`
  border-radius: 50%;
  position: absolute;
  transform: rotate(90deg);
  right: 12px;
  top: 12px;
  width: 10px;
  height: 10px;
  visibility: hidden;
  padding: 3px;
`;

const GeneralAttachments = ({
  data = [],
  handleDeleteItems,
  handleAddItems,
  maxAttachments,
  singleFile = false,
  enableUpload = true,
  allowedAttachmentsExtensions = ALLOWED_FILE_EXTENSIONS
}) => {
  const [isOpenIndex, setIsOpenIndex] = useState();
  const [openPdf, setOpenPdf] = useState();
  const { t } = useContext(ConfigContext);

  const mediaAttachments = useMemo(() => data.map(({ src }) => ({ source: src })), [data]);

  const fileOptions = useCallback(
    (id, source, extension) => [
      ...(extension === 'pdf'
        ? [
            {
              label: t('view'),
              onClick: () => setOpenPdf(source)
            }
          ]
        : []),
      {
        label: t('download'),
        onClick: () => {
          window.open(source);
        }
      },
      {
        label: t('delete'),
        onClick: () => handleDeleteItems(id)
      }
    ],
    [handleDeleteItems, t]
  );

  const maxAttachmentsCanUpload = maxAttachments && maxAttachments - data.length;

  return (
    <>
      <Container>
        {data
          .filter(({ Component }) => Component !== undefined)
          .map(({ src, Component, name, title, extension, id }, index) => (
            <ComponentWrapper key={src}>
              <Component
                key={id || index}
                onClick={() => {
                  if (extension === 'pdf') {
                    return setOpenPdf(src);
                  }

                  return setIsOpenIndex(index);
                }}
                title={title}
                extension={extension}
                src={src}
                subTitle={name || extension}
                preload="false"
              />

              <ContextMenu
                options={fileOptions(id, src, extension)}
                menuOffset={{ top: 30, left: 20 }}
                maxHeight={500}
                usePortal>
                <Arrow />
              </ContextMenu>
            </ComponentWrapper>
          ))}

        {enableUpload && !singleFile && (
          <FileUpload
            apiFunc={api.uploadImage}
            onUploaded={handleAddItems}
            accept={allowedAttachmentsExtensions}
            maxAttachments={maxAttachmentsCanUpload}>
            <Plus />
          </FileUpload>
        )}
      </Container>

      {!!openPdf && <PdfModal url={openPdf} onClose={() => setOpenPdf(undefined)} />}
      <ImagesCarousel views={mediaAttachments} currentIndex={isOpenIndex} onClose={() => setIsOpenIndex()} />
    </>
  );
};

export default GeneralAttachments;
