import styled, { css } from 'styled-components';
import { body } from '../Typography';

export const baseInputStyle = css`
  ${body};

  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-radius: 2px;
  width: 100%;
  padding: 10px;
  min-height: 46px;
  font-size: 14px;
  outline: 0;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey3};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey1};
    color: ${({ theme }) => theme.colors.disabled};
    border: 1px solid ${({ theme }) => theme.colors.grey1};
  }

  &:hover:not(:focus, :disabled) {
    border: 1px solid ${({ theme }) => theme.colors.grey3};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.redNegative};
    `};
`;

export const baseErrorTextStyle = css`
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.redNegative};
  font-weight: normal;
  font-size: 13px;
  font-weight: 300;
`;

export const BaseInputStyle = styled.input`
  ${baseInputStyle};
`;

export const BaseErrorTextStyle = styled.div`
  ${baseErrorTextStyle};
`;
