import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { useField } from 'react-final-form';
import BaseRichTextEditor from 'react-rte';
import ConfigContext from '../../../contexts/ConfigContext';
import { SmallBody } from '../../Typography';
import { EditableByAdmins } from './EditableByAdmins';

const Wrapper = styled.div.attrs(() => ({ className: 'full' }))``;

const PlaceholderText = styled(SmallBody)`
  color: ${({ theme }) => theme.colors.grey4};
  font-size: 14px;
`;

const RichTextEditor = styled(BaseRichTextEditor)`
  font-family: inherit;

  span[class^='ImageSpan_'] {
    letter-spacing: 0 !important;
    background-size: contain !important;
    width: 100% !important;
    height: auto !important;
    font-size: medium !important;
    line-height: 200px !important;
  }

  div[class^='RichTextEditor__editor'] {
    height: 280px;
  }

  div.public-DraftEditor-content {
    overflow: auto;
  }
`;

const Label = styled(SmallBody).attrs(() => ({ className: 'description-field-label' }))`
  color: #858585;
  font-weight: normal;
  font-size: 14rem;
  line-height: 25rem;
  margin-bottom: 2px;
`;

const MaxLengthLabel = styled(Label)`
  font-size: 12rem;
  color: ${({ $hasExceededLimit }) => ($hasExceededLimit ? 'lightcoral' : '#858585')};
`;

const toolbarConfig = {
  display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
  INLINE_STYLE_BUTTONS: [{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' }],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
    { label: 'Code Block', style: 'code-block' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Blockquote', style: 'blockquote' }
  ]
};

const DescriptionFieldForm = ({
  placeholder,
  name = 'metadata.text',
  autoFocus = true,
  className = '',
  label = '',
  maxLength = undefined,
  showLimitEditAdminsToggle = false
}) => {
  const {
    input: { value = '', onChange }
  } = useField(name);

  const { isRtl, t } = useContext(ConfigContext);

  const [state, setState] = useState(
    RichTextEditor.createValueFromString(value, 'markdown') || RichTextEditor.createEmptyValue()
  );

  const handleSetChange = useCallback(
    (changedValue) => {
      // the default text starts with extra char for spaces
      const newInputValue = changedValue.toString('markdown').replace(/\u200B/g, '');
      const formValue = newInputValue.trim();

      if (!newInputValue.length) {
        setState(RichTextEditor.createValueFromString('', 'markdown'));
        onChange('');
        return;
      }

      setState(changedValue);
      onChange(formValue);
    },
    [onChange]
  );

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}

      <RichTextEditor
        placeholder={<PlaceholderText isRtl={isRtl}>{placeholder}</PlaceholderText>}
        value={state}
        onChange={handleSetChange}
        autoFocus={autoFocus}
        toolbarConfig={toolbarConfig}
        editorClassName={className}
      />

      {maxLength && (
        <MaxLengthLabel $hasExceededLimit={value.length > maxLength}>{`${value.length}/${maxLength} ${t(
          'characters'
        )}`}</MaxLengthLabel>
      )}

      {showLimitEditAdminsToggle && <EditableByAdmins />}
    </Wrapper>
  );
};
export default DescriptionFieldForm;
