import styled from 'styled-components';
import React, { useContext } from 'react';
import { SmallBody } from '@ubeya/shared-web/components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

const Badge = styled(SmallBody)`
  background: ${({ theme }) => theme.colors.greenPositive};
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px;
  line-height: initial;
  min-width: 70px;
  padding: 4px;
  text-align: center;
  border-radius: 30px;
`;

const BookedNeededBadge = ({ booked, needed, className, showBookedText = true }) => {
  const { t, theme } = useContext(ConfigContext);

  let stateColor = theme.colors.yellowPartially;
  if (!booked) {
    stateColor = theme.colors.redNegative;
  }
  if (booked >= needed) {
    stateColor = theme.colors.greenPositive;
  }

  return (
    <Badge $stateColor={stateColor} className={className}>
      {booked}/{needed} {showBookedText && t('booked')}
    </Badge>
  );
};

export default BookedNeededBadge;
