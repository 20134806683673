import styled from 'styled-components';
import { header3SemiBold } from '../Typography';

const MainLabel = styled.div`
  ${header3SemiBold};
  width: 100%;
  font-size: 18px;
  background: ${({ theme }) => theme.colors.grey1};
  color: ${({ theme }) => theme.colors.black};
  margin: 20px 0 22px;
  &:first-child {
    margin: 0 0 22px;
  }
  line-height: 1.78;
  height: 40px;
  padding: 3px 36px 5px 16px;
`;

export default MainLabel;
