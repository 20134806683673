import React from 'react';
import styled from 'styled-components';
import { Flex } from '../Flex';
import Label from '../Label';
import { ExtraSmall } from '../Typography';

const Hint = styled(ExtraSmall)`
  color: ${({ theme }) => theme.colors.grey3};
`;

const ComposeFields = ({ title, children, hint, className }) => (
  <div className={className}>
    <Label title={title}>
      <Flex>{children}</Flex>
    </Label>

    {hint && <Hint>{hint}</Hint>}
  </div>
);

export default ComposeFields;
