import React from 'react';
import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ExternalLink } from '@ubeya/shared-web/assets/external-link.svg';

import { Small, SmallBody } from '../Typography';
import { FlexCenter, FlexColumn, FlexSpace } from '../Flex';
import { Tooltip } from '../Tooltip';

const Wrapper = styled(FlexColumn).attrs(() => ({ className: 'label-wrapper' }))`
  position: relative;
`;

const Title = styled(SmallBody).attrs(() => ({ className: 'label' }))`
  color: ${({ theme }) => theme.colors.grey4};
  white-space: initial;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: '*';
        margin-left: 2px;
      }
    `}
  ${({ showCharsCount }) =>
    showCharsCount &&
    css`
      width: 100%;
    `}
`;

const Link = styled(NavLink).attrs(() => ({ className: 'label-main-link' }))`
  text-align: center;
  text-decoration: none;
  align-self: flex-end;
  margin-top: 5px;
`;

const ErrorText = styled(Small).attrs(() => ({ className: 'label-error-text' }))`
  color: ${({ theme }) => theme.colors.redNegative};
`;

const LinkLabel = styled.a.attrs(() => ({ className: 'label-links-text' }))`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.link};
  text-decoration: none;
`;

const LinkIcon = styled(ExternalLink).attrs(() => ({ className: 'label-icon' }))`
  margin-right: 4px;
  width: 16px;
  height: 16px;
`;

const LabelContainer = styled(FlexCenter).attrs(() => ({ className: 'label-container' }))`
  column-gap: 10px;
  margin-bottom: 3px;
`;

const Label = ({ title, children, className, required, error, linkTo, linkText, showCharsCount, links, showLinks }) => (
  <Wrapper className={className}>
    {(title || showLinks || error) && (
      <FlexSpace>
        {(title || showLinks) && (
          <LabelContainer>
            {title && (
              <Title required={required} showCharsCount={showCharsCount}>
                {title}
              </Title>
            )}
            {showLinks &&
              (links || []).map((link, i) => (
                <Tooltip key={link + i} body={link}>
                  <LinkLabel href={link} target="_blank">
                    <LinkIcon />
                  </LinkLabel>
                </Tooltip>
              ))}
          </LabelContainer>
        )}
        {error && (
          <ErrorText>
            <Trans>{error}</Trans>
          </ErrorText>
        )}
      </FlexSpace>
    )}
    {children}
    {linkTo && <Link to={linkTo}>{linkText}</Link>}
  </Wrapper>
);

export default Label;
