import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import createCachedSelector from 're-reselect';
import { selectedBranch } from '@ubeya/shared/atoms/shared';
import * as api from '../../services/api';
import useCRUD from '../useCRUD';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data) => {
    const costCenters = data || [];
    const mappedBranchCostCenters = costCenters.reduce((acc, item) => {
      const branchId = item.branchId;
      acc[branchId] = (acc[branchId] || []).concat(item);
      return acc;
    }, {});

    const mappedCostCenters = costCenters.reduce((acc, item) => {
      const costCenterId = item.id;
      acc[costCenterId] = item;
      return acc;
    }, {});

    const branchCostCentersOptions = costCenters.map(({ id, name, identifier }) => ({
      value: id,
      label: `${name} (${identifier})`
    }));
    return {
      costCenters: data || [], // all the account's cost centers
      mappedCostCenters, // cost centers by their cost center id as a key
      branchCostCentersOptions, // cost centers options
      mappedBranchCostCenters // cost centers groupped by their branch id as a key
    };
  }
)({
  keySelector: (data, storeKey) => `${storeKey.join('#')}`
});

const useBranchCostCenters = (requestedBranchId = undefined) => {
  const { accountId } = useAccount();
  const defaultBranchId = useRecoilValue(selectedBranch);
  const branchId = requestedBranchId ? parseInt(requestedBranchId, 10) : defaultBranchId;

  const storeKey = ['branch-cost-centers', accountId, branchId];
  const { isLoading, data } = useQuery(storeKey, () => api.fetchBranchCostCenters({ accountId, branchId }), {
    staleTime: 0,
    enabled: !!branchId,
    select: (branchCostCenters) => selector(branchCostCenters, storeKey)
  });

  const { costCenters, branchCostCentersOptions, mappedBranchCostCenters, mappedCostCenters } = useMemo(
    () => ({
      costCenters: data?.costCenters || [],
      branchCostCentersOptions: data?.branchCostCentersOptions || [],
      mappedBranchCostCenters: data?.mappedBranchCostCenters || {},
      mappedCostCenters: data?.mappedCostCenters || {}
    }),
    [data]
  );

  const { addItem, editItem, deleteItem } = useCRUD(
    storeKey,
    {
      addApi: ({ name, identifier }) => api.addBranchCostCenter({ accountId, branchId, name, identifier }),
      editApi: ({ id: costCenterId, name, identifier }) =>
        api.updateBranchCostCenter({ accountId, branchId, costCenterId, name, identifier }),
      deleteApi: ({ costCenterId }) => api.deleteBranchCostCenter({ accountId, branchId, costCenterId })
    },
    { refetchOnSuccessDelete: true }
  );

  return {
    isLoading,
    costCenters,
    branchCostCentersOptions,
    mappedBranchCostCenters,
    mappedCostCenters,
    addCostCenter: addItem,
    updateCostCenter: editItem,
    deleteCostCenter: deleteItem
  };
};

export default useBranchCostCenters;
