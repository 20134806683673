import { css } from 'styled-components';

export default ['top', 'right', 'bottom', 'left'].reduce(
  (prev, direction) =>
    css`
      ${prev} ${css`
    .rc-tooltip-placement-${direction} .rc-tooltip-arrow {
      border-${direction}-color: ${({ theme }) => theme.colors.black}; !important;
    }
  `}
    `,
  css``
);
