import { createCachedSelector } from 're-reselect';
import { useQuery } from 'react-query';
import * as api from '../../services/api';
import useCRUD from '../useCRUD';
import { QUERY_CACHE_TIME, TAG_COLORS } from '../../constants';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data) => {
    const colorsOptions = TAG_COLORS.map(({ id, color }) => ({ value: id, label: color }));

    const mappedColors = colorsOptions.reduce((prev, { value, label }) => ({ ...prev, [value]: label }), {});

    const tagsOptions = (data || []).map(({ id, title, color }) => ({
      value: id,
      label: title,
      color: mappedColors[color]
    }));

    const mappedTags = tagsOptions.reduce((prev, tag) => ({ ...prev, [tag.value]: tag }), {});

    return { colorsOptions, mappedColors, tagsOptions, mappedTags, data };
  }
)({
  keySelector: (data, storeKey) => storeKey.join('#')
});

const useTags = () => {
  const { accountId } = useAccount();
  const storeKey = ['tags', accountId];
  const { isLoading, data } = useQuery(storeKey, () => api.fetchTags({ accountId }), {
    enabled: !!accountId,
    select: (tagData) => selector(tagData, storeKey),
    cacheTime: QUERY_CACHE_TIME
  });

  const { colorsOptions = [], mappedColors = {}, tagsOptions = [], mappedTags = {} } = data || {};

  const { addItem, editItem, deleteItem } = useCRUD(storeKey, {
    addApi: (tag) => api.addTag({ accountId, tag }),
    editApi: ({ id, ...tag }) => api.updateTag({ accountId, tagId: id, tag }),
    deleteApi: ({ id }) => api.deleteTag({ accountId, tagId: id })
  });

  return {
    isLoading,
    tags: data?.data || [],
    deleteTag: deleteItem,
    addTag: addItem,
    editTag: editItem,
    colorsOptions,
    tagsOptions,
    mappedColors,
    mappedTags
  };
};

export default useTags;
