import React from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { small } from '../../Typography';
import { FlexMiddle } from '../../Flex';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'select-chip' }))`
  background: ${({ color }) => color || '#dee2fe'};
  color: ${({ theme, color }) => (color ? lighten(0.5, color) : theme.colors.primary)};
  margin: 7px 5px;
  padding: 5px;
  border-radius: 3px;
  ${small};
  line-height: 12rem;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
      }
    `}
`;

const CloseButton = styled(CloseIcon)`
  width: 8px;
  height: 8px;
  margin-left: 10px;
`;

const Chip = ({ value, label, isDisabled, onRemove, color }) => (
  <Wrapper isDisabled={isDisabled} color={color} onClick={isDisabled ? undefined : () => onRemove(value)}>
    {label}
    {!isDisabled && <CloseButton />}
  </Wrapper>
);

export default Chip;
