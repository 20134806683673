import React, { useState, useMemo, useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';
import * as api from '@ubeya/shared/services/api';
import { ALLOWED_DOCUMENT_FILE_EXTENSIONS } from '@ubeya/shared/constants';
import { ReactComponent as ArrowIcon } from '@ubeya/shared-web/assets/arrow.svg';
import { ContextMenu as BaseContextMenu, DocumentPreview } from '@ubeya/shared-web/components';
import { flexCenter } from '../Flex';
import { small } from '../Typography';
import { Tooltip } from '../Tooltip';
import { ReactComponent as FileIcon } from '../../assets/file.svg';
import { getExtension } from '../../utils/files';
import ConfigContext from '../../contexts/ConfigContext';
import BaseFileUpload from './FileUpload';

const FileUpload = styled(BaseFileUpload)`
  ${({ showImagePlaceholder }) =>
    showImagePlaceholder &&
    css`
      position: relative;
      cursor: pointer;
      ${flexCenter};
      ${small};

      width: 100% !important;
      height: 150px;
      background: ${({ theme }) => theme.colors.white};

      color: ${({ theme }) => theme.colors.disabled};
      border: 2px solid ${({ theme }) => theme.colors.grey2};
      text-align: center;
      outline: none;

      &:focus {
        border-color: ${({ theme }) => theme.colors.primary};
      }
    `}

  ${({ $error, theme }) => $error && `border: 1px solid ${theme.colors.redNegative}`}
`;

const ContextMenu = styled(BaseContextMenu)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Arrow = styled(ArrowIcon).attrs(() => ({ className: 'arrow' }))`
  border-radius: 50%;
  position: absolute;
  transform: rotate(90deg);
  width: 10px;
  height: 10px;
  top: 3px;
  right: 3px;
  visibility: hidden;
  padding: 3px;
`;

const UploadBackground = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid ${({ theme }) => theme.colors.white};
  transition: background 200ms;
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const UploadIcon = styled(FileIcon)`
  width: 20px;
  height: 20px;
  path {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: max-content;
  &:hover {
    .arrow {
      visibility: visible;
      background-color: ${({ theme }) => theme.colors.disabled};
      > path {
        fill: ${({ theme }) => theme.colors.white};
        stroke: none;
      }
    }
  }
`;

const defaultDataSetter = (value) => value;

const DocumentUpload = ({ value, onChange, error, dataSetter = defaultDataSetter }) => {
  const { t } = useContext(ConfigContext);
  const [file, setFile] = useState(dataSetter(value));

  const changeFileData = useCallback(
    (newFile) => {
      onChange(newFile);
      setFile(newFile);
    },
    [onChange]
  );

  const onUploaded = useCallback(
    (data) => {
      const { title, path, file: uploadedFile } = data;
      const changedData = { title, path, localPath: URL.createObjectURL(uploadedFile) };

      changeFileData(changedData);
    },
    [changeFileData]
  );

  const fileOptions = useMemo(
    () => [
      {
        label: t('download'),
        onClick: () => {
          const src = file?.localPath || file?.path;
          window.open(src);
        }
      },
      {
        label: t('delete'),
        onClick: () => changeFileData(null)
      }
    ],
    [changeFileData, file?.localPath, file?.path, t]
  );

  return (
    <>
      <FileUpload
        showImagePlaceholder={!file}
        multiple={false}
        image={file}
        onUploaded={onUploaded}
        $error={error}
        apiFunc={api.uploadImage}
        accept={ALLOWED_DOCUMENT_FILE_EXTENSIONS}
        loaderSize="small">
        {file ? (
          <Wrapper key={file.path}>
            <DocumentPreview
              title={file.title}
              extension={getExtension(file, 'title')}
              subTitle={getExtension(file, 'title').toLowerCase() || ''}
            />

            <ContextMenu options={fileOptions} menuOffset={{ top: 30, left: 20 }} maxHeight={500} usePortal>
              <Arrow />
            </ContextMenu>
          </Wrapper>
        ) : (
          <>
            <Trans>addFile</Trans>
            {!!error && (
              <>
                - <Trans>{error}</Trans>
              </>
            )}

            <Tooltip body={t('upload')}>
              <UploadBackground>
                <UploadIcon />
              </UploadBackground>
            </Tooltip>
          </>
        )}
      </FileUpload>
    </>
  );
};

export default DocumentUpload;
