import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FlexColumn } from '../Flex';
import CircleIcon from '../CircleIcon';
import { header3SemiBold } from '../Typography';
import { ReactComponent as BaseArrow } from '../../assets/arrow.svg';

const BoxContent = styled(FlexColumn).attrs(() => ({ className: 'drawer-box-content' }))`
  background: ${({ theme }) => theme.colors.white};
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  margin-top: 20px;
  margin-left: 16px;
`;

const DrawerLabel = styled.div.attrs(() => ({ className: 'drawer-label' }))`
  ${header3SemiBold};
  background: ${({ theme }) => theme.colors.grey1};
  letter-spacing: 2.25px;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.78;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 3px 36px 5px 16px;

  .drawer-arrow {
    visibility: ${({ $showArrow }) => ($showArrow ? 'visible' : 'hidden')};
  }
`;

const Arrow = styled(BaseArrow)`
  transform: rotate(${({ $isOpen }) => ($isOpen ? -90 : 90)}deg);

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div.attrs(() => ({ className: 'drawer-wrapper' }))`
  margin: 0 0 20px;
`;

const Section = ({ title, defaultOpen = true, showArrow = true, children, drawerEndData = null }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const showExtraDataNextToArrow = useMemo(() => showArrow && isOpen, [isOpen, showArrow]);

  return (
    <Wrapper>
      <DrawerLabel onClick={() => setIsOpen((prev) => !prev)} $showArrow={showArrow} className="group">
        {title}
        <CircleIcon icon={Arrow} $isOpen={isOpen} className="drawer-arrow" />
        {drawerEndData && (showExtraDataNextToArrow ? drawerEndData : <div style={{ width: '36px' }} />)}
      </DrawerLabel>

      <BoxContent isOpen={isOpen} className="content">
        {children}
      </BoxContent>
    </Wrapper>
  );
};

const Drawers = ({ className, children }) => {
  return (
    <FlexColumn className={className}>
      {React.Children.map(
        React.Children.toArray(children).filter((child) => !!child.props),
        (child) => React.cloneElement(child)
      )}
    </FlexColumn>
  );
};

Drawers.Section = Section;

export default Drawers;
