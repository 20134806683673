import React, { useContext } from 'react';
import styled from 'styled-components';
import useSuppliers from '@ubeya/shared/hooks/account/useSuppliers';
import { Flex, FlexCenter } from '../Flex';
import { smallBody } from '../Typography';
import ConfigContext from '../../contexts/ConfigContext';
import { TextWithTooltip, Tooltip } from '../Tooltip';
import OutsourceLogo from './OutsourceLogo';

const ExternalBadge = styled(FlexCenter)`
  margin: 0 8px;
  max-width: 150px;
  ${smallBody};
`;

const SupplierNameWithTooltip = styled(TextWithTooltip)`
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
`;

const OutsourceLogoWrapper = styled(Flex)`
  .out-source-logo-circle,
  .out-source-logo-image {
    border: 1px solid ${({ theme }) => theme.colors.grey3};
  }

  .out-source-logo-circle:not(:first-child) {
    margin-left: -8px;
  }
`;

const MultipleOutsourceBadges = ({ supplierData, originSupplierData, withName }) => {
  const { t } = useContext(ConfigContext);
  const { mappedSuppliers } = useSuppliers();

  const showOriginalSupplierToClient = mappedSuppliers[supplierData?.id]?.crmConfig?.showOriginalSupplierToClient;

  const shouldRenderExternalSupplierName = originSupplierData && showOriginalSupplierToClient;
  const renderSupplierNameTooltip = shouldRenderExternalSupplierName
    ? t('supplierOrderByMainSupplier', {
        originSupplier: originSupplierData.name,
        supplier: supplierData.name
      })
    : supplierData.name;

  const renderSupplierName = shouldRenderExternalSupplierName ? originSupplierData.name : supplierData.name;

  return (
    <>
      {withName && (
        <ExternalBadge>
          <Tooltip body={renderSupplierNameTooltip}>
            <SupplierNameWithTooltip>{renderSupplierName}</SupplierNameWithTooltip>
          </Tooltip>
        </ExternalBadge>
      )}

      <Tooltip body={renderSupplierNameTooltip} disabled={withName}>
        <OutsourceLogoWrapper>
          {shouldRenderExternalSupplierName && (
            <OutsourceLogo logo={originSupplierData.logo} name={originSupplierData.name} withName />
          )}
          <OutsourceLogo logo={supplierData.logo} name={supplierData.name} withName />
        </OutsourceLogoWrapper>
      </Tooltip>
    </>
  );
};

export default MultipleOutsourceBadges;
