import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Input } from '../Input';
import CircleIcon from '../CircleIcon';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { FlexMiddle } from '../Flex';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useOnBrowserSearch from '../../hooks/useOnBrowserSearch';
import ConfigContext from '../../contexts/ConfigContext';

const Wrapper = styled(FlexMiddle)`
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  max-width: 200px;

  > div:first-child {
    position: absolute;
    right: 1px;
    z-index: 1;
  }

  > div:last-child {
    transition: opacity 150ms, width 500ms;
    opacity: ${({ isOpen }) => (!isOpen ? 0 : 1)};
    width: ${({ isOpen }) => (!isOpen ? '42px' : '100%')};
    max-width: 350px;
    padding: 2px;
    > input {
      min-height: unset;
      height: 34px;
    }
  }
`;

const Search = ({
  className,
  value,
  onChange,
  keepOpen = false,
  placeholder = null,
  focus = true,
  openOnBrowserSearch = false,
  closeIcon = undefined
}) => {
  const { t } = useContext(ConfigContext);
  const wrapperRef = useRef();
  const inputRef = useRef();
  const [isOpen, setIsOpen] = useState(keepOpen);

  const handleOpenAndFocus = useCallback(() => {
    setIsOpen(true);
    inputRef.current.focus();
  }, []);

  useOnClickOutside(wrapperRef, () => setIsOpen(false), !keepOpen && !value);
  useOnBrowserSearch(wrapperRef, handleOpenAndFocus, openOnBrowserSearch);

  const showCloseIcon = value.length > 0 && isOpen;

  const handleClick = useCallback(() => {
    if (showCloseIcon) {
      onChange('');
      !keepOpen && setIsOpen(false);
    } else {
      setIsOpen(true);
      inputRef.current.focus();
    }
  }, [keepOpen, onChange, showCloseIcon]);

  useEffect(() => {
    if (keepOpen && focus) {
      inputRef.current.focus();
    }
  }, [focus, keepOpen]);

  return (
    <Wrapper isOpen={isOpen} className={className} ref={wrapperRef} keepOpen={keepOpen}>
      <CircleIcon
        icon={showCloseIcon ? closeIcon ?? CloseIcon : SearchIcon}
        hideBackground={isOpen}
        onClick={handleClick}
        tooltip={showCloseIcon ? t('clear') : t('search')}
      />

      <Input placeholder={placeholder || t('search')} value={value} onChange={onChange} inputRef={inputRef} />
    </Wrapper>
  );
};

export default Search;
