import styled from 'styled-components';
import { Flex, FlexColumn } from '@ubeya/shared-web/components';

export const Wrapper = styled(Flex)`
  width: 100%;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    flex: 1;
    -moz-appearance: textfield;
    border: none;
    text-align: center;
  }

  input {
    width: 45px;
    height: 100%;
    line-height: 1.65;
    float: left;
    display: block;
    margin: 0;
    padding-right: 32px;
    padding-left: 0;
  }

  &.wrapper input:focus {
    outline: none;
  }
`;

export const ButtonsWrapper = styled(FlexColumn)`
  position: relative;

  .wrapper-button {
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.grey2};
    width: 20px;
    height: 21px;
    text-align: center;
    font-size: 13px;
    line-height: 1.4;
    transform: translateX(-100%);
    user-select: none;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.grey2};
      border: 1px solid ${({ theme }) => theme.colors.disabled};
    }

    &:active {
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .wrapper-button.wrapper-up {
    position: absolute;
    top: 1px;
    left: 0;
  }

  .wrapper-button.wrapper-down {
    position: absolute;
    bottom: 1px;
  }
`;
