import React from 'react';
import styled, { css } from 'styled-components';
import { CHAT_ICONS } from '@ubeya/shared/constants';
import { default as CircleIcon } from '../CircleIcon';
import { FlexCenter } from '../Flex';

const Button = styled(CircleIcon)`
  position: relative;

  ${({ $disableHover }) =>
    $disableHover &&
    css`
      &:hover {
        background: transparent;
      }
    `}
`;

const CircleWrapper = styled.div`
  position: relative;
`;

const Counter = styled(FlexCenter)`
  background: ${({ theme }) => theme.colors.notification};
  border-radius: ${({ $counter }) => ($counter > 9 ? '20px' : '100%')};
  position: absolute;
  min-width: 14px;
  padding: 0 4px;
  height: 15px;
  top: 2px;
  right: 0;
`;

const CounterText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  line-height: 15px;
  text-align: center;
`;

const ChatIcon = ({ iconType }) => CHAT_ICONS[iconType].component;

export const ChatButton = ({
  onClick,
  iconType = CHAT_ICONS.WHITE_FILLED.type,
  tooltip,
  disableHover = false,
  messages = 0
}) => {
  const numOfMessages = messages > 99 ? '+99' : messages;

  return (
    <CircleWrapper>
      <Button $disableHover={disableHover} tooltip={tooltip} onClick={onClick}>
        <ChatIcon iconType={iconType} />
      </Button>
      {messages > 0 && (
        <Counter $counter={numOfMessages}>
          <CounterText>{numOfMessages}</CounterText>
        </Counter>
      )}
    </CircleWrapper>
  );
};
