import React, { useContext, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import useTimeout from '@ubeya/shared/hooks/useTimeout';
import { flatten } from 'lodash';
import BaseDataGrid, { CheckBoxDataGrid } from '../DataGrid';
import { TextWithTooltip } from '../Tooltip';
import { LoaderArea } from '../Loader';
import CircleIcon from '../CircleIcon';
import { ReactComponent as EditColumn } from '../../assets/configure.svg';
import { bodySemiBold } from '../Typography';
import { card } from '../Card';
import CheckBox from '../CheckBox';
import ConfigContext from '../../contexts/ConfigContext';
import ColumnsMenu from './ColumnsMenu';

const gridStyle = css`
  --color: ${({ theme }) => theme.colors.black};
  --border-color: ${({ theme }) => theme.colors.grey2};
  --background-color: ${({ theme }) => theme.colors.white};
  --header-background-color: ${({ theme }) => theme.colors.white};
  --row-hover-background-color: ${({ theme }) => theme.colors.white};

  ${({ shadow }) => (shadow ? card : `box-shadow: none;`)}
  ${({ autoHeight }) => autoHeight && `overflow: hidden;`}

  .header-row,
  .row {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

    /* This serves to avoid the row text from being selected when reordering (blue selection) */
    ${({ isReordering }) => isReordering && `user-select: none;`}
  }

  #group {
    &:hover {
      background-color: ${({ theme }) => theme.colors.hoverBackground};
    }
  }

  #sub-row {
    &:hover {
      background-color: ${({ theme }) => theme.colors.hoverBackground};
    }
  }

  .cell {
    display: flex;
    align-items: center;
    ${({ noCellPadding }) => !noCellPadding && `padding: 10px;`}
    border: none;
    &.header {
      ${bodySemiBold};
      font-size: 13px;
    }
    &.checkbox-column {
      justify-content: center;
      input[type='checkbox'] {
        width: 0;
        position: absolute;
      }

      input[type='checkbox'] + label {
        padding-left: 0;
        margin-right: 0;
        min-width: 16px;
      }
    }
  }

  .cell:not(:first-child) {
    ${({ $showCellBorder }) =>
      $showCellBorder &&
      css`
        border-left: 1px solid ${({ theme }) => theme.colors.grey2};
        border-right: 0;
        &.header {
          border-left: 1px solid ${({ theme }) => theme.colors.grey2};
          border-right: 0;
        }
      `};
  }
`;

const DataGrid = styled(BaseDataGrid)`
  ${gridStyle}
`;

const StyledCheckBoxDataGrid = styled(CheckBoxDataGrid)`
  ${gridStyle}
`;

const DEFAULT_HEADER_ROW_HEIGHT = 38;
const DEFAULT_ROW_HEIGHT = 50;
const DEFAULT_IS_VIRTUALIZED = true;

const Table = ({
  autoHeight,
  shadow = true,
  noDataRows = 0,
  useCalculationBySubRows = false,
  isVirtualized = DEFAULT_IS_VIRTUALIZED,
  showCellBorder = true,
  extraTableActionIcon,
  extraTableActionHandler,
  extraTableActionIsLoading,
  extraTableActionTooltip,
  CellRenderer,
  LoadingComponent = LoaderArea,
  loadingTimeout = 500,
  checkboxSelection = false,
  ...props
}) => {
  const headerRowHeight = props.headerRowHeight ?? DEFAULT_HEADER_ROW_HEIGHT;
  const rowHeight = props.rowHeight ?? DEFAULT_ROW_HEIGHT;
  const emptyRowDisplay = props.data.length === 0 ? noDataRows : 0;
  const [isLoading, setIsLoading] = useState(true);
  const { isRtl } = useContext(ConfigContext);
  const [extendedRows, setExtendedRows] = useState({});
  const [isReordering, setIsReordering] = useState(false);

  const totalRows = useMemo(
    () =>
      props.data.length > 0
        ? props.data?.[0].subRows
          ? props.data?.[0].subRows.length * props.data.length
          : props.data.length
        : 0,
    [props]
  );

  useTimeout(() => setIsLoading(false), loadingTimeout, [setIsLoading]);

  const checkIsVirtualized = useMemo(() => {
    if (typeof isVirtualized === 'boolean') {
      return isVirtualized;
    }
    if (typeof isVirtualized === 'function') {
      return isVirtualized(totalRows);
    }
    return DEFAULT_IS_VIRTUALIZED;
  }, [isVirtualized, totalRows]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  // when each item in data array contains sub rows, the real data length is calculated as following
  const autoHeightDataLength = useCalculationBySubRows
    ? props.data.reduce((all, { subRows }) => all + (subRows ? subRows.length : 0), 0)
    : 0;
  const handleSortEnd = (sortProps) => {
    setIsReordering(false);
    props.onSortEnd?.(sortProps);
  };

  const handleSortStart = (sortProps) => {
    setIsReordering(true);
    props.onSortStart?.(sortProps);
  };

  if (checkboxSelection) {
    const rows = props.data.length;
    const subRows = useCalculationBySubRows
      ? flatten(props.data.filter((_, index) => extendedRows[index]).map((row) => row?.subRows)).length
      : 0;
    const checkBoxDataGridHeight =
      props.gridHeight ||
      (autoHeight
        ? (props.headless ? 0 : headerRowHeight) + (rows + subRows + emptyRowDisplay) * rowHeight
        : undefined);

    return (
      <StyledCheckBoxDataGrid
        $showCellBorder={showCellBorder}
        shadow={shadow}
        autoHeight={autoHeight}
        isVirtualized={checkIsVirtualized}
        headerRowHeight={headerRowHeight}
        rowHeight={rowHeight}
        CellRenderer={CellRenderer || TextWithTooltip}
        loadingRenderer={() => <LoaderArea />}
        {...props}
        onExpandClick={setExtendedRows}
        gridHeight={checkBoxDataGridHeight}
        columnsActionListRenderer={({ onOpen }) => <CircleIcon icon={EditColumn} onClick={onOpen} />}
        extraTableActionIconRenderer={() => (
          <CircleIcon
            tooltip={extraTableActionTooltip}
            isLoading={extraTableActionIsLoading}
            icon={extraTableActionIcon}
            onClick={extraTableActionHandler}
          />
        )}
        columnsMenuRenderer={(columnsMenuProps) => (
          <ColumnsMenu columnsMenuTitle={props.columnsMenuTitle} {...columnsMenuProps} />
        )}
        checkboxRenderer={CheckBox}
        isRtl={isRtl}
        checkboxSelection
        isReordering={isReordering}
        onSortStart={handleSortStart}
        onSortEnd={handleSortEnd}
      />
    );
  }

  return (
    <DataGrid
      $showCellBorder={showCellBorder}
      shadow={shadow}
      autoHeight={autoHeight}
      isVirtualized={checkIsVirtualized}
      headerRowHeight={headerRowHeight}
      rowHeight={rowHeight}
      CellRenderer={CellRenderer || TextWithTooltip}
      loadingRenderer={() => <LoaderArea />}
      {...props}
      gridHeight={
        props.gridHeight ||
        (autoHeight
          ? (props.headless ? 0 : headerRowHeight) +
            (props.data.length + autoHeightDataLength + emptyRowDisplay) * rowHeight
          : undefined)
      }
      columnsActionListRenderer={({ onOpen }) => <CircleIcon icon={EditColumn} onClick={onOpen} />}
      extraTableActionIconRenderer={() => (
        <CircleIcon
          tooltip={extraTableActionTooltip}
          isLoading={extraTableActionIsLoading}
          icon={extraTableActionIcon}
          onClick={extraTableActionHandler}
        />
      )}
      columnsMenuRenderer={(columnsMenuProps) => (
        <ColumnsMenu columnsMenuTitle={props.columnsMenuTitle} {...columnsMenuProps} />
      )}
      checkboxRenderer={CheckBox}
      isRtl={isRtl}
      isReordering={isReordering}
      onSortStart={handleSortStart}
      onSortEnd={handleSortEnd}
    />
  );
};

export default Table;
