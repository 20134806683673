import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ExplanationIcon } from '@ubeya/shared-web/assets/explanation.svg';
import BaseTooltip from '../Tooltip/Tooltip';

const MainTitle = styled.div`
  margin-right: 10px;
`;

const TitleSection = styled.div.attrs(() => ({ className: 'explanation-tooltip-title-section' }))`
  display: flex;
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey4};

  .svg {
    width: 50px;
    height: 50px;
  }
`;

const Tooltip = styled(BaseTooltip)`
  .rc-tooltip-inner {
    max-width: 200px;
    text-align: center;
  }
`;

const ExplanationTooltip = ({ title, body }) => {
  return (
    <TitleSection>
      {title && <MainTitle>{title}</MainTitle>}
      <Tooltip body={body}>
        <ExplanationIcon />
      </Tooltip>
    </TitleSection>
  );
};

export default ExplanationTooltip;
