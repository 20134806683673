import React, { useMemo, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import useFilters from '@ubeya/shared/hooks/account/useFilters';
import useFields from '@ubeya/shared/hooks/account/useFields';
import { formatOptions, removeFilter } from '../../utils/filters';
import { FlexMiddle } from '../Flex';
import DropDown from '../DropDown';
import FilterOptions from '../Filter/FilterOptions';
import ConfigContext from '../../contexts/ConfigContext';
import { GENERAL_FILTERS_SLUGS, PROJECT_SCHEDULING_FILTERS_SLUGS } from '../../constants';
import Chip from './Chip';
import PositionChip from './CustomChips/PositionsChip';
import RatingChip from './CustomChips/RatingChip';
import EmployeeTypeChip from './CustomChips/EmployeeTypeChip';
import AdminChip from './CustomChips/AdminChip';
import TagsChip from './CustomChips/TagsChip';
import ClientsChip from './CustomChips/ClientsChip';
import BranchesChip from './CustomChips/BranchesChip';
import RestrictedClientChip from './CustomChips/RestrictedClientChip';
import BookedInProjectChip from './CustomChips/BookedInProjectChip';
import SuppliersByBranchChip from './CustomChips/SuppliersByBranchChip';
import LocationsChip from './CustomChips/LocationsChip';
import FieldChip from './CustomChips/FieldChip';
import AgeChip from './CustomChips/AgeChip';
import DateChip from './CustomChips/DateChip';
import PreferredClientsChip from './CustomChips/PreferredClientsChip';
import EventBookerChip from './CustomChips/EventBookerChip';
import WorkedWithClientFilterChip from './CustomChips/WorkedWithClientFilterChip';
import BookingStatusChip from './CustomChips/BookingStatusChip';
import BooleanChip from './CustomChips/BooleanChip';
import OrderStatusChip from './CustomChips/OrderStatusChip';
import StartTimeChip from './CustomChips/StartTimeChip';
import CostCenterChip from './CustomChips/CostCenterChip';
import SuppliersByAccountChip from './CustomChips/SuppliersByAccountChip';
import ProjectBookingStatusChip from './CustomChips/ProjectBookingStatusChip';
import ProjectBookingIssuesStatusChip from './CustomChips/ProjectBookingIssuesStatusChip';
import PayrollProcessStatusChip from './CustomChips/PayrollProcessStatusChip';
import AgeWarningChip from './CustomChips/AgeWarningChip';
import DepartmentChip from './CustomChips/DepartmentChip';
import EmploymentTypeChip from './CustomChips/EmploymentTypeChip';
import ProjectCostCenterChip from './CustomChips/ProjectCostCenterChip';

const COMPONENTS_MAP = {
  positions: PositionChip,
  rating: RatingChip,
  employeeType: EmployeeTypeChip,
  workedWithClient: WorkedWithClientFilterChip,
  adminType: AdminChip,
  tags: TagsChip,
  eventBooker: EventBookerChip,
  clients: ClientsChip,
  suppliers: SuppliersByBranchChip,
  branches: BranchesChip,
  locations: LocationsChip,
  department: DepartmentChip,
  employmentType: EmploymentTypeChip,
  restrictedClient: RestrictedClientChip,
  bookedInProjects: BookedInProjectChip,
  payrollProcessStatus: PayrollProcessStatusChip,
  age: AgeChip,
  hasApp: BooleanChip,
  approved: BooleanChip,
  clientApproval: BooleanChip,
  notBookedOnDate: DateChip,
  preferredClients: PreferredClientsChip,
  bookingStatus: BookingStatusChip,
  availableByTimeAvailability: BooleanChip,
  orderStatusFilter: OrderStatusChip,
  costCenter: CostCenterChip,

  [PROJECT_SCHEDULING_FILTERS_SLUGS.positions]: PositionChip,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.locations]: LocationsChip,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.startTime]: StartTimeChip,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.endTime]: StartTimeChip,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.suppliers]: SuppliersByAccountChip,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.branchCostCenters]: ProjectCostCenterChip,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.bookingStatus]: ProjectBookingStatusChip,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.bookingIssues]: ProjectBookingIssuesStatusChip,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.ageWarning]: AgeWarningChip,

  [GENERAL_FILTERS_SLUGS.WITH_CLOCK_IN]: BooleanChip,
  [GENERAL_FILTERS_SLUGS.WITH_CLOCK_OUT]: BooleanChip,
  [GENERAL_FILTERS_SLUGS.WITH_CHECK_IN]: BooleanChip,
  [GENERAL_FILTERS_SLUGS.WITH_CHECK_OUT]: BooleanChip,
  [GENERAL_FILTERS_SLUGS.WITH_BREAKS]: BooleanChip
};

const Wrapper = styled(FlexMiddle).attrs(() => ({ className: 'filters-row-wrapper' }))`
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -10px;
`;

const FiltersRow = ({
  filterContextParams,
  selectedKey,
  filters,
  setFilters,
  disabledFilters = [],
  usePortal,
  className,
  externalOptions
}) => {
  const { t } = useContext(ConfigContext);
  const { filters: data } = useFilters();
  const { mappedFields } = useFields();

  const options = useMemo(() => {
    const basicOptions = formatOptions({ options: data[selectedKey], type: 'basic', filters });

    const fieldsOptions = formatOptions({
      options: data[selectedKey],
      type: 'fields',
      formatSlug: (id) => mappedFields?.[id]?.name,
      filters
    });

    return [...basicOptions, ...fieldsOptions].filter(({ value }) => Number.isInteger(value) || !isEmpty(value));
  }, [data, filters, mappedFields, selectedKey]);

  const handleRemoveFilter = useCallback(
    ({ type, id }) => {
      setFilters(removeFilter({ type, id, allFilters: filters }));
    },
    [filters, setFilters]
  );

  return (
    <Wrapper className={className}>
      {options.map(({ slug, value, type, id }, index) => {
        const isDisabled = disabledFilters.includes(slug);
        const Container = isDisabled ? ({ renderValue }) => renderValue : DropDown;
        const Component = type === 'basic' ? COMPONENTS_MAP[slug] : FieldChip;
        return (
          <Container
            key={index}
            openFromRight={!usePortal}
            usePortal={usePortal}
            showArrow={false}
            renderValue={
              <Chip
                label={t(slug)}
                value={
                  Component ? (
                    <Component id={id} value={value} externalOptions={externalOptions} />
                  ) : Array.isArray(value) ? (
                    value.join(',')
                  ) : (
                    value
                  )
                }
                onRemove={isDisabled ? undefined : () => handleRemoveFilter({ type, id })}
                isDisabled={isDisabled}
              />
            }>
            <FilterOptions
              {...{
                filterContextParams,
                externalOptions,
                selectedKey,
                filters,
                setFilters,
                selectedOpenFilter: { type, id, slug, value }
              }}
            />
          </Container>
        );
      })}
    </Wrapper>
  );
};

export default FiltersRow;
