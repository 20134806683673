import styled from 'styled-components';

const Divider = styled.div`
  width: 100%;
  height: 2px;
  margin: 0 0 19px;
  border: solid 1px var(--cloudy-blue);
  background-color: ${({ theme }) => theme.colors.grey3};
`;

export default Divider;
