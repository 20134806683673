import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import useOnClickOutside from '@ubeya/shared-web/hooks/useOnClickOutside';
import usePortal from '@ubeya/shared-web/hooks/usePortal';
import { PromptClick } from '@ubeya/shared-web/components';
import { media } from '../../style';

const jumpIn = keyframes`
  0%{transform: translateY(100%);}
  100%{transform: translateY(0);}
`;

const Container = styled.div`
  transform: translate(-50%, 0);

  justify-content: space-around;

  display: flex;
  align-items: center;
  width: 431px;
  align-self: center;
  box-shadow: 0 0 8px 6px rgba(125, 125, 125, 0.1);
  height: 56px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.primary};

  display: flex;
  margin: 0 50%;

  z-index: 4;

  ${media.tablet`
  position: fixed;
  top: 20px;
  margin: unset;
  animation: ${jumpIn} 300ms;
  right: 50%;
  left: 50%;`};
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

const FloatingNotifyButton = ({ onClose, text, promptyBody, promptConfirmText, prompt, onClick, innerButton }) => {
  const ref = useRef();
  const { Portal } = usePortal({ enabled: true, portalId: 'modal' });

  useOnClickOutside(ref, () => {
    onClose();
  });

  return (
    <Portal>
      <Container>
        {text && <Text>{text}</Text>}

        <PromptClick
          body={promptyBody}
          confirmText={promptConfirmText}
          enable={prompt || false}
          onClick={onClick}
          usePortal>
          {innerButton}
        </PromptClick>
      </Container>
    </Portal>
  );
};

export default FloatingNotifyButton;
