import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip as BaseTooltip } from '@ubeya/shared-web/components';

const CheckBoxWrapper = styled.div``;

const Tooltip = styled(BaseTooltip)`
  max-width: 200px;
`;

const useCheckboxColumn = ({ columns, checkboxData, checkboxRenderer: CheckBox }) => {
  const newColumns = useMemo(() => {
    if (!checkboxData) return columns;
    return [
      {
        Header: () => (
          <CheckBox
            name={`header-checkbox-${Math.random()}`}
            value={checkboxData.isAllChecked}
            onChange={checkboxData.toggleAll}
            indeterminate={!!(checkboxData.value.include || checkboxData.value.exclude)}
          />
        ),
        dataKey: 'checkbox',
        className: 'checkbox-column',
        minWidth: 0,
        maxWidth: 60,
        width: 60,
        sortable: false,
        captureRowClick: false,
        Cell: ({ rowData }) => {
          const disabledSet = new Set(checkboxData.disabled);
          const isDisabled = disabledSet.has(rowData.id);
          const CellCheckBox = (
            <CheckBox
              name={Math.random()}
              value={
                checkboxData.isAllChecked && !isDisabled
                  ? !checkboxData.isChecked(rowData)
                  : checkboxData.isChecked(rowData)
              }
              disabled={isDisabled}
              onChange={(checked) => checkboxData.toggleCheck(rowData, checked)}
            />
          );
          return (
            <Tooltip body={checkboxData.disabledTooltip} disabled={!isDisabled}>
              <CheckBoxWrapper>{CellCheckBox}</CheckBoxWrapper>
            </Tooltip>
          );
        }
      },
      ...columns
    ];
  }, [columns, checkboxData]);

  return newColumns;
};

export default useCheckboxColumn;
