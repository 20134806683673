import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import BasePhoneInput from 'react-phone-input-2';
import EmptyFlag from '@ubeya/shared-web/assets/empty-flag.png';
import 'react-phone-input-2/lib/style.css';
import ConfigContext from '../../contexts/ConfigContext';
import { body } from '../Typography';
import Label from '../Label';

const Container = styled.div.attrs(() => ({ dir: 'ltr' }))`
  .react-tel-input {
    border: 0;

    > input {
      ${body};
      border-radius: 2px;
      width: 100%;
      border: 1px solid ${({ theme }) => theme.colors.grey2};
      outline: 0;
      padding: 10px 40px 10px 40px;
      background: ${({ theme }) => theme.colors.white};
      min-height: 46px;
      ${({ theme, error }) => error && `border-color: ${theme.colors.redNegative};`}

      &:focus {
        border: 1px solid ${({ theme }) => theme.colors.primary};
      }

      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.grey3};
      }
    }

    .flag-dropdown {
      border: none;
      background: transparent;

      &.open,
      :hover {
        background: transparent;
        > div {
          background: transparent;
        }
      }

      > .selected-flag:focus {
        background: transparent;
        border: 1px solid ${({ theme }) => theme.colors.primary};
      }
    }

    .search {
      padding: 0;
      > input {
        margin: 0;
        width: 100%;
        padding: 10px 7px;
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};
        border-radius: 0;
      }
    }

    // Placeholder for no country selected
    .selected-flag[title=''] {
      .flag {
        background-image: url(${EmptyFlag});
      }
    }
  }
`;

const CUSTOM_MASKS = { gb: '(...) .... ....', ie: '..........', au: '(...) ... ...' };

const PhoneInput = ({ title, value, onChange, error, className, disableInitialDialCode = undefined, ...restProps }) => {
  const { countryCode } = useContext(ConfigContext);
  const [localValue, setLocalValue] = useState(value);

  const handleChange = useCallback(
    (newValue, { dialCode, countryCode: newCountryCode }) => {
      if (newValue === dialCode) {
        onChange(null, { countryCode: newCountryCode });
        setLocalValue(dialCode);
      } else {
        onChange(newValue);
        setLocalValue(newValue);
      }
    },
    [onChange]
  );

  return (
    <Label title={title} error={error} className={className} required={restProps.required}>
      <Container error={error}>
        <BasePhoneInput
          value={localValue}
          onChange={handleChange}
          placeholder="+0"
          enableSearch
          disableSearchIcon
          country={!disableInitialDialCode && countryCode.toLowerCase()}
          countryCodeEditable={false}
          masks={CUSTOM_MASKS}
          {...restProps}
        />
        {restProps?.children}
      </Container>
    </Label>
  );
};

export default PhoneInput;
