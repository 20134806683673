import React from 'react';
import BaseTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { isMobile } from 'react-device-detect';

const Tooltip = ({
  body,
  children,
  disabled,
  className,
  overlayStyle,
  mouseLeaveDelay = 0.2,
  keepDisplayUponHover = false,
  placement = 'top',
  trigger,
  mouseEnterDelay = 0.3
}) => {
  const disableOnMobile = isMobile && !trigger;

  if (disabled || disableOnMobile) {
    return children;
  }

  return (
    <BaseTooltip
      mouseLeaveDelay={keepDisplayUponHover ? undefined : mouseLeaveDelay}
      placement={placement}
      destroyTooltipOnHide
      overlay={Array.isArray(body) ? body.map((item, index) => <div key={index}>{item}</div>) : body}
      trigger={trigger}
      classNames={{
        root: className
      }}
      styles={{
        root: overlayStyle
      }}
      align={{
        //center the arrow
        targetOffset: [-6, 0],
        offset: [-6],
        //same as in the old version (5.0.1) of rc-tooltip
        overflow: {
          adjustX: 1,
          adjustY: 1,
          shiftX: 0
        }
      }}
      mouseEnterDelay={mouseEnterDelay}>
      {children}
    </BaseTooltip>
  );
};

export default Tooltip;
