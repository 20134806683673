import React from 'react';
import { Field } from 'react-final-form';
// import { PlacesAutocomplete } from '@ubeya/shared-web/components';
import { AttachmentsField } from './UploadDocuments';

const UploadField = ({ name, validate, title, hasExpiration, isDisabled }) => (
  <Field
    name={name}
    validate={validate}
    render={({ meta: { error } }) => {
      return (
        <>
          <AttachmentsField
            name={name}
            title={title}
            error={error}
            dataName={name}
            fieldContext="employee"
            hasExpiration={hasExpiration}
            isDisabled={isDisabled}
          />
        </>
      );
    }}
  />
);

export default UploadField;
