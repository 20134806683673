import styled, { css } from 'styled-components';
import { ReactComponent as BaseApproveIcon } from '../../assets/approve.svg';

const ApproveIcon = styled(BaseApproveIcon)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  g {
    ${({ $isActive }) =>
      $isActive
        ? css`
            fill: #fff;
            stroke: #fff;
          `
        : css`
            stroke: ${({ $color, theme }) => $color || theme.colors.greenPositive};
          `}
  }
  circle {
    ${({ $isActive }) =>
      $isActive
        ? css`
            fill: ${({ $color, theme }) => $color || theme.colors.greenPositive};
          `
        : css`
            stroke: ${({ $color, theme }) => $color || theme.colors.greenPositive};
          `}
  }
`;

export default ApproveIcon;
