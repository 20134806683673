import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { counterFormatter } from '../../utils/filters';
import { ReactComponent as FilterIcon } from '../../assets/filter.svg';
import { FlexCenter } from '../Flex';
import { extraSmall, Small } from '../Typography';
import { Tooltip } from '../Tooltip';
import DropDown from '../DropDown';
import CircleIcon from '../CircleIcon';
import BaseSearch from '../Search';
import ConfigContext from '../../contexts/ConfigContext';
import FilterOptions from './FilterOptions';

const ValueWrapper = styled.div`
  position: relative;
`;

const Counter = styled(FlexCenter)`
  ${extraSmall};
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 16px;
  height: 16px;
`;

const Search = styled(BaseSearch)`
  .circle-icon {
    margin-top: 20px;
  }
  > div:last-child {
    margin-top: 20px;
    > input {
      border: none;
      border-radius: 5px;
      width: 100%;
      border: 1px solid ${({ theme }) => theme.colors.grey2};
      outline: 0;
      padding: 14px;
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
      &::placeholder {
        color: ${({ theme }) => theme.colors.disabled};
      }
      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }
`;

const ErrorText = styled(Small)`
  color: ${({ theme }) => theme.colors.redNegative};
`;

const Value = ({ isActive, showIcon, title, filters, search, setSearch, disableTooltip = false }) => {
  const { t } = useContext(ConfigContext);
  const counter = useMemo(
    () =>
      Object.values(filters).reduce(
        (sum, array) => sum + array.reduce((prevSum, { value }) => prevSum + (counterFormatter(value) ? 1 : 0), 0),
        0
      ),
    [filters]
  );

  return (
    <Tooltip body={t('filter')} disabled={disableTooltip}>
      <ValueWrapper>
        {showIcon ? (
          <CircleIcon isActive={isActive} icon={FilterIcon} />
        ) : (
          <Search placeholder={title} value={search} onChange={setSearch} keepOpen />
        )}
        {counter > 0 && <Counter>{counter}</Counter>}
      </ValueWrapper>
    </Tooltip>
  );
};

const Filter = ({
  selectedKey,
  filterContextParams,
  setFilters,
  usePortal,
  error,
  openFromRight,
  externalOptions,
  title = '',
  filters = {},
  disabledFilters = [],
  showIcon = true,
  isDisabled = false,
  disableTooltip = false,
  doApiCall = true,
  maxHeight
}) => {
  const [search, setSearch] = useState('');

  return (
    <>
      {error && (
        <ErrorText>
          <Trans>{error}</Trans>
        </ErrorText>
      )}
      <DropDown
        showArrow={false}
        openFromRight={openFromRight}
        renderValue={
          <Value
            search={search}
            title={title}
            setSearch={setSearch}
            showIcon={showIcon}
            filters={filters}
            isDisabled={isDisabled}
            disableTooltip={disableTooltip}
          />
        }
        menuMinWidth={210}
        maxHeight={maxHeight || 400}
        menuOffset={{ top: 10 }}
        usePortal={usePortal}>
        <FilterOptions
          showSearchBox={showIcon}
          search={search}
          setSearch={setSearch}
          selectedKey={selectedKey}
          filters={filters}
          setFilters={setFilters}
          disabledFilters={disabledFilters}
          filterContextParams={filterContextParams}
          externalOptions={externalOptions}
          doApiCall={doApiCall}
        />
      </DropDown>
    </>
  );
};

export default Filter;
