import React, { useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useField } from 'react-final-form';
import { ATTACHMENTS_TYPES } from '@ubeya/shared/constants';
import moment from 'moment';
import { ReactComponent as PDFIcon } from '@ubeya/shared-web/assets/pdf-file.svg';
import useArrayManagement from '@ubeya/shared-web/hooks/useArrayManagement';
import { Body, DocumentPreview, FlexColumn } from '@ubeya/shared-web/components';
import { getSource, getExtension } from '@ubeya/shared-web/utils/files';
import { DatePickerInput } from '../../DatePicker';
import ConfigContext from '../../../contexts/ConfigContext';
import usePortal from '../../../hooks/usePortal';
import { FlexSpace } from '../../Flex';
import { SmallBody } from '../../Typography';
import AttachmentsActions from './AttachmentsActions';
import GeneralAttachments from './GeneralAttachments';
import AttachmentsList from './AttachmentsList';
import Uploader from './Uploader';

const attachment = css`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-top: 10px;
  &:not(:nth-child(4n)) {
    margin-right: 10px;
  }
`;

const ExpiredWarningText = styled.div`
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.redNegative};
  font-weight: normal;
  font-size: 13px;
  font-weight: 300;
`;

const Image = styled.div.attrs(({ src }) => ({ style: { backgroundImage: `url(${src})` } }))`
  ${attachment};
  background-position: center;
  background-size: cover;
  &:hover {
    cursor: pointer;
  }
`;

const Video = styled.video.attrs(() => ({ controls: true, autoPlay: true, loop: true }))`
  ${attachment};
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled(FlexColumn)`
  overflow: auto;

  .actions {
    padding: 0;
    margin: 10px 0;
  }

  .attachment-container {
    padding: 0;
    margin: 0 0 10px 0;
  }
`;

const MainTitle = styled(Body)`
  margin-top: 6px;
  color: ${({ theme }) => theme.colors.disabled};
`;

const Label = styled(SmallBody)`
  color: ${({ theme }) => theme.colors.grey4};
  white-space: initial;
  margin-bottom: 3px;
`;

const MaxAttachmentsLabel = styled(Label)`
  font-size: 12rem;
  color: ${({ $hasExceededLimit }) => ($hasExceededLimit ? 'lightcoral' : '#858585')};
`;

const getAttachmentComponent = (type) => {
  switch (type) {
    case 'document':
      return DocumentPreview;
    case 'image':
      return Image;
    case 'video':
      return Video;
    default:
      return DocumentPreview;
  }
};

const AttachmentsField = ({
  hasExpiration,
  error,
  title,
  allowedAttachmentsExtensions,
  dataName = 'documents',
  fieldContext = 'event',
  multiple = false,
  maxAttachments = Number.MAX_SAFE_INTEGER,
  isDisabled = false,
  withAttachmentsActions = true
}) => {
  const { data, handleAddItems, handleDelete } = useArrayManagement(dataName, { isDisabled });
  const { t } = useContext(ConfigContext);

  const expirationDateField = `${dataName}expirationDate`;

  const {
    input: { value, onChange }
  } = useField(dataName);

  const attachments = useMemo(
    () =>
      data.map((item) => {
        const extension = getExtension(item)?.toLowerCase();
        const type = ATTACHMENTS_TYPES[extension];
        const isVideoAttachment = type === 'video';

        return {
          id: item.id,
          src: getSource(item),
          Component: getAttachmentComponent(type),
          name: type === 'document' && extension,
          extension,
          type,
          title: item?.title,
          icon: type === 'document' && PDFIcon,
          videoUrl: isVideoAttachment
        };
      }),
    [data]
  );

  const fieldIsExpired =
    hasExpiration && !!value?.[0]?.expirationDate && moment(value?.[0]?.expirationDate).isBefore(moment());

  if (fieldContext === 'employee' || fieldContext === 'event') {
    return (
      <Wrapper>
        <FlexSpace>{title && <Label>{title}</Label>}</FlexSpace>

        {attachments?.length > 0 ? (
          <>
            <AttachmentsList
              data={attachments}
              handleDeleteItems={handleDelete}
              handleAddItems={handleAddItems}
              singleFile
              showDelete={!isDisabled}
            />
            {hasExpiration && (
              <DatePickerInput
                error={error}
                value={value?.[0]?.expirationDate}
                name={expirationDateField}
                title={`${title} ${t('expirationDate')}`}
                usePortal={usePortal}
                onChange={(expirationDate) => {
                  if (value.length > 0) {
                    value[0].expirationDate = expirationDate;
                    onChange([...value]);
                  }
                }}
              />
            )}

            {fieldIsExpired && <ExpiredWarningText>{t('expired')}</ExpiredWarningText>}

            {multiple && attachments.length < maxAttachments && (
              <Uploader handleAddItems={handleAddItems} disabled={isDisabled} />
            )}
          </>
        ) : (
          <Uploader handleAddItems={handleAddItems} disabled={isDisabled} />
        )}
      </Wrapper>
    );
  }

  const isUploadDisabled = maxAttachments ? attachments.length >= maxAttachments : false;
  const hasExceededLimit = maxAttachments ? attachments.length > maxAttachments : false;

  return (
    <Wrapper>
      <MainTitle>{title || <Trans>attachFiles</Trans>}</MainTitle>

      <GeneralAttachments
        enableUpload={!isUploadDisabled}
        data={attachments}
        handleDeleteItems={handleDelete}
        handleAddItems={handleAddItems}
        maxAttachments={maxAttachments}
        allowedAttachmentsExtensions={allowedAttachmentsExtensions}
      />

      {withAttachmentsActions && (
        <AttachmentsActions isUploadDisabled={isUploadDisabled} handleAddItems={handleAddItems} />
      )}

      {maxAttachments && (
        <MaxAttachmentsLabel $hasExceededLimit={hasExceededLimit}>
          {`${attachments.length}/${maxAttachments} ${t('attachments')}`}
        </MaxAttachmentsLabel>
      )}
    </Wrapper>
  );
};

export default AttachmentsField;
