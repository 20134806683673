import moment from 'moment';
import React from 'react';
import { ReactComponent as OrderPendingIcon } from '@ubeya/shared-web/assets/OrderDetails/exclamation-gray.svg';
import { ReactComponent as OrderCancelledIcon } from '@ubeya/shared-web/assets/OrderDetails/order-cancelled.svg';

import { ReactComponent as OrderChangesPendingIcon } from '@ubeya/shared-web/assets/OrderDetails/exclamation-orange.svg';
import { ReactComponent as OrderCompletedIcon } from '@ubeya/shared-web/assets/OrderDetails/order-completed.svg';
import { ReactComponent as OrderApprovedIcon } from '@ubeya/shared-web/assets/OrderDetails/order-completed.svg';
import { ReactComponent as BaseChatIconWhiteFilled } from '@ubeya/shared-web/assets/chat-white-filled.svg';
import { ReactComponent as BaseChatIconBlue } from '@ubeya/shared-web/assets/chat-blue.svg';
import { ReactComponent as BaseShiftCaptainIcon } from '@ubeya/shared-web/assets/is-captain-shift.svg';
import { ReactComponent as BaseAreaCaptainIcon } from '@ubeya/shared-web/assets/is-captain-area.svg';
import { ReactComponent as BaseEventCaptainIcon } from '@ubeya/shared-web/assets/is-captain-event.svg';
import { ReactComponent as ChatIcon } from '@ubeya/shared-web/assets/chat.svg';

import styled, { css } from 'styled-components';

export const EMPLOYEES_TABS = {
  active: { statusFilter: 'active', dataKey: 'active', tooltip: 'employeesTabActive' },
  inactive: { statusFilter: 'inactive', dataKey: 'inactive', tooltip: 'employeesTabDeactivated' },
  deleted: { statusFilter: 'deleted', dataKey: 'deleted', tooltip: 'employeesTabDeleted' },
  admins: { statusFilter: 'active', dataKey: 'admins', onlyAdmins: true, includeAdmin: true },
  pending: { statusFilter: 'isPending', dataKey: 'isPending', hideCaseEmpty: true },
  candidates: { statusFilter: 'candidates', dataKey: 'candidates', tooltip: 'employeesTabCandidates' }
};

const iconCSS = css`
  ${({ preventHoverStyle }) =>
    !preventHoverStyle &&
    css`
      &:hover {
        > circle {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
    `}
`;

const ShiftCaptainIcon = styled(BaseShiftCaptainIcon)`
  ${iconCSS};
`;

const AreaCaptainIcon = styled(BaseAreaCaptainIcon)`
  ${iconCSS};
`;

const EventCaptainIcon = styled(BaseEventCaptainIcon)`
  ${iconCSS}
`;

export const COMMENT_TYPES = [
  {
    id: 3,
    slug: 'comment__late'
  },
  {
    id: 2,
    slug: 'comment__disciplinary'
  },
  {
    id: 1,
    slug: 'comment__other'
  },

  {
    id: 4,
    slug: 'comment__employment'
  }
];

export const SUPPLIERS_TABS = {
  suppliers: { key: 'suppliers' }
};

export const SETTINGS__ACCOUNTS_TABS = [
  'info',
  'branches',
  'fields',
  'positions',
  'tags',
  'modules',
  'payroll',
  'departments'
];

export const CLOCK_METHODS = {
  SELF: { slug: 'clockMethodSelf', value: 0 },
  EMPLOYEE_QR_SCAN: { slug: 'clockMethodEmployeeQRScan', value: 1 },
  MANAGER_QR_SCAN: { slug: 'clockMethodManagerQRScan', value: 2 }
};

export const ORDER_STATUSES = {
  ORDER_INITIATED: {
    label: 'order_initiated',
    color: ({ theme }) => theme.colors.white,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.grey2,
    backgroundLight: ({ theme }) => theme.colors.grey2,
    slug: 'order_initiated',
    icon: OrderPendingIcon,
    nextStatus: 'client_order_approved',
    hideInSchedulingContext: true
  },
  DRAFT: {
    label: 'order_draft',
    color: ({ theme }) => theme.colors.white,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.grey2,
    backgroundLight: ({ theme }) => theme.colors.grey2,
    slug: 'order_draft',
    icon: OrderPendingIcon,
    nextStatus: 'supplier_order_pending_approval'
  },
  CLIENT_ORDER_PENDING_APPROVAL: {
    label: 'client_order_pending_approval',
    color: ({ theme }) => theme.colors.white,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.grey2,
    backgroundLight: ({ theme }) => theme.colors.grey2,
    slug: 'client_order_pending_approval',
    icon: OrderPendingIcon,
    nextStatus: 'supplier_order_approved'
  },
  SUPPLIER_ORDER_PENDING_APPROVAL: {
    label: 'supplier_order_pending_approval',
    color: ({ theme }) => theme.colors.white,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.grey2,
    backgroundLight: ({ theme }) => theme.colors.grey2,
    slug: 'supplier_order_pending_approval',
    icon: OrderPendingIcon,
    nextStatus: 'supplier_order_approved'
  },
  SUPPLIER_ORDER_APPROVED: {
    label: 'supplier_order_approved',
    color: ({ theme }) => theme.colors.black,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.greenPositive,
    backgroundLight: ({ theme }) => `${theme.colors.greenPositive}33`,
    slug: 'supplier_order_approved',
    icon: OrderApprovedIcon,
    nextStatus: null,
    changeButtonLabel: 'supplier_order_approved_change_button'
  },
  SUPPLIER_BOOKING_COMPLETED: {
    label: 'supplier_booking_completed',
    color: ({ theme }) => theme.colors.black,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.greenPositive,
    backgroundLight: ({ theme }) => theme.colors.greenLight,
    slug: 'supplier_booking_completed',
    icon: OrderApprovedIcon,
    nextStatus: null,
    changeButtonLabel: 'supplier_booking_completed_change_button'
  },
  CLIENT_APPROVED_HOURS: {
    label: 'client_approved_hours',
    color: ({ theme }) => theme.colors.black,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.greenPositive,
    backgroundLight: ({ theme }) => theme.colors.greenLight,
    slug: 'client_approved_hours',
    icon: OrderApprovedIcon,
    nextStatus: null,
    changeButtonLabel: 'client_approved_hours_change_button'
  },
  SUPPLIER_ORDER_COMPLETED: {
    label: 'supplier_order_completed',
    color: ({ theme }) => theme.colors.white,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.greenPositive,
    backgroundLight: ({ theme }) => theme.colors.greenLight,
    slug: 'supplier_order_completed',
    icon: OrderCompletedIcon,
    nextStatus: null,
    changeButtonLabel: 'supplier_order_completed_change_button'
  },
  CHANGES_REQUESTED: {
    label: 'changes_requested',
    color: ({ theme }) => theme.colors.white,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.orangeChanges,
    backgroundLight: ({ theme }) => theme.colors.orangeLight,
    slug: 'changes_requested',
    icon: OrderChangesPendingIcon,
    nextStatus: 'supplier_order_approved'
  },
  CANCELLATION_REQUESTED: {
    label: 'cancellation_requested',
    color: ({ theme }) => theme.colors.white,
    colorLight: ({ theme }) => theme.colors.black,
    background: ({ theme }) => theme.colors.orangeChanges,
    backgroundLight: ({ theme }) => theme.colors.orangeLight,
    slug: 'cancellation_requested',
    icon: OrderChangesPendingIcon,
    nextStatus: 'supplier_order_approved'
  },
  CANCELED: {
    label: 'order_canceled',
    color: ({ theme }) => theme.colors.white,
    colorLight: ({ theme }) => theme.colors.grey4,
    background: ({ theme }) => theme.colors.grey3,
    backgroundLight: ({ theme }) => theme.colors.grey2,
    slug: 'order_canceled',
    icon: OrderCancelledIcon,
    changeButtonLabel: 'order_canceled_change_button'
  }
};

export const ORDER_STATUSES_OPTIONS = Object.values(ORDER_STATUSES).map(({ label }) => label);

export const ORDER_CHANGES = {
  DECISIONS: {
    ACCEPTED: 'accepted',
    REJECTED: 'rejected'
  },
  REQUEST_TYPES: {
    NEW: 'new',
    CHANGE: 'change',
    CANCEL: 'cancel',
    ORDER_PENDING: 'order-pending'
  }
};

export const ACCOUNT_BILLING_STATUS_MAP = {
  STAFFING_AGENCY_FREEMIUM: 'staffingAgencyFreemium',
  CLIENT_FREEMIUM: 'clientFreemium',
  INTERNAL: 'internal',
  DEMO: 'demo',
  WARNING: 'warning',
  TRIAL: 'trial',
  PAYING: 'paying',
  BLOCKED: 'blocked',
  FROZEN: 'frozen',
  UNQUALIFIED: 'unqualified',
  PILOT: 'pilot'
};

export const ACCOUNT_STATUSES = [
  { label: 'Trial', value: ACCOUNT_BILLING_STATUS_MAP.TRIAL },
  { label: 'Paying', value: ACCOUNT_BILLING_STATUS_MAP.PAYING },
  { label: 'Demo', value: ACCOUNT_BILLING_STATUS_MAP.DEMO },
  { label: 'Warning', value: ACCOUNT_BILLING_STATUS_MAP.WARNING },
  { label: 'Blocked', value: ACCOUNT_BILLING_STATUS_MAP.BLOCKED },
  { label: 'Frozen', value: ACCOUNT_BILLING_STATUS_MAP.FROZEN },
  { label: 'Unqualified', value: ACCOUNT_BILLING_STATUS_MAP.UNQUALIFIED },
  { label: 'Pilot', value: ACCOUNT_BILLING_STATUS_MAP.PILOT },
  { label: 'Staffing Agency Freemium', value: ACCOUNT_BILLING_STATUS_MAP.STAFFING_AGENCY_FREEMIUM },
  { label: 'Client Freemium', value: ACCOUNT_BILLING_STATUS_MAP.CLIENT_FREEMIUM },
  { label: 'Internal', value: ACCOUNT_BILLING_STATUS_MAP.INTERNAL }
];

export const SETTINGS__BRANCH_TABS = ['info', 'timesheet', 'schedule', 'employees', 'locations', 'uniforms', 'payroll'];

export const SETTINGS__BRANCH_HOST_TABS = ['info'];

export const TIME_FORMAT = {
  12: 'hh:mma',
  24: 'HH:mm'
};

export const DATE_FORMAT = ['DD/MM/YYYY', 'MM/DD/YYYY'];

export const DAYS_OF_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const getDatesRange = () => ({
  yesterday: { start: moment().subtract(1, 'day').startOf('day'), end: moment().subtract(1, 'day').endOf('day') },
  today: { start: moment().startOf('day'), end: moment().endOf('day') },
  thisWeek: { start: moment().startOf('week').startOf('day'), end: moment().endOf('week').endOf('day') },
  lastWeek: {
    start: moment().subtract(1, 'week').startOf('week').startOf('day'),
    end: moment().subtract(1, 'week').endOf('week').endOf('day')
  },
  thisMonth: { start: moment().startOf('month').startOf('day'), end: moment().endOf('month').endOf('day') },
  lastMonth: {
    start: moment().subtract(1, 'month').startOf('month').startOf('day'),
    end: moment().subtract(1, 'month').endOf('month').endOf('day')
  },
  custom: { start: moment().startOf('day'), end: moment().endOf('day') }
});

export const ALLOWED_PHOTO_TYPES = '.jpeg,.jpg,.gif,.png';

export const ALLOWED_VIDEOS_TYPES = '.mp4,.mov,.qt';

export const ALLOWED_DOCUMENT_EXTENSIONS = '.jpeg,.jpg,.gif,.png,.pdf,.doc,.docx,.ppt,.pptx,.xlsx';

export const ALLOWED_DOCUMENT_FILE_EXTENSIONS = '.pdf,.doc,.docx,.ppt,.pptx,.xlsx';

export const LOCATION_ATTACHMENTS_EXTENSIONS = '.mp4,.mov,.qt,.jpeg,.jpg,.gif,.png,.pdf';

export const ALLOWED_FILE_EXTENSIONS = '.mp4,.mov,.qt,.jpeg,.jpg,.gif,.png,.pdf,.doc,.docx,.ppt,.pptx,.xlsx';

export const ATTACHMENTS_TYPES = {
  ...ALLOWED_DOCUMENT_EXTENSIONS.split(',').reduce((all, curr) => ({ ...all, [curr.slice(1)]: 'document' }), {}),
  ...ALLOWED_PHOTO_TYPES.split(',').reduce((all, curr) => ({ ...all, [curr.slice(1)]: 'image' }), {}),
  ...ALLOWED_VIDEOS_TYPES.split(',').reduce((all, curr) => ({ ...all, [curr.slice(1)]: 'video' }), {})
};

export const IMAGE_ATTACHMENTS_TYPES = ALLOWED_PHOTO_TYPES.split(',').map((extension) => extension.slice(1));

export const API_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const API_DATE_TIME_FORMAT_ZERO_SECONDS = 'YYYY-MM-DD HH:mm:00';
export const API_TIME_FORMAT = 'HH:mm:ss';
export const API_TIME_FORMAT_NO_SECONDS = 'HH:mm';
export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const POLLING_INTERVAL = 7 * 1000;
export const LONG_POLLING_INTERVAL = 1 * 60 * 1000;

//Chat - Post message
export const PM_EMPLOYEE_CLICKED = 'employee-clicked';
export const PM_CHAT_COUNTER = 'chat-counter';
export const PM_CLOSE_CHAT_MOBILE = 'close-chat-mobile';

//shift duration
export const MAX_HOURS_SHOW_PROMPT = 17;
export const MAX_HOURS_WARNING = 35;

// Weekly refresh interval
export const REFRESH_WEEKLY_INTERVAL = 1000 * 20;

// Timesheet slot fields
export const SLOT_FIELDS = {
  payrollNotes: 'payrollNotes',
  rating: 'rating',
  reviewOnEmployee: 'review'
};

//Project employee slot fields
export const EMPLOYEE_SLOT_FIELDS = {
  payrollNotes: 'payrollNotes',
  rating: 'rating',
  reviewOnEmployee: 'review'
};

export const PAYROLL_FIELD_TIP_ID = 1;
export const PAYROLL_FIELD_TIP_BONUS_ID = 85;
export const PAYROLL_FIELD_TIP_ID_7036 = 94;
export const PAYROLL_FIELD_TRAVEL_ID = 2;
export const PAYROLL_FIELD_TRAVEL_PARKING_ID = 137;

export const PAYROLL_PERIOD_TYPES = {
  MONTH: 'month',
  WEEK: 'week'
};

export const REQUEST_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  DECLINED: 2
};

export const REQUESTS_STATUS = {
  PENDING: 'pending',
  HISTORY: 'history'
};

export const CREATE_USER_BY = {
  PHONE: 0,
  EMAIL: 1
};

export const FILTERS_IDS = {
  POSITIONS_FILTER_ID: 1,
  AGE: 3,
  RESTRICTED_CLIENTS_FILTER_ID: 12,
  NOT_BOOKED_ON_DATE_FILTER_ID: 18,
  PREDERRED_CLIENTS_FILTER_ID: 19,
  START_EMPLOYMENT_DATE: 20,
  AVAILABLE_BY_TIME_AVAILABILITY: 23
};

export const EMPLOYEE_JOB_MONTHS_AGO = 120;
export const EMPLOYEE_JOB_MONTHS_AHEAD = 18;

export const EMPLOYEE_JOB_STATS = {
  pastFromDate: moment().subtract(EMPLOYEE_JOB_MONTHS_AGO, 'months').utc().format(API_DATE_FORMAT),
  pastToDate: moment().format(API_DATE_FORMAT),
  futureFromDate: moment().format(API_DATE_FORMAT),
  futureToDate: moment().add(EMPLOYEE_JOB_MONTHS_AHEAD, 'months').format(API_DATE_FORMAT),
  includeRejectedApplications: true
};

export const FEATURE_GATE_TYPES = {
  EVENTS: 'events',
  AVAILABILITY_CHECK: 'availability'
};

// warnings and infos
export const WARNINGS_AND_INFOS = {
  TOTAL_WEEK_HOURS: 'total_week_hours'
};

export const TIMESHEET_GROUP_BY_OPTIONS = [
  { label: 'groupByEmployee', value: 'employee' },
  { label: 'groupByDate', value: 'date' },
  { label: 'groupByPosition', value: 'position' },
  { label: 'groupByEvent', value: 'project' },
  { label: 'groupByClient', value: 'client' }
];

export const INVOICES_RECEIVED_GROUP_BY_OPTIONS = [
  { label: 'groupByEmployee', value: 'employee' },
  { label: 'groupByDate', value: 'date' },
  { label: 'groupByPosition', value: 'position' },
  { label: 'groupByEvent', value: 'project' }
];

export const TIMESHEET_GROUP_BY_OPTIONS_ENUM = {
  EMPLOYEE: 'employee',
  DATE: 'date',
  POSITION: 'position',
  PROJECT: 'project',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  LOCATION: 'location',
  CLIENT: 'client',
  COST_CENTER: 'costCenter'
};

export const TIMESHEET_DATA_KEY_OPTIONS = {
  START: 'start',
  END: 'end',
  CHECK_IN: 'checkIn',
  CHECK_OUT: 'checkOut'
};

export const FIGARO_LATEST_VERSION = 7;

export const CAPTAIN_TYPES = {
  EVENT: 1,
  SHIFT: 2,
  AREA: 3
};

export const CAPTAIN_TYPES_SLUGS = {
  EVENT: 'eventCaptain',
  SHIFT: 'shiftCaptain',
  AREA: 'areaCaptain'
};

export const CAPTAIN_ICONS_BY_TYPE = {
  [CAPTAIN_TYPES.SHIFT]: ShiftCaptainIcon,
  [CAPTAIN_TYPES.EVENT]: EventCaptainIcon,
  [CAPTAIN_TYPES.AREA]: AreaCaptainIcon
};

export const TAG_COLORS = [
  { id: 1, color: '#1abc9c' },
  { id: 2, color: '#2ecc71' },
  { id: 3, color: '#3498db' },
  { id: 4, color: '#9b59b6' },
  { id: 5, color: '#34495e' },
  { id: 6, color: '#f1c40f' },
  { id: 7, color: '#e67e22' },
  { id: 8, color: '#e74c3c' },
  { id: 9, color: '#bdc3c7' },
  { id: 10, color: '#9c88ff' },
  { id: 11, color: '#273c75' },
  { id: 12, color: '#192a56' },
  { id: 13, color: '#F43CCC' },
  { id: 14, color: '#FBA0BB' },
  { id: 15, color: '#66D1FF' },
  { id: 16, color: '#56E2D1' },
  { id: 17, color: '#9F7645' },
  { id: 18, color: '#D98FFB' },
  { id: 19, color: '#FF9881' }
];

const chatIconStyle = css`
  width: 24px;
  height: 24px;
`;

const ChatIconWhiteFilled = styled(BaseChatIconWhiteFilled)`
  ${chatIconStyle}
`;

const ChatIconBlue = styled(BaseChatIconBlue)`
  ${chatIconStyle}
`;

const ChatIconGray = styled(ChatIcon)`
  ${chatIconStyle}
`;

export const CHAT_ICONS = {
  WHITE_FILLED: {
    type: 'WHITE_FILLED',
    component: <ChatIconWhiteFilled />
  },
  BLUE: {
    type: 'BLUE',
    component: <ChatIconBlue />
  },
  GRAY: {
    type: 'GRAY',
    component: <ChatIconGray />
  }
};

export const LEGAL_AGE = 18;

export const QUERY_CACHE_TIME = 15 * 60 * 1000; // 15 minutes

export const getSelectedBranchKeyByAccountId = (accountId) => `selectedBranchId-${accountId}`;
