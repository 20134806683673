import React, { useState, useRef, useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as PlayIcon } from '@ubeya/shared-web/assets/Video/play.svg';
import { ReactComponent as PauseIcon } from '@ubeya/shared-web/assets/Video/pause.svg';
import { ReactComponent as MuteIcon } from '@ubeya/shared-web/assets/Video/mute.svg';
import { ReactComponent as SoundIcon } from '@ubeya/shared-web/assets/Video/sound.svg';
import BaseTimeline from './Timeline';

const VideoWrapper = styled.div.attrs(() => ({ className: 'video-wrapper' }))``;

const Controls = styled.div.attrs(() => ({ className: 'video-controls' }))`
  display: flex;
  padding: 0 20px;
`;

const ControlButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  border-radius: 100%;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.grey1};
  }
`;

const Timeline = styled(BaseTimeline)`
  margin-left: 10px;
`;

const VideoHtml = styled.video.attrs(() => ({ className: 'video-container' }))`
  width: 100%;
  max-height: calc(100% - 40px);
`;

const Video = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, toggleMute] = useReducer((prev) => !prev, true);
  const videoRef = useRef(null);
  const [handlerPosition, setHandlerPosition] = useState(0);

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
    const updateTime = () => {
      if (videoRef.current) {
        const seconds = Math.floor(videoRef.current.currentTime);
        setHandlerPosition(seconds);
      }
    };
    videoRef.current.addEventListener('timeupdate', updateTime);
  }, []);

  const handleChange = (value) => {
    setHandlerPosition(value);
    videoRef.current.currentTime = value;
  };

  return (
    <VideoWrapper>
      <VideoHtml ref={videoRef} autoPlay muted={isMuted} loop>
        <source src={src} type="video/mp4" />
      </VideoHtml>
      {videoRef.current && (
        <Controls>
          <ControlButton onClick={togglePlay}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</ControlButton>
          <ControlButton onClick={toggleMute}>{isMuted ? <MuteIcon /> : <SoundIcon />}</ControlButton>
          <Timeline
            value={handlerPosition}
            max={videoRef.current.duration}
            onChange={(e) => handleChange(e.target.value)}
          />
        </Controls>
      )}
    </VideoWrapper>
  );
};

export default Video;
