import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { ALLOWED_DOCUMENT_EXTENSIONS } from '@ubeya/shared/constants';
import * as api from '@ubeya/shared/services/api';
import { ReactComponent as UploadIcon } from '@ubeya/shared-web/assets/upload-a-file.svg';
import { FileUpload as BaseFileUpload, FlexSpace } from '@ubeya/shared-web/components';
import ConfigContext from '../../../contexts/ConfigContext';

const FileUpload = styled(BaseFileUpload)`
  width: 100% !important;
  text-align: center;
  outline: none;

  ${({ $error, theme }) => $error && `border: 1px solid ${theme.colors.redNegative}`}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UploadText = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.grey3};
  font-size: 13px;
`;

const Actions = styled(FlexSpace)`
  padding: 10px;
  border: 1px dashed ${({ theme }) => theme.colors.grey2};
  width: 100% !important;
  height: 46px;
  outline: 0;
  border-radius: 3px;
  ${({ $disabled }) =>
    $disabled
      ? css`
          cursor: not-allowed;
          opacity: 0.5;
        `
      : css`
          &:hover {
            background-color: rgba(215, 220, 231, 0.2);
            cursor: pointer;
          }
        `};
`;

const Uploader = ({ handleAddItems, disabled = false }) => {
  const { t } = useContext(ConfigContext);

  return (
    <Actions $disabled={disabled}>
      <FileUpload
        apiFunc={api.uploadImage}
        onUploaded={handleAddItems}
        accept={ALLOWED_DOCUMENT_EXTENSIONS}
        enabled={!disabled}
        loaderSize="small">
        <Wrapper>
          <UploadIcon />
          <UploadText>{t('uploadFile')}</UploadText>
        </Wrapper>
      </FileUpload>
    </Actions>
  );
};

export default Uploader;
