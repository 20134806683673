import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { fileUpload } from '@ubeya/shared/atoms/shared';
import useToaster from '@ubeya/shared/hooks/useToaster';
import { Loader } from '../Loader';

const FileUpload = ({
  onDrop,
  onUploaded,
  apiFunc,
  children,
  loaderSize,
  accept,
  className,
  maxAttachments,
  multiple = true,
  enabled = true
}) => {
  const [isFileUploading, setIsFileUpload] = useRecoilState(fileUpload);
  const { addError } = useToaster();

  const { mutateAsync: uploadFile } = useMutation(apiFunc);

  const handleDrop = useCallback(
    async (files) => {
      if (files.length === 0) {
        return;
      }

      onDrop?.(multiple ? files : files[0]);

      if (!apiFunc) {
        return;
      }

      if (maxAttachments && files.length > maxAttachments) {
        addError(`Cannot upload more than ${maxAttachments} attachments`);
        return;
      }

      setIsFileUpload(true);

      const result = (
        await Promise.all(
          files.map(async (file) => {
            try {
              const uploadResult = await uploadFile([file]);
              return { file, title: file?.name, path: uploadResult.data.path };
            } catch (error) {
              addError(`Invalid file format or size: ${file?.name}`);
              return;
            }
          })
        )
      ).filter((value) => !!value);

      setIsFileUpload(false);
      onUploaded?.(multiple ? result : result[0], multiple ? files : files[0]);
    },
    [onDrop, multiple, apiFunc, onUploaded, maxAttachments, addError, setIsFileUpload, uploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple,
    disabled: !enabled,
    accept
  });

  return (
    <div {...getRootProps()} className={`${className} ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      {isFileUploading ? <Loader size={loaderSize} /> : children}
    </div>
  );
};

export default FileUpload;
