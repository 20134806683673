import { useCallback, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
// import { Mixpanel } from '../analytics/Mixpanel';
import useOnClickOutside from './useOnClickOutside';

const usePortal = ({ enabled, portalId, closeOnClickOutside = true, onClose }) => {
  const [coordinates, setCoordinates] = useState();
  const ref = useRef();

  useOnClickOutside(
    ref,
    () => {
      setCoordinates();
      onClose?.();
    },
    closeOnClickOutside && !!coordinates,
    portalId
  );

  const setOpen = useCallback(() => setCoordinates(ref.current?.getBoundingClientRect?.()), []);

  const setClose = useCallback(() => setCoordinates(), []);

  const setToggle = useCallback(
    (e) => {
      // if (printLogs) {
      //   Mixpanel.track('CHANGE SHIFT CLICK ISSUE - 10', {
      //     e,
      //     coordinates,
      //     willOpen: !coordinates,
      //     willOpenWithCoordinates: ref.current?.getBoundingClientRect?.()
      //   });
      // }
      e.preventDefault();
      e.stopPropagation();

      coordinates ? setClose() : setOpen();
    },
    [coordinates, setClose, setOpen]
  );

  const Portal = useCallback(
    ({ children }) => {
      if (enabled) {
        return ReactDOM.createPortal(children, document.getElementById(portalId));
      }
      return children;
    },
    [portalId, enabled]
  );

  return { Portal, isOpen: !!coordinates, setToggle, setOpen, setClose, clickRef: ref, coordinates };
};

export default usePortal;
