import React, { useCallback, useMemo, useState, useRef, useEffect, useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { Map, Marker, InfoWindow, Circle } from 'google-maps-react';
import { ExtraSmallSemiBold } from '../Typography';
import locationPurple from '../../assets/map-marker-1.svg';
import locationYellow from '../../assets/map-marker-2.svg';
import locationBlack from '../../assets/map-marker-3.svg';
import ConfigContext from '../../contexts/ConfigContext';
import mapUtils from './utils';

const COLORS = window.google
  ? {
      purple: new window.google.maps.MarkerImage(locationPurple),
      yellow: new window.google.maps.MarkerImage(locationYellow),
      black: new window.google.maps.MarkerImage(locationBlack)
    }
  : { purple: '#000', yellow: '#000', black: '#000' };

const containerStyle = {
  position: 'relative'
};

const AddressContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AddressNameContainer = styled.div`
  text-align: center;
`;

const styles = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }]
  }
];

const LocationName = styled(ExtraSmallSemiBold)`
  color: ${({ $color }) => $color};
`;

const AddressComponent = ({ lat, lng, width }) => {
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (!lat || !lng) {
      return;
    }
    mapUtils
      .getAddressFromLatLng(window.google, parseFloat(lat), parseFloat(lng))
      .then((value) => setAddress(value))
      .catch(() => setAddress(null));
  }, [lat, lng]);

  return <AddressNameContainer style={{ width }}>{address || 'Loading...'}</AddressNameContainer>;
};

const MapContainer = ({
  width = '100%',
  height = '100%',
  markers = [],
  defaultMarker = { lat: 19.95, lng: 30.33 },
  zoom = 14,
  circleRadius,
  allowShowAddress = false
}) => {
  const { distanceUnit } = useContext(ConfigContext);
  const { google } = window;
  const googleMap = useRef();
  const filteredMarkers = useMemo(() => markers.filter((marker) => marker?.lat && marker?.lng), [markers]);
  const { colors } = useTheme();

  const onReady = useCallback((mapProps, map) => {
    googleMap.current = map;
    // if (!google) {
    //   return;
    // }
    // const bounds = new google.maps.LatLngBounds();

    // filteredMarkers.forEach((marker) => {
    //   const { lat, lng } = marker;
    //   bounds.extend(new google.maps.LatLng(lat, lng));
    // });

    // map.fitBounds(bounds, 1000);
    // map.setZoom(50);
    // google.maps.event.trigger(map, 'resize');
  }, []);

  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [addressDisplay, setAddressDisplay] = useState(false);

  const onMarkerClick = useCallback((props, marker) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  }, []);

  const onMapClicked = useCallback(() => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  }, [showingInfoWindow]);

  const center = useMemo(
    () =>
      filteredMarkers && filteredMarkers.length > 0
        ? { lat: filteredMarkers[0].lat, lng: filteredMarkers[0].lng }
        : defaultMarker,
    [defaultMarker, filteredMarkers]
  );

  useEffect(() => {
    if (!google || !googleMap?.current) {
      return;
    }
    const bounds = new google.maps.LatLngBounds();

    if (circleRadius) {
      const newLatitude1 = center.lat - ((circleRadius * 5) / 6371e3) * (180 / Math.PI);
      const newLangitude1 = center.lng - ((circleRadius * 5) / 6371e3) * (180 / Math.PI);

      const newLatitude2 = center.lat + ((circleRadius * 5) / 6371e3) * (180 / Math.PI);
      const newLangitude2 = center.lng + ((circleRadius * 5) / 6371e3) * (180 / Math.PI);

      const newLatitude3 = center.lat - ((circleRadius * 5) / 6371e3) * (180 / Math.PI);
      const newLangitude3 = center.lng + ((circleRadius * 5) / 6371e3) * (180 / Math.PI);

      const newLatitude4 = center.lat + ((circleRadius * 5) / 6371e3) * (180 / Math.PI);
      const newLangitude4 = center.lng - ((circleRadius * 5) / 6371e3) * (180 / Math.PI);

      bounds.extend(new google.maps.LatLng(newLatitude1, newLangitude1));
      bounds.extend(new google.maps.LatLng(newLatitude2, newLangitude2));
      bounds.extend(new google.maps.LatLng(newLatitude3, newLangitude3));
      bounds.extend(new google.maps.LatLng(newLatitude4, newLangitude4));
      googleMap?.current.fitBounds(bounds, 100);
    } else {
      googleMap?.current.setCenter({ lat: center.lat, lng: center.lng });
    }
  }, [center.lat, center.lng, circleRadius, filteredMarkers, google]);

  let distanceMultiplier = 1000;
  if (distanceUnit === 'mi') {
    distanceMultiplier = 1609;
  }

  return (
    <>
      <Map
        google={google}
        zoom={zoom}
        containerStyle={{ ...containerStyle, width, height }}
        fullscreenControl={false}
        zoomControl={false}
        mapTypeControl={false}
        streetViewControl={false}
        onReady={onReady}
        // onLoad={adjustMap}
        initialCenter={center}
        onClick={onMapClicked}
        styles={styles}>
        {filteredMarkers.map(({ lat, lng, color = 'purple', ...markerProps }, index) => (
          <Marker
            key={index}
            position={{ lat, lng }}
            onClick={(_, marker) => onMarkerClick(markerProps, marker)}
            icon={color && COLORS[color]}
          />
        ))}
        {circleRadius && circleRadius > 0 && (
          <Circle
            center={center}
            radius={circleRadius * distanceMultiplier}
            fillColor="#f98b88"
            strokeColor="#f98b88"
            strokeWeight={0.8}
          />
        )}
        <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
          <LocationName $color={colors.black}>{selectedPlace.title}</LocationName>
        </InfoWindow>
      </Map>
      {allowShowAddress &&
        filteredMarkers?.length > 0 &&
        (addressDisplay ? (
          <AddressComponent lat={filteredMarkers[0]?.lat} lng={filteredMarkers[0]?.lng} width={width} />
        ) : (
          <AddressContainer onClick={() => setAddressDisplay(true)}>Show Address </AddressContainer>
        ))}
    </>
  );
};

export default React.memo(MapContainer);
