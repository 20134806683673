import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from '../Tooltip';
import { FlexEnd } from '../Flex';

const Container = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const Bar = styled.div.attrs(() => ({ className: 'multi-progress-bar-height' }))`
  height: 100px;
  display: flex;
  width: 100%;
  border-radius: 4px;
  background: rgba(153, 195, 255, 0.3);
`;

const Progress = styled(FlexEnd)`
  background: ${({ theme, $color }) => $color || theme.colors.greenPositive};
  border-top-left-radius: ${({ $isFirstItem }) => ($isFirstItem ? '4px' : '0')};
  border-bottom-left-radius: ${({ $isFirstItem }) => ($isFirstItem ? '4px' : '0')};
  border-top-right-radius: ${({ $isLastItem }) => ($isLastItem ? '4px' : '0')};
  border-bottom-right-radius: ${({ $isLastItem }) => ($isLastItem ? '4px' : '0')};
  width: 100%;
  height: 100%;
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
`;

const Counter = styled.p.attrs(() => ({ className: 'multi-progress-bar-counter' }))`
  color: #676876;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
`;

const Percentage = styled.div`
  color: white;
  font-weight: 400;
  font-size: 14px;
`;

const ProgressSection = styled.div`
  position: relative;
  flex: ${({ $width }) => `${$width / 100}`};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const MultipleProgressBar = ({ values, total, showCounter = false, showPercentage = false }) => {
  const generateValues = values
    .map(({ value, color, tooltip, onClick }) => ({
      value: total > 0 ? (value / total) * 100 : 0,
      color,
      tooltip,
      onClick
    }))
    .filter(({ value }) => value > 0);

  const totalValues = values.reduce((output, { value }) => output + value || 0, 0);

  return (
    <Container>
      <Bar className="multi-bar">
        {generateValues.map(({ value, color, tooltip, onClick }, index) => (
          <Tooltip key={index} body={tooltip} disabled={!tooltip}>
            <ProgressSection key={index} $width={value} onClick={onClick}>
              <Progress $color={color} $isLastItem={index === generateValues.length - 1} $isFirstItem={index === 0}>
                {showPercentage && <Percentage>{Math.round(value)}%</Percentage>}
              </Progress>
            </ProgressSection>
          </Tooltip>
        ))}
      </Bar>
      {showCounter && (
        <Counter className="multi-counter">
          {totalValues}/{total}
        </Counter>
      )}
    </Container>
  );
};

export default MultipleProgressBar;
