import { css } from 'styled-components';

const theme = css`
  --color: #000;
  --border-color: #ccd1db !important;
  --background-color: hsl(0deg 0% 100%);
  --header-background-color: hsl(0deg 0% 97.5%);
  --row-hover-background-color: hsl(0deg 0% 96%);
`;

export default theme;
