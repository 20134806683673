import React from 'react';
import styled from 'styled-components';
import { FlexMiddle } from '../Flex';

const Circle = styled.div`
  width: 12px;
  height: 12px;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.greenPositive : theme.colors.grey2)};
  border-radius: 100%;
  box-shadow: 0 2px 8px 0 rgba(47, 60, 79, 0.08);
  z-index: 1;
  position: relative;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const WizardBar = ({ page, stepsCount, className }) => {
  if (stepsCount === 1) {
    return null;
  }

  return (
    <FlexMiddle className={className}>
      {[...Array(stepsCount)].map((_, index) => (
        <Circle key={index} isActive={page === index} />
      ))}
    </FlexMiddle>
  );
};

export default WizardBar;
