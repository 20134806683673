import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { FlexSpace } from '../Flex';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import CircleIcon from '../CircleIcon';

const TitleRow = styled(FlexSpace)`
  padding: 22px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};
`;

const TitleContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => ($align === 'center' ? 'center' : 'flex-start')};
`;

const NavItem = styled.div`
  ${({ $withMargin }) => $withMargin && 'margin: 0 20px;'};
  ${({ $big }) => $big && 'font-size: 18rem;'};

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  ${({ $currentStep }) =>
    $currentStep
      ? css`
          font-weight: 500;
          line-height: 26rem;
        `
      : css`
          color: #c8c8c8;
        `};
`;

const Arrow = styled.div`
  ${({ $currentStep }) =>
    $currentStep
      ? css`
          font-weight: 600;
          line-height: 26rem;
        `
      : css`
          color: #c8c8c8;
        `};
`;

const WizardTopNavBarItem = ({ index, disabled, setPage, page, tab, showArrow = true }) => {
  const currentStep = page === index;
  const disablePress = disabled && index > page;

  const onClick = useCallback(() => (disablePress ? undefined : setPage(index)), [disablePress, index, setPage]);

  return (
    <>
      {showArrow && index !== 0 && <Arrow $currentStep={currentStep}> > </Arrow>}
      <NavItem
        $withMargin={showArrow}
        $big={!showArrow}
        $currentStep={currentStep}
        $disabled={disablePress}
        onClick={onClick}>{`${index + 1}. ${tab}`}</NavItem>
    </>
  );
};

const WizardTopNavBar = ({ page, wizardTopNavTabs, onClose, disabled, setPage, showOnlyCurrent = false }) => {
  return (
    <div>
      <TitleRow className="title">
        <TitleContent $align={showOnlyCurrent ? undefined : 'center'}>
          {(wizardTopNavTabs || []).map((tab, index) => {
            if (showOnlyCurrent && page !== index) {
              return null;
            }
            return (
              <WizardTopNavBarItem
                key={index}
                index={index}
                page={page}
                disabled={disabled}
                setPage={setPage}
                tab={tab}
                showArrow={!showOnlyCurrent}
              />
            );
          })}
        </TitleContent>
        <CircleIcon icon={CloseIcon} onClick={onClose} />
      </TitleRow>
    </div>
  );
};

export default WizardTopNavBar;
