import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

export const TagRoot = styled.div`
  max-width: ${({ maxWidth }) => `${maxWidth}px` || '100%'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  white-space: nowrap;
  padding: 0;
  background-color: ${({ type, theme }) => (type === 'filled' ? theme.colors.grey2 : 'transparent')};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.grey2};
  cursor: default;
  user-select: none;
`;

export const TagLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TagDeleteIcon = styled(CloseIcon)`
  min-width: 8px;
  max-width: 8px;
  min-height: 8px;
  max-height: 8px;
  cursor: pointer;
  padding: 0 6px 0 2px;
`;
