import React from 'react';
import styled from 'styled-components';
import BaseInput from './Input';

const Input = styled(BaseInput).attrs(() => ({ className: 'editable-input' }))`
  max-width: 120px;
  border: none;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.disabled};
  background: transparent;
  outline: none;
  padding: 3px;
  min-height: 20px;
  border-radius: unset;
  color: ${({ theme }) => theme.colors.black};

  &:focus {
    border: none;
    border: 1px solid ${({ theme }) => theme.colors.disabled};
    box-shadow: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const EditableInput = ({
  value,
  placeholder,
  onChange,
  onSubmit = undefined,
  type = 'number',
  className,
  setEmptyWhenZero = true
}) => {
  const setValueToEmptyUponZero = () => setEmptyWhenZero && value === 0 && onChange?.('');

  return (
    <Input
      type={type}
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onFocus={setValueToEmptyUponZero}
      onKeyPress={(e) => e.which === 13 && onSubmit() && e.target.blur()}
      onBlur={onSubmit}
    />
  );
};

export default EditableInput;
