import defaultColors from './defaultColors.json';

// for toaster
defaultColors.toast = {
  success: defaultColors.greenPositive,
  error: defaultColors.redNegative,
  info: defaultColors.generalBlue
};

const themeMaker = (themeColors = {}) => {
  const colors = { ...defaultColors, ...themeColors };

  return {
    colors,
    button: {
      primary: {
        normal: {
          background: colors.primary,
          color: colors.white,
          border: 'transparent'
        },
        hover: {
          background: colors.primary,
          color: colors.white,
          border: 'transparent'
        },
        disabled: {
          background: colors.disabled,
          color: colors.grey3,
          border: 'transparent'
        },
        pressed: {
          background: colors.primary
        }
      },
      secondary: {
        normal: {
          background: colors.white,
          color: colors.primary,
          border: colors.primary
        },
        hover: {
          background: colors.white,
          color: colors.primary,
          border: colors.primary,
          shadow: colors.inputShadow
        },
        disabled: {
          background: colors.white,
          color: colors.grey3,
          border: colors.grey3
        },
        pressed: {
          background: colors.white
        }
      },
      tertiary: {
        normal: {
          background: colors.white,
          color: colors.primary,
          border: 'transparent'
        },
        hover: {
          background: colors.white,
          color: colors.primary,
          border: 'transparent'
        },
        disabled: {
          background: colors.white,
          color: colors.grey3,
          border: 'transparent'
        },
        pressed: {
          background: colors.white
        }
      },
      quaternary: {
        general: {
          padding: '13px 73px'
        },
        normal: {
          background: colors.primary,
          color: colors.white,
          border: colors.white
        },
        hover: {
          background: colors.primary,
          color: colors.white,
          border: colors.white
        },
        disabled: {
          background: colors.disabled,
          color: colors.grey3,
          border: colors.grey3
        },
        pressed: {
          background: colors.primary
        }
      },
      success: {
        normal: {
          background: colors.greenPositive,
          color: colors.white,
          border: 'transparent'
        },
        hover: {
          background: colors.greenPositive,
          color: colors.white,
          border: 'transparent'
        },
        disabled: {
          background: colors.greenPositive,
          color: colors.white,
          border: 'transparent'
        },
        pressed: {
          background: colors.greenPositive
        }
      },
      link: {
        general: {
          borderWidth: '0 0 1px 0',
          padding: 0,
          borderRadius: 0
        },
        normal: {
          background: 'transparent',
          color: colors.primary,
          border: colors.primary
        },
        hover: {
          background: 'transparent',
          color: colors.primary,
          border: colors.primary
        },
        disabled: {
          background: 'transparent',
          color: colors.black,
          border: colors.black
        }
      },
      secondaryGray: {
        general: {
          borderRadius: '4px'
        },
        normal: {
          background: colors.white,
          color: colors.black,
          border: colors.grey2
        },
        hover: {
          background: colors.white,
          color: colors.black,
          border: colors.grey3
        },
        disabled: {
          background: colors.white,
          color: colors.grey3,
          border: colors.grey3
        },
        pressed: {
          background: colors.grey1
        }
      }
    }
  };
};

export default themeMaker;
