import styled from 'styled-components';
import { TextWithTooltip } from '../Tooltip';

export const CircleChip = styled(TextWithTooltip)`
  margin: 2px;
  min-width: 28px;
  height: 28px;
  white-space: nowrap;
  padding: 4px 10px 5px;
  border-radius: 15.5px;
  background-color: #ffd029;
  background: ${({ theme, $color }) => $color || theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`;
