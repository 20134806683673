import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FlexColumn, FlexColumnCenter } from '../Flex';
import { smallBodySemiBold } from '../Typography';
import { Loader } from '../Loader';
import { responsiveColumn, media } from '../../style';

const Wrapper = styled(FlexColumn)`
  ${responsiveColumn.tablet}
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background: ${({ theme }) => theme.colors.white};

  padding: 10px;
  z-index: 2;

  ${media.tablet`
    position: relative;
    width: 250px;
    border-right: 1px solid ${({ theme }) => theme.colors.grey2};
    z-index: 1;
  `}
`;

const LoaderWrapper = styled(FlexColumnCenter)`
  flex: 1;
`;

const Link = styled(NavLink)`
  ${smallBodySemiBold};
  padding: 13px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grey4};
  position: relative;
  white-space: nowrap;
  ${media.tablet`white-space: normal; padding: 13px 13px 13px 30px;`}

  &.active {
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Div = styled.div`
  cursor: default;
  &:hover {
    color: inherit;
  }
`;

const SubLinks = styled(FlexColumn)`
  ${responsiveColumn.tablet}

  > ${Link} {
    padding: 13px;
    ${media.tablet`padding: 13px 13px 13px 60px;`}
  }
`;

const SubText = styled.div`
  padding: 13px;
  font-weight: normal;
  font-size: 11rem;
  padding-top: 0;
  position: relative;
  white-space: nowrap;
  display: none;
  ${media.tablet`display:flex;padding: 0 13px 13px 60px;`}
  color: ${({ theme }) => theme.colors.disabled};
`;

const SideBar = ({ isLoading, links, asLink }) => (
  <Wrapper>
    {isLoading && (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )}

    {links.map(({ name, to, subLinks }, index) => (
      <React.Fragment key={index}>
        <Link to={to} as={to ? asLink : Div}>
          {name}
        </Link>
        <SubLinks>
          {subLinks?.map((subLink, subLinkIndex) => (
            <>
              <Link to={subLink.to} as={asLink} key={subLinkIndex}>
                {subLink.name}
              </Link>
              <SubText key={`t-${subLinkIndex}`}>{subLink.subText}</SubText>
            </>
          ))}
        </SubLinks>
      </React.Fragment>
    ))}
  </Wrapper>
);

export default SideBar;
